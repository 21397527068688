import { Dialog, Heading, IconButton, Paragraph, TextLink } from "@hexa-ui/components";
import { HelpCircle, Trash2 } from "@hexa-ui/icons";
import { InfraFormContext } from "contexts";
import { useContext } from "react";
import { useFormContext } from "react-hook-form";
import { HeaderStyled, InfoModalStyled } from "../InfraServicesPagesStyled";
import { handleRemoveFormItem } from "../InfraSupportFunctions";
import { IInfraStacksInfo } from "./InfraStacksInfo";

interface IFormHeaderProps {
	stackInfo: IInfraStacksInfo;
	fields: [] | Record<"id", string>[];
	removeItem: (number) => void;
	index: number;
	fieldId: string;
	formSection: string;
}

export const FormHeader = ({
	stackInfo,
	fields,
	removeItem,
	index,
	fieldId,
	formSection
}: IFormHeaderProps) => {
	const { setInfraFormData } = useContext(InfraFormContext);
	const { getValues } = useFormContext();

	return (
		<HeaderStyled>
			<InfoModalStyled>
				<Heading size="H3">{stackInfo?.title}</Heading>
				<Dialog.Root
					title={<Heading size="H3">{stackInfo?.title}</Heading>}
					trigger={<HelpCircle />}
				>
					<Paragraph className="description">{stackInfo?.description}</Paragraph>
					<TextLink href={stackInfo?.documentation} target="blank" hasUnderline={false}>
						Read More.
					</TextLink>
				</Dialog.Root>
			</InfoModalStyled>

			{fields?.length > 1 && (
				<IconButton
					variant="tertiary"
					data-testid="delete-button"
					icon={Trash2}
					size="medium"
					onClick={() => {
						removeItem(index);
						handleRemoveFormItem(getValues, setInfraFormData, fieldId, formSection);
					}}
				/>
			)}
		</HeaderStyled>
	);
};
