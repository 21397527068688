import Api from "api/Api";
import {
	ADD_COMMENT,
	ADD_PARTICIPANT,
	AddJiraCommentRoutes,
	AddParticipantRoutes,
	GET_JIRA_FIELDS,
	GET_OPEN_REQUESTS,
	GET_TICKET,
	GetOpenRequestsRoutes,
	GetRequestFieldsRoutes,
	GetTicketByIdRoutes,
	POST_JIRA_TICKET,
	PostJiraTicketRoutes,
	REMOVE_PARTICIPANT,
	RemoveParticipantRoutes
} from "api/routes/JiraTicketRoutes";
import { Http2ServerResponse } from "http2";
import { INewJiraRequestBody } from "pages/InfraServicesPages/InfraServices.types";

interface ICommentsProps {
	body: string;
	public: boolean;
}

export const getOpenRequests = (
	serviceDeskId: number,
	requestOwnership: string,
	expand: string,
	limit?: number
): Promise<any> => {
	return Api.get({
		baseURL: GetOpenRequestsRoutes({ route: GET_OPEN_REQUESTS }),
		url: "",
		params: {
			serviceDeskId: serviceDeskId,
			requestOwnership: requestOwnership,
			expand: expand,
			limit: limit
		}
	});
};

export const getTicketById = (issueKey: string, expand: string): Promise<any> => {
	return Api.get({
		baseURL: GetTicketByIdRoutes({ route: GET_TICKET }),
		url: `${issueKey}`,
		params: {
			expand: expand
		}
	});
};

export const getRequestFields = (requestType: number, serviceDeskId: string): Promise<any> => {
	return Api.get({
		baseURL: GetRequestFieldsRoutes({ route: GET_JIRA_FIELDS }),
		url: `${serviceDeskId}/${requestType}`
	});
};

export const createJiraTicket = (
	jiraRequestBody: INewJiraRequestBody
): Promise<Http2ServerResponse> => {
	return Api.post({
		baseURL: PostJiraTicketRoutes({ route: POST_JIRA_TICKET }),
		url: "",
		body: jiraRequestBody
	});
};

export const addJiraComment = (
	issueKey: string,
	comment: ICommentsProps
): Promise<Http2ServerResponse> => {
	return Api.post({
		baseURL: AddJiraCommentRoutes({ route: ADD_COMMENT }),
		url: `${issueKey}`,
		body: comment
	});
};

export const addParticipant = (
	serviceDeskId: string,
	issueKey: string,
	participant: string[]
): Promise<Http2ServerResponse> => {
	return Api.post({
		baseURL: AddParticipantRoutes({ route: ADD_PARTICIPANT }),
		url: `${serviceDeskId}/${issueKey}`,
		body: participant
	});
};

export const removeParticipant = (
	serviceDeskId: string,
	issueKey: string,
	participant: string[]
): Promise<Http2ServerResponse> => {
	return Api.delete({
		baseURL: RemoveParticipantRoutes({ route: REMOVE_PARTICIPANT }),
		url: `${serviceDeskId}/${issueKey}/remove`,
		data: participant
	});
};
