import { ISteps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { DataLakeStackForm } from "../../modules/DataLakeStack/DataLakeStackForm";
import { DataShareStackForm } from "../../modules/DataShareStack/DataShareStackForm";
import { DatabaseStackForm } from "../../modules/DatabaseStack/DatabaseStackForm";
import { GeneralQuestionsForm } from "../../modules/GeneralQuestions/GeneralQuestionsForm";
import { MachineLearningStackForm } from "../../modules/MachineLearningStack/MachineLearningStackForm";
import { ProcessingStackForm } from "../../modules/ProcessingStack/ProcessingStackForm";
import { StreamingStackForm } from "../../modules/StreamingStack/StreamingStackForm";
import { WorkspaceStackForm } from "../../modules/WorkspaceStack/WorkspaceStackForm";

export const steps: ISteps[] = [
	{
		component: GeneralQuestionsForm,
		id: 1,
		label: "General Questions",
		formKey: "General_questions"
	},
	{
		component: ProcessingStackForm,
		id: 2,
		label: "Processing Stack",
		formKey: "processing_stack"
	},
	{ component: DataLakeStackForm, id: 3, label: "Data Lake Stack", formKey: "datalake_stack" },
	{ component: DatabaseStackForm, id: 4, label: "Database Stack", formKey: "database_stack" },
	{ component: WorkspaceStackForm, id: 5, label: "Workspace Stack", formKey: "workspace_stack" },
	{
		component: MachineLearningStackForm,
		id: 6,
		label: "Machine Learning Stack",
		formKey: "machine_learning_stack"
	},
	{
		component: DataShareStackForm,
		id: 7,
		label: "Data Share Stack",
		formKey: "data_share_stack"
	},
	{
		component: StreamingStackForm,
		id: 8,
		label: "Streaming Stack",
		formKey: "streaming_stack"
	}
];
