import { generatePath } from "react-router-dom";

const BASE_PATH = "/data-portal";

const BEES_ONE = "/";
const HOME_PAGE = `${BASE_PATH}`;
const REQUEST_ACCESS = `${BASE_PATH}/request-access`;
const USAGE_METRICS = `${BASE_PATH}/usage-metrics`;
const DATA_CATALOG = `${BASE_PATH}/data-catalog`;
const DATA_GOVERNANCE = `${BASE_PATH}/data-governance`;
const DIGITAL_COMMUNICATION = `${BASE_PATH}/digital-comms`;

const SELF_SERVICE_BI = `${BASE_PATH}/self-service-bi`;
const SELF_SERVICE_BI_DOMAIN = `${SELF_SERVICE_BI}/:domainName`;
const DATASET_DETAILS = `${SELF_SERVICE_BI}/:domainName/:datasetId`;
const EDIT_DATASET = `${SELF_SERVICE_BI}/:domainName/:datasetId/edit`;

const ACCESS_MANAGEMENT = `${BASE_PATH}/access-management`;
const SEGMENT_ACCESS = `${ACCESS_MANAGEMENT}/segment-access`;
const TENANT_ACCESS = `${ACCESS_MANAGEMENT}/tenant-access`;
const SNOWFLAKE_DTC_ACCESS = `${ACCESS_MANAGEMENT}/snowflake-dtc-access`;
const UNITY_CATALOG_REQUESTS = `${ACCESS_MANAGEMENT}/unity-catalog`;
const UNITY_CATALOG_INTERNAL_SHARING = `${ACCESS_MANAGEMENT}/unity-catalog/internal-sharing`;
const UNITY_CATALOG_NEW_DOMAIN = `${ACCESS_MANAGEMENT}/unity-catalog/new-domain`;
const UNITY_CATALOG_DELTA_SHARING = `${ACCESS_MANAGEMENT}/unity-catalog/delta-sharing`;

export const BASE_GLOBAL_REPORTS = `${BASE_PATH}/global-reports`;
const EDIT_REPORTS = `${BASE_GLOBAL_REPORTS}/edit-reports`;
const GLOBAL_REPORTS_DOMAIN = `${BASE_GLOBAL_REPORTS}/:domainName`;
const REPORT_DETAILS = `${BASE_GLOBAL_REPORTS}/:domainName/:reportId`;

const INFRA_SERVICES = `${BASE_PATH}/infra-services`;
const NEW_DOMAIN = `${INFRA_SERVICES}/new-domain`;
const NEW_RESOURCE = `${INFRA_SERVICES}/new-resource`;
const ALL_REQUESTS = `${INFRA_SERVICES}/requests-page`;

export const routes = {
	BEES_ONE,
	HOME_PAGE,
	REQUEST_ACCESS,
	USAGE_METRICS,
	DATA_CATALOG,
	DATA_GOVERNANCE,
	DIGITAL_COMMUNICATION,
	SELF_SERVICE_BI: {
		SELF_SERVICE_BI,
		SELF_SERVICE_BI_DOMAIN,
		DATASET_DETAILS,
		EDIT_DATASET
	},
	ACCESS_MANAGEMENT: {
		ACCESS_MANAGEMENT,
		SEGMENT_ACCESS,
		TENANT_ACCESS,
		SNOWFLAKE_DTC_ACCESS,
		UNITY_CATALOG_REQUESTS,
		UNITY_CATALOG_INTERNAL_SHARING,
		UNITY_CATALOG_NEW_DOMAIN,
		UNITY_CATALOG_DELTA_SHARING
	},
	GLOBAL_REPORTS: {
		BASE_GLOBAL_REPORTS,
		EDIT_REPORTS,
		GLOBAL_REPORTS_DOMAIN,
		REPORT_DETAILS
	},
	INFRA_SERVICES: {
		INFRA_SERVICES,
		NEW_DOMAIN,
		NEW_RESOURCE,
		ALL_REQUESTS
	}
};

export { generatePath };
