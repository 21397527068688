import { Paragraph } from "@hexa-ui/components";
import { TypeToast } from "admin-portal-shared-services";
import { SpaceParagraphStyled } from "styles/shared/ParagraphStyled/ParagraphStyled";
import { EDomainWorkspaceName, IScope } from "types/Report.types";
import { EScopes } from "types/Scopes.types";
import { showNotify } from "utils/notify";
import { removeBlankSpaces } from "utils/transformers";
import { ScopesCheckboxesStyled } from "../ReportModalStyled";

export const CreateWorkspaceIdMessage = (domains, formValues): JSX.Element => {
	const identifySelectedDomain = () => {
		return domains?.find((domain) => domain.domainId == formValues.domainId)?.domainName;
	};

	const domainSelected = identifySelectedDomain();
	return (
		formValues?.domainId !== undefined && (
			<SpaceParagraphStyled colortype="secondary" size="small">
				Your dashboard should be in this workspace:{" "}
				{EDomainWorkspaceName[removeBlankSpaces(domainSelected)]}
			</SpaceParagraphStyled>
		)
	);
};

export const CreateScopesMessage = (selectedScopes: IScope[], hasRls?: boolean): JSX.Element => {
	if (selectedScopes) {
		const checkedScopes = selectedScopes.map((s: IScope) => s.scopeLevel);

		if (checkedScopes.length === 0) {
			return null;
		} else {
			return (
				<ScopesCheckboxesStyled>
					<div className="scopes-text">
						<Paragraph colortype="secondary" size="small" weight="semibold">
							When you're adding a dashboard to{" "}
							{checkedScopes.length > 1 ? "these scopes" : "this scope"} the visualization rules
							are:
						</Paragraph>
						{checkedScopes?.includes(EScopes.Bees) && (
							<Paragraph colortype="secondary" size="small">
								<li>Bees employees will see your dashboard</li>
							</Paragraph>
						)}
						{checkedScopes?.includes(EScopes.AbiGlobal) && (
							<Paragraph colortype="secondary" size="small">
								<li>ABI Global employees will see your dashboard</li>
							</Paragraph>
						)}
						{checkedScopes?.includes(EScopes.AbiNonGlobal) && hasRls && (
							<Paragraph colortype="secondary" size="small">
								<li>
									ABI non-Global employees will see your dashboard filtered according to the country
									(Ex: Peru sees Peru, Brazil sees Brazil)
								</li>
							</Paragraph>
						)}
						{checkedScopes?.includes(EScopes.AbiNonGlobal) && !hasRls && (
							<Paragraph colortype="secondary" size="small">
								<li>ABI non-Global employees will see your complete dashboard</li>
							</Paragraph>
						)}
					</div>
				</ScopesCheckboxesStyled>
			);
		}
	}
};

export const checkMessageError = (message: string, setError, formatMessage) => {
	switch (true) {
		case message?.includes("Duplicated registry"):
			showNotify(formatMessage({ id: "REPORT.TOAST_DUPLICATED_ERROR" }), TypeToast.ERROR);
			setError("reportLink", {
				type: "manual",
				message: formatMessage({ id: "REPORT.TOAST_DUPLICATED_ERROR" })
			});
			break;
		case message?.includes("Workspace error"):
			showNotify(formatMessage({ id: "REPORT.TOAST_WORKSPACE_ERROR" }), TypeToast.ERROR);
			setError("domainId", {
				type: "manual",
				message: formatMessage({ id: "REPORT.TOAST_WORKSPACE_ERROR" })
			});
			break;
		case message?.includes("RLS error"):
			showNotify(formatMessage({ id: "REPORT.TOAST_RLS_ERROR" }), TypeToast.ERROR);
			setError("hasRls", {
				type: "manual",
				message: formatMessage({ id: "REPORT.TOAST_RLS_ERROR" })
			});
			break;
		default:
			showNotify(formatMessage({ id: "REPORT.TOAST_ADDED_ERROR" }), TypeToast.ERROR);
			break;
	}
};
