import Api from "api/Api";
import { ROUTE_BASE_URL } from "api/constants";

export const getDatasetById = (datasetId: string) => {
	return Api.get({
		baseURL: `${ROUTE_BASE_URL}/datasets`,
		url: `${datasetId}`
	});
};

export const deleteDataset = (datasetId: string) => {
	return Api.delete({
		baseURL: `${ROUTE_BASE_URL}/datasets`,
		url: `${datasetId}`
	});
};
