import { Heading, Paragraph, TextLink } from "@hexa-ui/components";

const LINKS = {
	GUIDE:
		"https://anheuserbuschinbev.sharepoint.com/:p:/r/sites/BEESDATATeam/_layouts/15/Doc.aspx?sourcedoc=%7B1C6F6C4A-B7E9-426E-9D75-93F3641FEDFC%7D&file=User%20guide%20model%20-%20Deliver.pptx&action=edit&mobileredirect=true&cid=4e8d88d4-8cae-420b-9652-115f7e6c2d6c,",
	DOCUMENTATION:
		"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3383754808/Deliver+Data+Domain+Onboarding"
};

export const ReportGoodPractices = (): JSX.Element => {
	return (
		<>
			<Heading size="H4">Good Practices</Heading>
			<Heading size="H5">Roles and Responsibilities</Heading>
			<hr />

			<ul>
				<li>
					<Paragraph>
						Each Domain has one workspace connected. So, to publish your dashboard, it should be
						inside the specific workspace of your Domain.
					</Paragraph>
				</li>
			</ul>

			<Heading size="H5">Data Protection (Security and Privacy)</Heading>
			<hr />
			<ul>
				<li>
					<Paragraph>
						If your report has any personally identifiable information (e.g., CPF, personal IDs,
						email address, personal address, etc.), please check with Data Governance the best way
						to share it with data protection best practices;
					</Paragraph>
				</li>
				<li>
					<Paragraph>
						Share only reports validated and approved by the owner (do not share reports in
						development);
					</Paragraph>
				</li>
				<li>
					<Paragraph>
						Check if the new report already exists in another workspace or if it's too similar to
						another report already published;
					</Paragraph>
				</li>
			</ul>

			<Heading size="H5">Quality</Heading>
			<hr />
			<ul>
				<li>
					<Paragraph>
						Make sure that your reports doesn't bring data that you don't need. This will help it
						with performance, making reports faster;
					</Paragraph>
				</li>
				<li>
					<Paragraph>
						Inform the update Date and period of the occurrence of your dashboard;
					</Paragraph>
				</li>
				<li>
					<Paragraph>Ensure efficient modeling - recommended model is the star schema;</Paragraph>
				</li>
			</ul>

			<Heading size="H5">Deploy/Pipeline</Heading>
			<hr />
			<ul>
				<li>
					<Paragraph>
						Respect non prod and prod environment. Make sure that your dashboard worked well across
						all non prod environments before prod publication.
					</Paragraph>
				</li>
			</ul>

			<Heading size="H5">User Guidance</Heading>
			<hr />
			<ul>
				<li>
					<Paragraph>
						Upload a User Guide to your dashboard. There users can find how to use it (filters, tabs
						and data);{" "}
						<TextLink target="_blank" href={LINKS.GUIDE}>
							User Guide Example
						</TextLink>
					</Paragraph>
				</li>
				<li>
					<Paragraph>
						Upload a Documentation Page. So the users can find out data dictionary (docs,
						Glossaries, Business terms and rules);{" "}
						<TextLink target="_blank" href={LINKS.DOCUMENTATION}>
							Documentation Page Example
						</TextLink>
					</Paragraph>
				</li>
			</ul>
		</>
	);
};
