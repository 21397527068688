export const EmptyState = () => {
	return (
		<svg width="380" height="250" fill="none" xmlns="http://www.w3.org/2000/svg">
			<path
				d="M173.387 10.0589L98.8173 50.2511C88.4586 55.8343 82 66.6524 82 78.4199L82 166.813C82 178.31 88.1682 188.924 98.1579 194.616L172.322 236.874C182.236 242.523 194.408 242.465 204.268 236.723L282.104 191.392C291.946 185.66 298 175.129 298 163.739L298 78.6976C298 66.7836 291.381 55.8561 280.823 50.3375L203.392 9.86777C193.978 4.94767 182.737 5.01929 173.387 10.0589Z"
				fill="white"
				stroke="#F0ECFC"
				strokeWidth="12"
				strokeLinejoin="round"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M136.155 157.572C133.631 157.572 131.578 159.546 131.578 161.972C131.578 164.398 133.631 166.372 136.155 166.372H240.35C242.874 166.372 244.927 164.398 244.927 161.972C244.927 159.546 242.874 157.572 240.35 157.572H136.155ZM240.35 167.813H136.155C132.805 167.813 130.078 165.193 130.078 161.972C130.078 158.752 132.805 156.131 136.155 156.131H240.35C243.7 156.131 246.426 158.752 246.426 161.972C246.426 165.193 243.7 167.813 240.35 167.813Z"
				fill="#DDDCDC"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M188.718 164.532H136.62C135.149 164.532 133.957 163.386 133.957 161.972C133.957 160.558 135.149 159.412 136.62 159.412H188.718C190.189 159.412 191.381 160.558 191.381 161.972C191.381 163.386 190.189 164.532 188.718 164.532Z"
				fill="#F0E44C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M189.807 143.832H167.79C164.687 143.832 162.749 140.605 164.3 138.023L175.308 119.694L186.318 101.366C187.869 98.7838 191.746 98.7838 193.297 101.366L204.306 119.694L215.315 138.023C216.866 140.605 214.927 143.832 211.825 143.832H189.807Z"
				fill="#5F538F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M161.805 141.028L165.762 143.31L168.087 140.791L166.471 138.746L161.805 141.028Z"
				fill="#5F538F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M188.219 98.0664L192.401 100.33L189.818 104.31L187.234 102.674L188.219 98.0664Z"
				fill="#5F538F"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M186.698 142.189H164.68C161.578 142.189 159.639 138.961 161.191 136.379L172.199 118.05L183.208 99.7217C184.759 97.14 188.636 97.14 190.188 99.7217L201.197 118.05L212.205 136.379C213.756 138.961 211.818 142.189 208.716 142.189H186.698Z"
				fill="#7C74B1"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M183.997 127.632C183.905 127.545 183.86 127.44 183.86 127.314L183.198 111.136C183.198 110.837 183.354 110.687 183.666 110.687H189.197C189.509 110.687 189.666 110.837 189.666 111.136L189.003 127.314C189.003 127.44 188.958 127.545 188.866 127.632C188.775 127.72 188.665 127.763 188.535 127.763H184.328C184.198 127.763 184.088 127.72 183.997 127.632ZM183.724 135.946C183.087 135.334 182.77 134.555 182.77 133.605C182.77 132.657 183.087 131.883 183.724 131.284C184.36 130.685 185.172 130.385 186.159 130.385C187.145 130.385 187.957 130.685 188.594 131.284C189.23 131.883 189.549 132.657 189.549 133.605C189.549 134.529 189.23 135.304 188.594 135.927C187.957 136.552 187.145 136.863 186.159 136.863C185.172 136.863 184.36 136.558 183.724 135.946Z"
				fill="#F0E44C"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M183.369 134.176C183.369 133.228 183.686 132.453 184.324 131.854C184.96 131.254 185.771 130.956 186.758 130.956C187.525 130.956 188.185 131.138 188.74 131.5C188.674 131.426 188.606 131.354 188.533 131.283C187.895 130.684 187.084 130.385 186.098 130.385C185.11 130.385 184.299 130.684 183.663 131.283C183.026 131.883 182.707 132.657 182.707 133.606C182.707 134.554 183.026 135.335 183.663 135.946C183.815 136.092 183.977 136.22 184.149 136.331C183.63 135.75 183.369 135.033 183.369 134.176Z"
				fill="#E2D246"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M183.935 127.632C184.026 127.72 184.136 127.763 184.266 127.763H184.455L183.798 111.706C183.798 111.407 183.953 111.257 184.266 111.257H189.598L189.604 111.136C189.604 110.837 189.447 110.687 189.136 110.687H183.604C183.292 110.687 183.137 110.837 183.137 111.136L183.799 127.314C183.799 127.44 183.844 127.545 183.935 127.632Z"
				fill="#E2D246"
			/>
			<path
				fillRule="evenodd"
				clipRule="evenodd"
				d="M125.396 68.5392C122.296 68.5392 119.772 70.964 119.772 73.9451V171.746C119.772 174.727 122.296 177.152 125.396 177.152H251.112C254.214 177.152 256.736 174.727 256.736 171.746V73.9451C256.736 70.964 254.214 68.5392 251.112 68.5392H125.396ZM251.112 178.799H125.396C121.35 178.799 118.059 175.635 118.059 171.746V73.9451C118.059 70.0562 121.35 66.8923 125.396 66.8923H251.112C255.158 66.8923 258.45 70.0562 258.45 73.9451V171.746C258.45 175.635 255.158 178.799 251.112 178.799Z"
				fill="#DDDCDC"
			/>
			<mask id="mask0_401_16437" maskUnits="userSpaceOnUse" x="114" y="62" width="150" height="122">
				<path d="M114 184H264V62H114V184Z" fill="#DEDEDE" />
			</mask>
			<g mask="url(#mask0_401_16437)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M118.914 86.3167H257.592V84.6698H118.914V86.3167Z"
					fill="#DDDCDC"
				/>
			</g>
			<mask id="mask1_401_16437" maskUnits="userSpaceOnUse" x="114" y="62" width="150" height="122">
				<path d="M114 184H264V62H114V184Z" fill="#DEDEDE" />
			</mask>
			<g mask="url(#mask1_401_16437)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M133.204 76.8714C133.204 78.6005 131.746 80.0018 129.947 80.0018C128.149 80.0018 126.691 78.6005 126.691 76.8714C126.691 75.1423 128.149 73.7411 129.947 73.7411C131.746 73.7411 133.204 75.1423 133.204 76.8714Z"
					fill="#DDDCDC"
				/>
			</g>
			<mask id="mask2_401_16437" maskUnits="userSpaceOnUse" x="114" y="62" width="150" height="122">
				<path d="M114 184H264V62H114V184Z" fill="#DEDEDE" />
			</mask>
			<g mask="url(#mask2_401_16437)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M143.887 76.8714C143.887 78.6005 142.429 80.0018 140.631 80.0018C138.833 80.0018 137.375 78.6005 137.375 76.8714C137.375 75.1423 138.833 73.7411 140.631 73.7411C142.429 73.7411 143.887 75.1423 143.887 76.8714Z"
					fill="#DDDCDC"
				/>
			</g>
			<mask id="mask3_401_16437" maskUnits="userSpaceOnUse" x="114" y="62" width="150" height="122">
				<path d="M114 184H264V62H114V184Z" fill="#DEDEDE" />
			</mask>
			<g mask="url(#mask3_401_16437)">
				<path
					fillRule="evenodd"
					clipRule="evenodd"
					d="M154.563 76.8714C154.563 78.6005 153.105 80.0018 151.306 80.0018C149.509 80.0018 148.051 78.6005 148.051 76.8714C148.051 75.1423 149.509 73.7411 151.306 73.7411C153.105 73.7411 154.563 75.1423 154.563 76.8714Z"
					fill="#DDDCDC"
				/>
			</g>
		</svg>
	);
};
