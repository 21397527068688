import { Card } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const DatasetCardStyled = styled(Card, {
	margin: "24px 0",
	padding: "24px",
	width: "100%",
	h3: {
		marginBottom: "24px"
	}
});
