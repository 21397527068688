import { EmptyState } from "@hexa-ui/components";
import { EmptyStateStyled } from "styles/shared/EmptyStateStyled/EmptyStateStyled";

const { PageLevel } = EmptyState;

export const ReportNoContentState = () => {
	return (
		<EmptyStateStyled>
			<PageLevel
				data-testid="page-level-no-content"
				title="Oops!"
				description="There are no dashboards here yet."
			/>
		</EmptyStateStyled>
	);
};
