import { useAnalyticsService, useAuthenticationService } from "admin-portal-shared-services";
import { useEffect } from "react";

const jwtDecoded = useAuthenticationService().parseJwt();
const user = jwtDecoded;

export const useSegmentAnalytics = (segmentKey: string): void => {
	const analyticsService = useAnalyticsService();

	useEffect(() => {
		analyticsService.load(segmentKey);
		analyticsService.identify(user.sub);
	}, [analyticsService, segmentKey]);
};

export default useSegmentAnalytics;
