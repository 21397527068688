export const Chart7 = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 731.39 365.7">
		<g id="aae6ee1a-c951-478b-9ad4-d2c3401b7e05" data-name="Layer 6">
			<rect
				y={0.86}
				width={731.39}
				height={364.84}
				style={{
					fill: "none"
				}}
			/>
			<line
				y1={250.17}
				x2={731.37}
				y2={250.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10
				}}
			/>
			<line
				x1={-0.03}
				y1={193.17}
				x2={731.41}
				y2={193.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={307.17}
				x2={731.41}
				y2={307.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={136.17}
				x2={731.41}
				y2={136.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={79.17}
				x2={731.41}
				y2={79.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={20}
				x2={731.39}
				y2={20}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
		</g>
		<g id="a40ca9ae-190d-43c8-9ff6-734dcf61ccbc" data-name="Layer 2">
			<rect
				x={34.66}
				y={121.97}
				width={32.55}
				height={243.5}
				style={{
					fill: "#f9de87"
				}}
			/>
			<rect
				x={76.98}
				y={38.83}
				width={32.55}
				height={326.65}
				style={{
					fill: "#f9e5a5"
				}}
			/>
			<rect
				x={119.29}
				y={121.97}
				width={32.55}
				height={243.5}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={161.61}
				y={83.71}
				width={32.55}
				height={281.76}
				style={{
					fill: "#f9de87"
				}}
			/>
			<rect
				x={203.93}
				y={54.72}
				width={32.55}
				height={310.75}
				style={{
					fill: "#f9e5a5"
				}}
			/>
			<rect
				x={246.24}
				y={96.23}
				width={32.55}
				height={269.24}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={291.07}
				y={158.15}
				width={32.55}
				height={207.32}
				style={{
					fill: "#f9e5a5"
				}}
			/>
			<rect
				x={330.88}
				y={204.29}
				width={32.55}
				height={161.41}
				style={{
					fill: "#faeaba"
				}}
			/>
			<rect
				x={373.19}
				y={223.55}
				width={32.55}
				height={141.93}
				style={{
					fill: "#f9e5a5"
				}}
			/>
			<rect
				x={415.51}
				y={223.55}
				width={32.55}
				height={141.93}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={457.83}
				y={214.5}
				width={32.55}
				height={150.97}
				style={{
					fill: "#f9e5a5"
				}}
			/>
			<rect
				x={500.14}
				y={204.29}
				width={32.55}
				height={161.41}
				style={{
					fill: "#faeaba"
				}}
			/>
			<rect
				x={542.46}
				y={192.24}
				width={32.55}
				height={173.23}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={584.78}
				y={208.24}
				width={32.55}
				height={157.23}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={627.09}
				y={250.68}
				width={32.55}
				height={114.79}
				style={{
					fill: "#f9e5a5"
				}}
			/>
			<rect
				x={669.41}
				y={256.94}
				width={32.55}
				height={108.53}
				style={{
					fill: "#faeaba"
				}}
			/>
		</g>
	</svg>
);
