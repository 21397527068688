import { styled } from "@hexa-ui/theme";

export const DeleteDialogActionButtonStyled = styled("div", {
	display: "flex",
	justifyContent: "end",
	width: "100%",
	paddingTop: "$2",

	Button: {
		marginRight: "1rem"
	}
});

export const FormStyled = styled("form", {
	width: "100%"
});
