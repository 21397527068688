import { Checkbox, Paragraph, TextLink } from "@hexa-ui/components";
import { IInfraStacksInfo } from "../InfraStacksInfo";
import {
	CardContentStyled,
	CardHeaderStyled,
	CardWrapperStyled,
	ItemStyled
} from "./SelectableCardStyled";

export interface ISelectableCardProps extends IInfraStacksInfo {
	selectedStacks: string[];
	setSelectedStacks: (string) => void;
}

export const SelectableCard = ({
	id,
	title,
	description,
	documentation,
	selectedStacks,
	setSelectedStacks
}: ISelectableCardProps): React.JSX.Element => {
	const handleChange = (title) => {
		selectedStacks.includes(title)
			? setSelectedStacks(selectedStacks.filter((stack) => stack !== title))
			: setSelectedStacks((previousState) => [title, ...previousState]);
	};

	return (
		<ItemStyled xl={3} lg={3} md={4} sm={6} xs={6}>
			<CardWrapperStyled border="small" elevated="medium">
				<CardContentStyled>
					<CardHeaderStyled>
						<Checkbox
							data-testid="checkbox-selectable-card"
							label={title}
							id={id}
							onCheckedChange={() => handleChange(title)}
						/>
						<Paragraph colortype="disabled">{description}</Paragraph>
					</CardHeaderStyled>
					<TextLink
						href={documentation}
						hasUnderline={false}
						target="blank"
						className="documentation-link"
					>
						Read More.
					</TextLink>
				</CardContentStyled>
			</CardWrapperStyled>
		</ItemStyled>
	);
};
