import { ProgressTracker } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const InfraFormStyled = styled("div", {
	width: "100%",
	marginBottom: "$9"
});

export const StyledLabel = styled(ProgressTracker.StepLabel, {
	whiteSpace: "nowrap",
	lineBreak: "normal"
});
