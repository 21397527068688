import {
	IBasicStackForm,
	IInfraReqDatabaseStack,
	IInfraReqDatalakeStack,
	IInfraReqProcessingStack,
	IInfraReqStreamingStack,
	IInfraReqWorkspaceStack
} from "pages/InfraServicesPages/InfraNewDomainPage/InfraNewDomain.types";
import { useEffect, useState } from "react";

export const useValidateUniqueValues = (
	formValues:
		| IBasicStackForm[]
		| IInfraReqProcessingStack[]
		| IInfraReqDatalakeStack[]
		| IInfraReqDatabaseStack[]
		| IInfraReqWorkspaceStack[]
		| IInfraReqStreamingStack[],
	index: number,
	fieldProp: string,
	fieldName: string
) => {
	const [error, setError] = useState({
		hasUniquenessError: false,
		errorMessage: `${fieldName} must be unique.`
	});

	useEffect(() => {
		formValues.length !== new Set(formValues.map((form) => form?.[fieldProp])).size
			? setError({ ...error, hasUniquenessError: true })
			: setError({ ...error, hasUniquenessError: false });
	}, [formValues?.[index]?.[fieldProp]]);

	return error;
};
