import MessageMap from "../i18n.d";

const ptBR: MessageMap = {
	test: {
		translate: "Traduzir",
		missing: "Only English option available"
	}
};

export default ptBR;
