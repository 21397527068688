export const ExternalLink = () => {
	return (
		<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
			<g id="external link">
				<path
					id="Vector (Stroke)"
					fillRule="evenodd"
					clipRule="evenodd"
					d="M9.34694 2.4898C9.34694 2.21929 9.56623 2 9.83674 2H13.5102C13.7807 2 14 2.21929 14 2.4898V6.16327C14 6.43377 13.7807 6.65306 13.5102 6.65306C13.2397 6.65306 13.0204 6.43377 13.0204 6.16327V3.67227L7.12185 9.57083C6.93057 9.76211 6.62045 9.76211 6.42917 9.57083C6.2379 9.37955 6.2379 9.06943 6.42917 8.87815L12.3277 2.97959H9.83674C9.56623 2.97959 9.34694 2.7603 9.34694 2.4898ZM3.71429 4.81633C3.51943 4.81633 3.33256 4.89373 3.19478 5.03151C3.057 5.1693 2.97959 5.35617 2.97959 5.55102V12.2857C2.97959 12.4806 3.057 12.6674 3.19478 12.8052C3.33256 12.943 3.51943 13.0204 3.71429 13.0204H10.449C10.6438 13.0204 10.8307 12.943 10.9685 12.8052C11.1063 12.6674 11.1837 12.4806 11.1837 12.2857V8.61225C11.1837 8.34174 11.403 8.12245 11.6735 8.12245C11.944 8.12245 12.1633 8.34174 12.1633 8.61225V12.2857C12.1633 12.7404 11.9827 13.1764 11.6612 13.4979C11.3397 13.8194 10.9036 14 10.449 14H3.71429C3.25963 14 2.82359 13.8194 2.5021 13.4979C2.18061 13.1764 2 12.7404 2 12.2857V5.55102C2 5.09636 2.18061 4.66033 2.5021 4.33884C2.82359 4.01735 3.25963 3.83673 3.71429 3.83673H7.38776C7.65826 3.83673 7.87755 4.05602 7.87755 4.32653C7.87755 4.59704 7.65826 4.81633 7.38776 4.81633H3.71429Z"
					fill="#0F7FFA"
				/>
			</g>
		</svg>
	);
};
