import { Grid } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const { Container } = Grid;

const RouterLoaderStyled = styled(Container, {
	width: "100%",
	height: 100,
	display: "flex",
	justifyContent: "center",
	padding: "20px 0 0 0"
});

export default RouterLoaderStyled;
