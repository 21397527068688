import { useAuthenticationService, useSidebar } from "admin-portal-shared-services";
import { EnvConfig } from "contexts";
import { ApplicationProviders } from "contexts/App/ApplicationProviders";
import { useMenuItems, useMenuUtils } from "hooks/useMenuLinks";
import useSegmentAnalytics from "hooks/useSegmentAnalytics";
import { BrowserRouter } from "react-router-dom";
import Router from "router/Routes";
import { setSidebarItems } from "utils/identity";

export default function App(props: EnvConfig) {
	const authService = useAuthenticationService();
	const scopes = authService.getUserScopes();
	const { sidebarLinks } = useMenuItems();
	const { sidebarUtilsLinks } = useMenuUtils();

	useSegmentAnalytics(props.segmentKey);
	useSidebar({
		items: setSidebarItems(sidebarLinks, scopes),
		utils: sidebarUtilsLinks
	});

	return (
		<ApplicationProviders props={props}>
			<BrowserRouter>
				<Router />
			</BrowserRouter>
		</ApplicationProviders>
	);
}
