import { styled } from "@hexa-ui/theme";

export const WrapperStyled = styled("div", {
	width: "100%",
	display: "flex",
	margin: "$6 0",
	flexWrap: "wrap",
	padding: 0,
	variants: {
		gap: {
			true: { gap: "$4" }
		}
	}
});
