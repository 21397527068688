import { createContext, useEffect, useMemo, useState } from "react";

interface ViewportContextProps {
	width: number;
	height: number;
}

export const ViewportContext = createContext<ViewportContextProps>({
	width: undefined,
	height: undefined
});

export const ViewportProvider = ({ children }) => {
	const [width, setWidth] = useState(window.innerWidth);
	const [height, setHeight] = useState(window.innerHeight);
	const providerValues = useMemo(() => ({ width, height }), [width, height]);

	const handleWindowResize = () => {
		setWidth(window.innerWidth);
		setHeight(window.innerHeight);
	};

	useEffect(() => {
		window.addEventListener("resize", handleWindowResize);
		return () => window.removeEventListener("resize", handleWindowResize);
	}, []);

	return <ViewportContext.Provider value={providerValues}>{children}</ViewportContext.Provider>;
};
