import { InfraFormContext } from "contexts";
import { useValidateUniqueValues } from "hooks/useValidateUniqueValues";
import { FormHeader } from "pages/InfraServicesPages/components/FormHeader";
import { infraStacksInfo } from "pages/InfraServicesPages/components/InfraStacksInfo";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { IStackFieldsProps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { BasicStackForm } from "../BasicStack/BasicStackForm";

export const MachineLearningStackFields = ({
	index,
	removeItem,
	fields,
	fieldId
}: IStackFieldsProps) => {
	const { infraFormData } = useContext(InfraFormContext);
	const stackInfo = infraStacksInfo.filter((stack) => stack.id === "machine-learning-stack")?.[0];
	const { watch, setValue, getValues } = useFormContext();
	const formValues = watch("machine_learning_stack");
	const error = useValidateUniqueValues(formValues, index, "product_name", "Product Name");

	useEffect(() => {
		setValue(`machine_learning_stack.${index}.id`, fieldId);
	}, [fieldId, infraFormData]);

	return (
		<ContainerCardStyled
			border="small"
			elevated="medium"
			key={fieldId}
			data-testid="machine-learning-stack-form"
		>
			<FormHeader
				stackInfo={stackInfo}
				fields={fields}
				removeItem={removeItem}
				index={index}
				fieldId={fieldId}
				formSection="machine_learning_stack"
			/>
			<BasicStackForm
				acceptsShortProductName
				formSection="machine_learning_stack"
				index={index}
				error={error}
			/>
		</ContainerCardStyled>
	);
};
