import { Grid, Heading, Table } from "@hexa-ui/components";
import { TypeToast, setAppHeaderConfig } from "admin-portal-shared-services";
import { useReportsContext } from "contexts";
import { useResizeObserverErrorHandler } from "hooks/useResizeObserverErrorHandler";
import { useEffect, useState } from "react";
import { useIntl } from "react-intl";
import { routes } from "router/constants";
import { getReports } from "services/Report/ReportService";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { IReport } from "types/Report.types";
import { showNotify } from "utils/notify";
import { formatDate } from "utils/transformers";
import EditReportsActionButtons from "./EditReportActionButtons/EditReportActionButtons";
import { renderDashboardTitle, renderTypeIconColumn } from "./components/EditReportTableRenders";

const { Container } = Grid;
const GET_REPORTS_PARAMS = {
	size: 1000,
	offset: 0
};

const EditReportsPage = (): JSX.Element => {
	useResizeObserverErrorHandler();
	const [reports, setReports] = useState([] as IReport[]);
	const [isLoading, setIsLoading] = useState(true);
	const { formatMessage } = useIntl();
	const { updateReportsDate } = useReportsContext();

	useEffect(() => {
		setAppHeaderConfig({
			pageTitle: "Reports Summary",
			breadcrumbConfig: {
				homePath: routes.HOME_PAGE,
				items: [
					{
						label: "Global Reports",
						path: routes.GLOBAL_REPORTS.BASE_GLOBAL_REPORTS,
						isCurrentPage: false
					},
					{
						label: `Edit Reports`,
						path: routes.GLOBAL_REPORTS.EDIT_REPORTS,
						isCurrentPage: true
					}
				]
			}
		});
	}, [setAppHeaderConfig]);

	const editDashboardTableColumns = [
		{
			Header: "",
			accessor: "reportType",
			disableSortBy: true,
			style: {
				width: "5%"
			},
			customRender: (value) => {
				return renderTypeIconColumn(value);
			}
		},
		{
			Header: "Upload Date",
			accessor: "uploadDate",
			disableSortBy: false,
			customRender: (value) => {
				return formatDate(value);
			},
			style: {
				width: "10%"
			}
		},
		{
			Header: "Dashboard Title",
			accessor: "reportTitle",
			disableSortBy: false,
			customRender: (value, obj) => {
				return renderDashboardTitle(value, obj);
			},
			style: {
				width: "30%"
			}
		},
		{
			Header: "Domain",
			accessor: "domain.domainName",
			disableSortBy: false,
			style: {
				width: "20%"
			}
		},
		{
			Header: "Owner Name",
			accessor: "ownerName",
			disableSortBy: false,
			style: {
				width: "25%"
			}
		},
		{
			Header: "",
			accessor: "edit",
			disableSortBy: true,
			customRender: (_, obj) => {
				return <EditReportsActionButtons report={obj} />;
			},
			style: {
				width: "10%"
			}
		}
	];

	const getAllReports = async () => {
		try {
			const { data } = await getReports(GET_REPORTS_PARAMS.size, GET_REPORTS_PARAMS.offset);
			setReports(data.data);
		} catch {
			showNotify(formatMessage({ id: "DASHBOARD.TOAST_LOAD_ERROR" }), TypeToast.ERROR);
		} finally {
			setIsLoading(false);
		}
	};

	useEffect(() => {
		getAllReports();
	}, []);

	useEffect(() => {
		if (updateReportsDate !== null) {
			getAllReports();
		}
	}, [updateReportsDate]);

	return (
		<Container type="fluid" data-testid="report-page" sidebar>
			<Heading size="H3">Dashboards List</Heading>
			<ContainerStyled marginTop>
				<Table
					loading={isLoading}
					emptyMessage={"No reports found :("}
					columns={editDashboardTableColumns}
					data={reports}
					search
				/>
			</ContainerStyled>
		</Container>
	);
};

export default EditReportsPage;
