import { AddStackButton } from "pages/InfraServicesPages/components/AddStackButton/AddStackButton";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DataLakeStackFields } from "./DataLakeStackFields";

export const DataLakeStackForm = () => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "datalake_stack",
		shouldUnregister: false
	});

	const handleAppend = () =>
		append({
			product_name: "",
			regions: [],
			primary_region: "",
			short_product_name: "",
			raw_containers: [],
			refined_containers: [],
			workspace_containers: []
		});

	return (
		<>
			{fields?.map((field, index) => {
				return (
					<DataLakeStackFields
						key={field.id}
						fieldId={field.id}
						index={index}
						removeItem={remove}
						fields={fields}
					/>
				);
			})}
			<AddStackButton onClick={handleAppend} stackName="Data Lake Stack" />
		</>
	);
};
