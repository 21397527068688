import { EScopeLevel } from "types/Report.types";

export const scopeList = [
	{
		scopeId: "8c21411b-6156-46ce-9aed-9b22aadf98bb",
		scopeLevel: EScopeLevel["BeesDataPortal.Bees.Read"]
	},
	{
		scopeId: "711fdf1c-6ff5-49f6-b1b8-d2dd1c8dfd0e",
		scopeLevel: EScopeLevel["BeesDataPortal.Global.Read"]
	},
	{
		scopeId: "2f4db0e1-9385-4125-ab6f-3b56c0587c2c",
		scopeLevel: EScopeLevel["BeesDataPortal.CountryBU.Read"]
	}
];
