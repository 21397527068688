import { styled } from "@hexa-ui/theme";

export const ReportCardStyled = styled("div", {
	width: "100%",
	minHeight: "fit-content;",
	paddingTop: "$6",
	marginBottom: "$4",

	".report-style-class": {
		height: "100vh",
		iframe: {
			border: 0
		}
	}
});
