import Api from "api/Api";
import { GET_SCOPES, GetScopesRoute } from "api/routes/ScopeRoutes";

export const getScopes = (size: number, offset: number): Promise<any> => {
	return Api.get({
		baseURL: GetScopesRoute({ route: GET_SCOPES }),
		url: "",
		params: {
			size: size,
			offset: offset
		}
	});
};
