import { Card, Grid } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const { Item } = Grid;

export const CardStyled = styled(Card, {
	padding: "$4",
	margin: "$4 0"
});

export const ItemStyled = styled(Item, {
	padding: 0,
	marginRight: "12px",
	p: {
		margin: "0 4px 4px 0",
		fontWeight: "$semibold"
	}
});
