import { Paragraph } from "@hexa-ui/components";
import { ParagraphProps } from "@hexa-ui/components/dist/declarations/src/lib/typography/Paragraph/ParagraphTypes";
import { LabelBackground } from "./StatusLabelStyled";

interface IStatusProps {
	status: "Active" | "Inactive";
}

type TColortype = ParagraphProps["colortype"];

const colortype = {
	Active: {
		iconColor: "#31BD54",
		textColor: "success"
	},
	Inactive: {
		iconColor: "#E4423F",
		textColor: "error"
	}
};

export const StatusLabel = ({ status }: IStatusProps) => {
	return (
		<LabelBackground variant={status}>
			<svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" fill="none">
				<circle cx="4" cy="4" r="4" fill={colortype[status]?.iconColor} data-testid="status-icon" />
			</svg>
			<Paragraph
				size="small"
				colortype={colortype[status]?.textColor as TColortype}
				weight="medium"
			>
				{status}
			</Paragraph>
		</LabelBackground>
	);
};
