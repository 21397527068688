import { Button } from "@hexa-ui/components";
import { CategoryAdd, IconProps } from "@hexa-ui/icons";
import { FunctionComponent } from "react";
import { ButtonsWrapperStyled } from "styles/shared/ButtonsWrapperStyled/ButtonsWrapperStyled";

interface IAddStackButtonProps {
	onClick: () => void;
	stackName: string;
	icon?: FunctionComponent<IconProps>;
}

export const AddStackButton = ({
	onClick,
	stackName,
	icon = CategoryAdd
}: IAddStackButtonProps) => {
	return (
		<ButtonsWrapperStyled position="end" margin="top">
			<Button size="small" trailing icon={icon} onClick={onClick} variant="secondary">
				Add {stackName}
			</Button>
		</ButtonsWrapperStyled>
	);
};
