export const Chart8 = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 731.39 365.7">
		<g id="a7db665a-5e96-46dc-97f1-a463dabd2bdf" data-name="Layer 6">
			<rect
				y={0.86}
				width={731.39}
				height={364.84}
				style={{
					fill: "none"
				}}
			/>
			<line
				y1={250.17}
				x2={731.37}
				y2={250.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10
				}}
			/>
			<line
				x1={-0.03}
				y1={193.17}
				x2={731.41}
				y2={193.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={307.17}
				x2={731.41}
				y2={307.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={136.17}
				x2={731.41}
				y2={136.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={79.17}
				x2={731.41}
				y2={79.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={20}
				x2={731.39}
				y2={20}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
		</g>
		<g id="b111a1b4-1a75-4961-9107-d5826684fb13" data-name="Layer 7">
			<rect
				x={31.78}
				y={189.99}
				width={43.5}
				height={80.42}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={86.91}
				y={164.19}
				width={43.5}
				height={80.42}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={144.32}
				y={172.79}
				width={43.5}
				height={97.87}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={201.22}
				y={148.64}
				width={43.5}
				height={119.87}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={258.38}
				y={116.27}
				width={43.5}
				height={126.45}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={315.53}
				y={86.93}
				width={43.5}
				height={181.58}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={372.69}
				y={152.18}
				width={43.5}
				height={116.33}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={429.84}
				y={166.85}
				width={43.5}
				height={101.67}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={487}
				y={183.54}
				width={43.5}
				height={84.97}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={544.15}
				y={188.09}
				width={43.5}
				height={80.42}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={601.31}
				y={202.51}
				width={43.5}
				height={66.01}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={658.46}
				y={212.87}
				width={43.5}
				height={55.64}
				style={{
					fill: "#f8e090"
				}}
			/>
		</g>
		<g id="a5ed926f-92dc-4c19-88ed-74c12199b84b" data-name="Layer 4">
			<polyline
				points="49.99 253.84 110.18 236.65 166.07 141.27 224.99 243.22 282.65 233.61 339.81 197.7 396.46 225.01 455.64 151.17 540.61 217.93 570.96 237.66 628.62 180.5 686.28 140.55"
				style={{
					fill: "none",
					stroke: "#f8ca2d",
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeWidth: 3
				}}
			/>
		</g>
	</svg>
);
