import * as yup from "yup";

export const reportModalSchema = yup.object().shape({
	domainId: yup.string().required("Domain is required."),
	scopes: yup
		.array()
		.of(
			yup.object().shape({
				scopeId: yup.string(),
				scopeLevel: yup.string()
			})
		)
		.min(1)
		.required("Scope is required."),
	hasRls: yup.boolean().when("scopes", (scopes, schema) => {
		return scopes[0]?.some((scope) => scope?.scopeLevel === "BeesDataPortal.CountryBU.Read")
			? yup.boolean().required("This information is required.")
			: schema;
	}),
	reportTitle: yup
		.string()
		.required("Report Title is required.")
		.min(5, "Report Title must be at least 5 characters.")
		.max(50, "Report Title must be at most 50 characters."),
	description: yup
		.string()
		.required("Description is required.")
		.min(32, "Description must be at least 32 characters.")
		.max(300, "Description must be at most 300 characters."),
	ownerName: yup
		.string()
		.required("Owner Name is required.")
		.min(5, "Owner Name must be at least 5 characters.")
		.max(32, "Owner Name must be at most 32 characters."),
	ownerEmail: yup
		.string()
		.required("Owner Email is required.")
		.email("Owner Email must be a valid email.")
		.min(10, "Owner Email must be at least 10 characters.")
		.max(50, "Owner Email must be at most 50 characters."),
	reportLink: yup
		.string()
		.required("Report Link is required.")
		.url("Report Link must be a valid URL."),
	documentationLink: yup
		.string()
		.url("Documentation Link must be a valid URL.")
		.required("Documentation Link is required."),
	dataSource: yup.string().required("Data Source is required."),
	updateFrequency: yup.string().required("Update Frequency is required."),
	userGuide: yup.string().url("User Guide must be a valid URL."),
	userAcceptedTerms: yup.boolean()
});
