import { AddStackButton } from "pages/InfraServicesPages/components/AddStackButton/AddStackButton";
import { useFieldArray, useFormContext } from "react-hook-form";
import { StreamingStackFields } from "./StreamingStackFields";

export const StreamingStackForm = () => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "streaming_stack"
	});

	const handleAppend = () =>
		append({
			product_name: "",
			regions: [],
			short_product_name: "",
			event_hub_namespace_sku: "",
			event_hub_namespace_capacity: "",
			event_hub_namespace_auto_inflate_enable: false,
			event_hub_namespace_zones: false,
			event_hub_namespace_public_access: false,
			event_hub_dedicated_cluster: false,
			event_hubs: [],
			authorization_rule: []
		});

	return (
		<>
			{fields?.map((field, index) => {
				return (
					<StreamingStackFields
						key={field.id}
						fieldId={field.id}
						index={index}
						removeItem={remove}
						fields={fields}
					/>
				);
			})}

			<AddStackButton onClick={handleAppend} stackName="Streaming Stack" />
		</>
	);
};
