import { Input } from "@hexa-ui/components";
import { InfraFormContext } from "contexts";
import { useValidateUniqueValues } from "hooks/useValidateUniqueValues";
import { FormHeader } from "pages/InfraServicesPages/components/FormHeader";
import { infraStacksInfo } from "pages/InfraServicesPages/components/InfraStacksInfo";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { IStackFieldsProps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { handleChange } from "../../InfraSupportFunctions";
import { BasicStackForm } from "../BasicStack/BasicStackForm";

export const DataLakeStackFields = ({ index, removeItem, fields, fieldId }: IStackFieldsProps) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const stackInfo = infraStacksInfo.filter((stack) => stack.id === "data-lake-stack")?.[0];
	const { watch, register, getValues, setValue } = useFormContext();
	const formValues = watch("datalake_stack");
	const error = useValidateUniqueValues(formValues, index, "product_name", "Product Name");

	useEffect(() => {
		setValue(`datalake_stack.${index}.id`, fieldId);
	}, [fieldId, infraFormData]);

	return (
		<ContainerCardStyled
			border="small"
			elevated="medium"
			key={fieldId}
			data-testid="datalake-stack-form"
		>
			<FormHeader
				stackInfo={stackInfo}
				fields={fields}
				removeItem={removeItem}
				index={index}
				fieldId={fieldId}
				formSection="datalake_stack"
			/>
			<BasicStackForm
				acceptsPrimaryRegion
				acceptsShortProductName
				formSection="datalake_stack"
				index={index}
				error={error}
			/>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register(`datalake_stack.${index}.raw_containers`, {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id={`raw_containers_${index}`}
					label="Raw Containers"
					value={infraFormData?.datalake_stack?.[index]?.raw_containers}
					width="100%"
					placeholder=""
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register(`datalake_stack.${index}.refined_containers`, {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="refined_containers"
					label="Refined Containers"
					value={infraFormData?.datalake_stack?.[index]?.refined_containers}
					width="100%"
					placeholder=""
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register(`datalake_stack.${index}.workspace_containers`, {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="workspace_containers"
					label="Workspace Containers"
					value={infraFormData?.datalake_stack?.[index]?.workspace_containers}
					width="100%"
					placeholder=""
				/>
			</ContainerStyled>
		</ContainerCardStyled>
	);
};
