import { TypeToast, useAuthenticationService } from "admin-portal-shared-services";
import { FormEvent, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { createJiraTicket } from "services/Jira/JiraService";
import { ACCOUNT_ID_BEES_DATA_PORTAL } from "utils/constants";
import { showNotify } from "utils/notify";

interface IUseSubmitJiraTicketProps {
	serviceDeskId: string;
	requestTypeId: number;
	requestFieldValues: object;
}

export const useSubmitJiraForm = ({
	serviceDeskId,
	requestTypeId,
	requestFieldValues
}: IUseSubmitJiraTicketProps) => {
	const [isSendButtonLoading, setIsSendButtonLoading] = useState(false);
	const authService = useAuthenticationService();
	const userEmail = authService.getUserEmailB2C()?.toLowerCase();
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	const getJiraRequestBody = () => {
		return {
			serviceDeskId: serviceDeskId,
			requestParticipants: [ACCOUNT_ID_BEES_DATA_PORTAL],
			raiseOnBehalfOf: userEmail,
			requestTypeId: `${requestTypeId}`,
			requestFieldValues: requestFieldValues
		};
	};

	const handleSubmit = async (event: FormEvent<HTMLButtonElement>) => {
		event.preventDefault();
		const requestBody = getJiraRequestBody();
		setIsSendButtonLoading(true);

		try {
			await createJiraTicket(requestBody);
			showNotify(formatMessage({ id: "JIRA.TOAST_POST_TICKET_SUCCESS" }), TypeToast.SUCCESS);
			navigate(-1);
		} catch {
			showNotify(formatMessage({ id: "JIRA.TOAST_POST_TICKET_ERROR" }), TypeToast.ERROR);
		} finally {
			setIsSendButtonLoading(false);
		}
	};

	return { isSendButtonLoading, handleSubmit };
};
