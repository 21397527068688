import { Plus } from "@hexa-ui/icons";
import { AddStackButton } from "pages/InfraServicesPages/components/AddStackButton/AddStackButton";
import { useFieldArray, useFormContext } from "react-hook-form";
import { EventHubFields } from "./EventHubFields";

export const EventHubForm = ({ formIndex }) => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "event_hubs",
		shouldUnregister: false
	});

	return (
		<>
			{fields.map((field, hubIndex) => {
				return <EventHubFields key={field.id} formIndex={formIndex} hubIndex={hubIndex} />;
			})}
			<AddStackButton
				onClick={() =>
					append({
						hub_name: "",
						partitions: 1,
						retention: 7,
						capture_enabled: false,
						capture_encoding: "",
						capture_interval: 300,
						capture_size: 314572800,
						skip_empty_archives: true,
						capture_archive_format:
							"{Namespace}/{EventHub}/{PartitionId}/{Year}/{Month}/{Day}/{Hour}/{Minute}/{Second}",
						datalake_name: "",
						datalake_resource_group: "",
						container_name: ""
					})
				}
				icon={Plus}
				stackName="Event Hub"
			/>
		</>
	);
};
