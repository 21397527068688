import { createContext, ReactNode, useContext, useState } from "react";

interface ILoaderContextProps {
	isLoading: boolean;
	setIsLoading: (value: boolean) => void;
}

export const LoaderContext = createContext<ILoaderContextProps>({
	isLoading: true,
	setIsLoading: () => null
});

export const useLoaderContext = () => useContext(LoaderContext);

export const LoaderProvider = ({ children }: { children: ReactNode }) => {
	const [isLoading, setIsLoading] = useState(true);

	return (
		<LoaderContext.Provider value={{ isLoading, setIsLoading }}>{children}</LoaderContext.Provider>
	);
};
