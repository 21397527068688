import { Alert, Divider } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const DividerStyled = styled(Divider, {
	marginTop: "$4"
});

export const AlertStyled = styled(Alert, {
	margin: "$1 0 $4 0",
	width: "400px"
});
