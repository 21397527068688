import { Heading } from "@hexa-ui/components";
import { ReportCard } from "components/Cards/ReportCard/ReportCard";
import { DatasetCardStyled } from "./DatasetCardStyled";

interface IDatasetCardProps {
	accessToken: string | undefined;
	embedUrl: string | undefined;
	id: string | undefined;
}

export const DatasetCard = ({ accessToken, embedUrl, id }: IDatasetCardProps) => {
	if (!accessToken || !embedUrl || !id) return <></>;

	return (
		<DatasetCardStyled border="medium" elevated="medium" data-testid="dataset-card">
			<Heading size="H3">Power BI information</Heading>
			<ReportCard accessToken={accessToken} embedUrl={embedUrl} id={id} />
		</DatasetCardStyled>
	);
};
