import { Button, Heading } from "@hexa-ui/components";
import { yupResolver } from "@hookform/resolvers/yup";
import { InfraFormContext, InfraFormProvider } from "contexts";
import { useContext, useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { ButtonsWrapperStyled } from "styles/shared/ButtonsWrapperStyled/ButtonsWrapperStyled";
import { ERequestTypeId } from "types/Jira.types";
import { InfraFormSchema } from "../InfraNewDomainPage/InfraFormSchema.schema";
import { infraFormDefaultValues } from "../components/InfraFormDefaultValues";
import InfraFormStepper from "../components/InfraFormStepper/InfraFormStepper";
import { steps } from "../components/InfraFormStepper/steps";
import { infraStacksInfo } from "../components/InfraStacksInfo";
import { SelectableCard } from "../components/SelectableCard/SelectableCard";
import { ParagraphStyled, StackCardsWrapperStyled } from "./InfraNewResourcePageStyled";

interface INewResourceProps {
	setNewResource: (string) => void;
}

export const InfraNewResourceStackForm = ({ setNewResource }: INewResourceProps) => {
	const [selectedStacks, setSelectedStacks] = useState<string[]>([]);
	const [stacksForm, setStacksForm] = useState<boolean>();
	const generalQuestionsForm = steps[0];
	const selectedSteps = steps.filter((step) => selectedStacks.includes(step.label));
	const { infraFormData } = useContext(InfraFormContext);
	const formMethods = useForm({
		resolver: yupResolver(InfraFormSchema),
		mode: "onChange",
		reValidateMode: "onChange",
		shouldFocusError: true,
		defaultValues: infraFormDefaultValues(infraFormData)
	});
	const { NewAzureResource } = ERequestTypeId;

	return (
		<>
			<Heading size="H3">New Stack</Heading>
			<ParagraphStyled>Select one or more types of Stacks you’ll need: </ParagraphStyled>
			{stacksForm ? (
				<FormProvider {...formMethods}>
					<InfraFormProvider>
						<InfraFormStepper
							steps={[generalQuestionsForm, ...selectedSteps]}
							handleCancel={() => setStacksForm(false)}
							requestTypeId={NewAzureResource}
						/>
					</InfraFormProvider>
				</FormProvider>
			) : (
				<StackCardsWrapperStyled>
					{infraStacksInfo?.map((stack) => {
						return (
							<SelectableCard
								key={stack.id}
								id={stack.id}
								title={stack.title}
								description={stack.description}
								documentation={stack.documentation}
								selectedStacks={selectedStacks}
								setSelectedStacks={setSelectedStacks}
							/>
						);
					})}
					<ButtonsWrapperStyled position="center" margin="bottomTop" gap>
						<Button onClick={() => setNewResource("")}>Cancel</Button>
						<Button onClick={() => setStacksForm(true)}>Next</Button>
					</ButtonsWrapperStyled>
				</StackCardsWrapperStyled>
			)}
		</>
	);
};
