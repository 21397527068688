import { Input, Paragraph, Select, TextLink } from "@hexa-ui/components";
import { InfraFormContext } from "contexts";
import { useValidateUniqueValues } from "hooks/useValidateUniqueValues";
import { FormHeader } from "pages/InfraServicesPages/components/FormHeader";
import { infraStacksInfo } from "pages/InfraServicesPages/components/InfraStacksInfo";
import { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { SpaceParagraphStyled } from "styles/shared/ParagraphStyled/ParagraphStyled";
import { IStackFieldsProps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { cleanDependentFields, handleChange, setupInput } from "../../InfraSupportFunctions";
import { YesOrNoRadio } from "../../components/YesOrNoRadio";
import { BasicStackForm } from "../BasicStack/BasicStackForm";
import { MySqlVersionOptions, PostgreVersionOptions, ServerTypeOptions } from "../InfraFormOptions";

const DOCS = {
	MYSQL_TIERS:
		"https://learn.microsoft.com/en-gb/azure/mysql/single-server/concepts-pricing-tiers ",
	POSTGRE_TIERS:
		"https://learn.microsoft.com/en-gb/rest/api/postgresql/singleserver/servers/create?tabs=HTTP//sku "
};

export const DatabaseStackFields = ({ index, removeItem, fields, fieldId }: IStackFieldsProps) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const stackInfo = infraStacksInfo.filter((stack) => stack.id === "database-stack")?.[0];
	const {
		watch,
		control,
		register,
		getValues,
		setValue,
		formState: { errors }
	} = useFormContext();
	const NEED_MYSQL_FIELD = infraFormData?.database_stack?.[index]?.need_mysql;
	const NEED_POSTGRE_FIELD = infraFormData?.database_stack?.[index]?.need_postgre;
	const formValues = watch("database_stack");
	const error = useValidateUniqueValues(formValues, index, "product_name", "Product Name");

	useEffect(() => {
		setValue(`database_stack.${index}.id`, fieldId);
	}, [fieldId, infraFormData]);

	useEffect(() => {
		cleanDependentFields(infraFormData, setValue, "database_stack", index, "need_mysql", [
			"mysql_server_type",
			"mysql_sku",
			"mysql_version",
			"mysql_extra_config"
		]);
	}, [NEED_MYSQL_FIELD]);

	useEffect(() => {
		cleanDependentFields(infraFormData, setValue, "database_stack", index, "need_postgre", [
			"postgre_server_type",
			"postgre_sku",
			"postgre_version",
			"postgre_extra_config"
		]);
	}, [NEED_POSTGRE_FIELD]);

	return (
		<ContainerCardStyled
			border="small"
			elevated="medium"
			key={fieldId}
			data-testid="database-stack-form"
		>
			<FormHeader
				stackInfo={stackInfo}
				fields={fields}
				removeItem={removeItem}
				index={index}
				fieldId={fieldId}
				formSection="database_stack"
			/>
			<BasicStackForm
				acceptsPrimaryRegion
				acceptsShortProductName
				formSection="database_stack"
				index={index}
				error={error}
			/>
			<ContainerStyled>
				<Paragraph size="small" weight="semibold" width="100%">
					Need MySQL?
				</Paragraph>
				<YesOrNoRadio
					id={`need_mysql`}
					fieldData="need_mysql"
					formSection="database_stack"
					index={index}
				/>
			</ContainerStyled>
			{NEED_MYSQL_FIELD && (
				<>
					<ContainerStyled marginBottom marginTop>
						<Controller
							control={control}
							name={`database_stack.${index}.mysql_server_type`}
							render={({ field: { onChange } }) => {
								return (
									<Select.Root
										id="mysql_server_type"
										label="MySQL Server Type"
										data-testid="mysql_server_type"
										labelProps={{ htmlFor: "mysql_server_type", className: "label" }}
										value={infraFormData?.database_stack?.[index]?.mysql_server_type}
										placeholder="Choose an option"
										onChange={onChange}
										error={errors?.database_stack?.[index]?.["mysql_server_type"]?.message}
									>
										<ServerTypeOptions />
									</Select.Root>
								);
							}}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(`database_stack.${index}.mysql_sku`, {
								onChange: () => handleChange(getValues, setInfraFormData)
							})}
							id="mysql_sku"
							label="MySQL Sku"
							width="100%"
							placeholder="B_Gen4_1"
							hint="Follow the pattern tier + family + cores, e.g. B_Gen4_1, GP_Gen5_8"
							{...setupInput(errors, "database_stack", "mysql_sku", index, infraFormData)}
						/>
						<SpaceParagraphStyled colortype="secondary" size="small">
							Possible values can be found{" "}
							<TextLink href={DOCS.MYSQL_TIERS} size="small">
								here.
							</TextLink>
						</SpaceParagraphStyled>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Controller
							control={control}
							name={`database_stack.${index}.mysql_version`}
							render={({ field: { onChange } }) => {
								return (
									<Select.Root
										id="mysql_version"
										label="MySQL Version"
										data-testid="mysql_version"
										labelProps={{ htmlFor: "mysql_version", className: "label" }}
										value={infraFormData?.database_stack?.[index]?.mysql_version}
										placeholder="Choose an option"
										onChange={onChange}
										error={errors?.database_stack?.[index]?.["mysql_version"]?.message}
									>
										<MySqlVersionOptions />
									</Select.Root>
								);
							}}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(`database_stack.${index}.mysql_extra_config`, {
								onChange: () => handleChange(getValues, setInfraFormData)
							})}
							id="mysql_extra_config"
							label="MySQL Extra Config"
							width="100%"
							placeholder="env_config_name"
							hint="Follow the format 'env_config_name'"
							{...setupInput(errors, "database_stack", "mysql_extra_config", index, infraFormData)}
						/>
					</ContainerStyled>
				</>
			)}
			<ContainerStyled>
				<Paragraph size="small" weight="semibold" width="100%">
					Need Postgre?
				</Paragraph>
				<YesOrNoRadio
					id={`need_postgre`}
					fieldData="need_postgre"
					formSection="database_stack"
					index={index}
				/>
			</ContainerStyled>
			{NEED_POSTGRE_FIELD && (
				<>
					<ContainerStyled marginBottom marginTop>
						<Controller
							control={control}
							name={`database_stack.${index}.postgre_server_type`}
							render={({ field: { onChange } }) => {
								return (
									<Select.Root
										id="postgre_server_type"
										label="Postgre Server Type"
										data-testid="postgre_server_type"
										labelProps={{ htmlFor: "mysql_server_type", className: "label" }}
										placeholder="Choose an option"
										value={infraFormData?.database_stack?.[index]?.postgre_server_type}
										onChange={onChange}
										error={errors?.database_stack?.[index]?.["postgre_server_type"]?.message}
									>
										<ServerTypeOptions />
									</Select.Root>
								);
							}}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(`database_stack.${index}.postgre_sku`, {
								onChange: () => handleChange(getValues, setInfraFormData)
							})}
							id="postgre_sku"
							label="Postgre Sku"
							width="100%"
							placeholder="GP_Gen5_8"
							hint="Follow the pattern tier + family + cores, e.g. B_Gen4_1, GP_Gen5_8"
							{...setupInput(errors, "database_stack", "postgre_sku", index, infraFormData)}
						/>
						<SpaceParagraphStyled colortype="secondary" size="small">
							Possible values can be found{" "}
							<TextLink href={DOCS.POSTGRE_TIERS} size="small">
								here.
							</TextLink>
						</SpaceParagraphStyled>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Controller
							control={control}
							name={`database_stack.${index}.postgre_version`}
							render={({ field: { onChange } }) => {
								return (
									<Select.Root
										id="postgre_version"
										label="Postgre Version"
										data-testid="postgre_version"
										labelProps={{ htmlFor: "postgre_version" }}
										value={infraFormData?.database_stack?.[index]?.postgre_version}
										placeholder="Choose an option"
										onChange={onChange}
										error={errors?.database_stack?.[index]?.["postgre_version"]?.message}
									>
										<PostgreVersionOptions />
									</Select.Root>
								);
							}}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(`database_stack.${index}.postgre_storage`, {
								onChange: () => handleChange(getValues, setInfraFormData)
							})}
							id="postgre_storage"
							label="Postgre Storage"
							width="100%"
							placeholder=""
							{...setupInput(errors, "database_stack", "postgre_storage", index, infraFormData)}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(`database_stack.${index}.postgre_extra_config`, {
								onChange: () => handleChange(getValues, setInfraFormData)
							})}
							id="postgre_extra_config"
							label="Postgre Extra Config"
							width="100%"
							placeholder="env_config_name"
							hint="Follow the format 'env_config_name'"
							{...setupInput(
								errors,
								"database_stack",
								"postgre_extra_config",
								index,
								infraFormData
							)}
						/>
					</ContainerStyled>
				</>
			)}
		</ContainerCardStyled>
	);
};
