import { Input, Paragraph, Select, TextLink } from "@hexa-ui/components";
import { InfraFormContext } from "contexts";
import { useValidateUniqueValues } from "hooks/useValidateUniqueValues";
import { FormHeader } from "pages/InfraServicesPages/components/FormHeader";
import { infraStacksInfo } from "pages/InfraServicesPages/components/InfraStacksInfo";
import { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import {
	ParagraphStyled,
	SpaceParagraphStyled
} from "styles/shared/ParagraphStyled/ParagraphStyled";
import { IStackFieldsProps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { cleanDependentFields, handleChange, setupInput } from "../../InfraSupportFunctions";
import { YesOrNoRadio } from "../../components/YesOrNoRadio";
import { BasicStackForm } from "../BasicStack/BasicStackForm";
import { SqlPoolSkuOptions } from "../InfraFormOptions";

const DOCS = {
	SQL_POOL_COLLATION:
		"https://learn.microsoft.com/en-us/azure/synapse-analytics/sql/reference-collation-types"
};

export const WorkspaceStackFields = ({ index, removeItem, fields, fieldId }: IStackFieldsProps) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const stackInfo = infraStacksInfo.filter((stack) => stack.id === "workspace-stack")?.[0];
	const {
		control,
		watch,
		register,
		getValues,
		setValue,
		formState: { errors }
	} = useFormContext();
	const NEED_SYNAPSE_FIELD = infraFormData?.workspace_stack?.[index]?.need_synapse;
	const NEED_SYNAPSE_SQL_POOL_FIELD =
		infraFormData?.workspace_stack?.[index]?.need_synapse_sql_pool;
	const formValues = watch("workspace_stack");
	const error = useValidateUniqueValues(formValues, index, "product_name", "Product Name");

	useEffect(() => {
		setValue(`workspace_stack.${index}.id`, fieldId);
	}, [fieldId, infraFormData]);

	useEffect(() => {
		cleanDependentFields(infraFormData, setValue, "workspace_stack", index, "need_synapse", [
			"need_synapse_sql_pool"
		]);
	}, [NEED_SYNAPSE_FIELD]);

	useEffect(() => {
		cleanDependentFields(
			infraFormData,
			setValue,
			"workspace_stack",
			index,
			"need_synapse_sql_pool",
			["sql_pool_collation", "sql_pool_sku"]
		);
	}, [NEED_SYNAPSE_SQL_POOL_FIELD]);

	return (
		<ContainerCardStyled border="small" elevated="medium" data-testid="workspace-stack-form">
			<FormHeader
				stackInfo={stackInfo}
				fields={fields}
				removeItem={removeItem}
				index={index}
				fieldId={fieldId}
				formSection="workspace_stack"
			/>
			<BasicStackForm
				acceptsPrimaryRegion
				acceptsShortProductName
				formSection="workspace_stack"
				index={index}
				error={error}
			/>
			<ContainerStyled>
				<Paragraph size="small" weight="semibold" width="100%">
					Need Databricks?
				</Paragraph>
				<YesOrNoRadio
					id="need_databricks"
					fieldData="need_databricks"
					formSection="workspace_stack"
					index={index}
				/>
			</ContainerStyled>
			<ContainerStyled>
				<Paragraph size="small" weight="semibold" width="100%">
					Need Synapse?
				</Paragraph>
				<YesOrNoRadio
					id="need_synapse"
					fieldData="need_synapse"
					formSection="workspace_stack"
					index={index}
				/>
			</ContainerStyled>
			{NEED_SYNAPSE_FIELD && (
				<>
					<ContainerStyled>
						<ParagraphStyled weight="semibold" size="small">
							Need Synapse SQL Pool?
						</ParagraphStyled>
						<YesOrNoRadio
							id="need_synapse_sql_pool"
							fieldData="need_synapse_sql_pool"
							formSection="workspace_stack"
							index={index}
						/>
					</ContainerStyled>
					{NEED_SYNAPSE_SQL_POOL_FIELD && (
						<>
							<ContainerStyled marginBottom marginTop>
								<Input
									{...register(`workspace_stack.${index}.sql_pool_collation`, {
										onChange: () => handleChange(getValues, setInfraFormData)
									})}
									id="sql_pool_collation"
									label="SQL Pool Collation"
									width="100%"
									placeholder=""
									{...setupInput(
										errors,
										"workspace_stack",
										"sql_pool_collation",
										index,
										infraFormData
									)}
								/>
								<SpaceParagraphStyled colortype="secondary" size="small">
									List of supported collation types can be found{" "}
									<TextLink href={DOCS.SQL_POOL_COLLATION} size="small">
										here.
									</TextLink>
								</SpaceParagraphStyled>
							</ContainerStyled>
							<ContainerStyled marginBottom marginTop>
								<Controller
									control={control}
									name={`workspace_stack.${index}.sql_pool_sku`}
									render={({ field: { onChange } }) => {
										return (
											<Select.Root
												id="sql_pool_sku"
												label="SQL Pool SKU"
												labelProps={{ htmlFor: "sql_pool_sku" }}
												data-testid="sql_pool_sku"
												value={infraFormData?.workspace_stack?.[index]?.sql_pool_sku}
												placeholder="Choose an option"
												onChange={onChange}
												error={errors?.workspace_stack?.[index]?.["sql_pool_sku"]?.message}
											>
												<SqlPoolSkuOptions />
											</Select.Root>
										);
									}}
								/>
							</ContainerStyled>
						</>
					)}
				</>
			)}
		</ContainerCardStyled>
	);
};
