import { useAuthenticationService } from "admin-portal-shared-services";
import segmentAnalytics from "analytics";

const authentication = useAuthenticationService();
const user = useAuthenticationService().parseJwt();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

export const reportDeleted = (reportId: string) => {
	segmentAnalytics.beesGlobalReportDeleted({
		country: userCountry,
		date: new Date().toISOString(),
		report_id: reportId,
		user_email: user.email
	});
};
