import { EScopes } from "types/Scopes.types";
import { ESidebarVisibility, ISidebarItem } from "types/Sidebar.types";

export const isBeesUser = (scopes: EScopes | string[]) => {
	return scopes?.includes(EScopes.Bees);
};

export const isBeesDataPortalUser = (scopes: EScopes | string[]) => {
	return (
		scopes?.includes(EScopes.Bees) ||
		scopes?.includes(EScopes.AbiGlobal) ||
		scopes?.includes(EScopes.AbiNonGlobal)
	);
};

export const setSidebarItems = (sidebarLinks: ISidebarItem[], scopes: EScopes | string[]) => {
	const visibilityConditions = {
		beesUser: isBeesDataPortalUser(scopes) && isBeesUser(scopes),
		dataPortalUser: isBeesDataPortalUser(scopes)
	};

	return sidebarLinks.filter((item: ISidebarItem) => {
		if (visibilityConditions.beesUser) {
			return item;
		} else if (visibilityConditions.dataPortalUser) {
			return item.visible === ESidebarVisibility.BeesDataPortalUsers && ESidebarVisibility.AllUsers;
		} else {
			return item.title === ESidebarVisibility.AllUsers;
		}
	});
};
