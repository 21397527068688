import { Paragraph } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const SpaceParagraphStyled = styled(Paragraph, {
	marginTop: "$2",
	width: "100%"
});

export const ParagraphStyled = styled(Paragraph, {
	width: "100%"
});
