import { Card } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const InfraCardStyled = styled(Card, {
	minHeight: 130,
	padding: "$6",
	marginBottom: "$4",
	display: "flex",
	cursor: "pointer",
	width: "100%"
});

export const HeaderCardStyled = styled("div", {
	display: "flex",
	gap: "$4"
});

export const DescriptionStyled = styled("div", {
	padding: "$4 0 $4 12px"
});
