import * as yup from "yup";
import { handleArrayFields } from "../InfraSupportFunctions";

declare module "yup" {
	interface StringSchema<TType, TContext, TDefault, TFlags> {
		maxItems(value: string, limit: number): this;
		maxItemCharacters(value: string, limit: number): this;
	}
}

yup.addMethod(yup.string, "maxItems", function (errorMessage, limit) {
	return this.test(`test-max-items`, errorMessage, function (value) {
		const { path, createError } = this;

		const array = handleArrayFields(value);
		return (array && array.length <= limit) || createError({ path, message: errorMessage });
	});
});

yup.addMethod(yup.string, "maxItemCharacters", function (errorMessage, limit) {
	return this.test(`test-max-character`, errorMessage, function (value) {
		const { path, createError } = this;

		const array = handleArrayFields(value);
		const exceededLimitItems = array?.some((item) => item.length > limit);
		return !exceededLimitItems || createError({ path, message: errorMessage });
	});
});

const MAX_CHARACTERS_VAULTS = 5;
const MAX_ITEMS_VAULTS = 9;

export const InfraFormSchema = yup.object().shape({
	summary: yup.string().required(),
	General_questions: yup.object().shape({
		team_name: yup.string().required("Team Name is required."),
		director_email: yup
			.string()
			.required("Director Email is required.")
			.email("Director Email must be a valid email."),
		environments: yup.array().min(1, "Select at least one environment."),
		admin_users: yup.array().required(),
		non_admin_users: yup.array().required()
	}),
	processing_stack: yup.array().of(
		yup.object().shape({
			product_name: yup
				.string()
				.max(23, "Product Name must be at most 23 characters.")
				.matches(/^\S*$/, "Product Name cannot contain blank spaces, use - (hyphen) instead"),
			short_product_name: yup.string().max(3, "Shgort Product Name must be at most 3 characters."),
			regions: yup.array().min(1, "Select at least one region."),
			primary_region: yup.string(),
			need_databricks: yup.boolean(),
			databricks_repository: yup.string().when("need_databricks", {
				is: true,
				then: (schema) => schema.required("Databricks repository is required")
			}),
			need_datafactory: yup.boolean(),
			datafactory_repository: yup.string().when("need_datafactory", {
				is: true,
				then: (schema) => schema.required("Datafactory repository is required")
			}),
			additional_vaults: yup
				.string()
				.maxItems(
					`Additional vaults list can have at max ${MAX_ITEMS_VAULTS} elements.`,
					MAX_ITEMS_VAULTS
				)
				.maxItemCharacters(
					`Each additional vault can have at most ${MAX_CHARACTERS_VAULTS} characters.`,
					MAX_CHARACTERS_VAULTS
				)
		})
	),
	datalake_stack: yup.array().of(
		yup.object().shape({
			product_name: yup
				.string()
				.max(23, "Product Name must be at most 23 characters.")
				.matches(/^\S*$/, "Product Name cannot contain blank spaces, use - (hyphen) instead"),
			short_product_name: yup.string().max(3, "Short Product Name must be at most 3 characters."),
			regions: yup.array().min(1, "Select at least one region.")
		})
	),
	database_stack: yup.array().of(
		yup.object().shape({
			product_name: yup
				.string()
				.max(23, "Product Name must be at most 23 characters.")
				.matches(/^\S*$/, "Product Name cannot contain blank spaces, use - (hyphen) instead"),
			short_product_name: yup.string().max(3, "Short Product Name must be at most 3 characters."),
			regions: yup.array().min(1, "Select at least one region."),
			mysql_server_type: yup.string().when("need_mysql", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			mysql_sku: yup.string().when("need_mysql", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			mysql_version: yup.string().when("need_mysql", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			mysql_extra_config: yup.string().when("need_mysql", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			postgre_server_type: yup.string().when("need_postgre", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			postgre_sku: yup.string().when("need_postgre", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			postgre_version: yup.string().when("need_postgre", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			postgre_storage: yup.string().when("need_postgre", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			postgre_extra_config: yup.string().when("need_postgre", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			})
		})
	),
	workspace_stack: yup.array().of(
		yup.object().shape({
			product_name: yup
				.string()
				.max(23, "Product Name must be at most 23 characters.")
				.matches(/^\S*$/, "Product Name cannot contain blank spaces, use - (hyphen) instead"),
			short_product_name: yup.string().max(3, "Short Product Name must be at most 3 characters."),
			regions: yup.array().min(1, "Select at least one region."),
			need_synapse_sql_pool: yup.boolean().when("need_synapse", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			sql_pool_collation: yup.string().when("need_synapse_sql_pool", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			}),
			sql_pool_sku: yup.string().when("need_synapse_sql_pool", {
				is: true,
				then: (schema) => schema.required("This information is required.")
			})
		})
	),
	machine_learning_stack: yup.array().of(
		yup.object().shape({
			product_name: yup
				.string()
				.max(23, "Product Name must be at most 23 characters.")
				.matches(/^\S*$/, "Product Name cannot contain blank spaces, use - (hyphen) instead"),
			short_product_name: yup.string().max(3, "Short Product Name must be at most 3 characters."),
			regions: yup.array().min(1, "Select at least one region.")
		})
	),
	data_share_stack: yup.array().of(
		yup.object().shape({
			product_name: yup
				.string()
				.max(23, "Product Name must be at most 23 characters.")
				.matches(/^\S*$/, "Product Name cannot contain blank spaces, use - (hyphen) instead"),
			short_product_name: yup.string().max(3, "Short Product Name must be at most 3 characters."),
			regions: yup.array().min(1, "Select at least one region.")
		})
	),
	streaming_stack: yup.array().of(
		yup.object().shape({
			product_name: yup
				.string()
				.max(23, "Product Name must be at most 23 characters.")
				.matches(/^\S*$/, "Product Name cannot contain blank spaces, use - (hyphen) instead"),
			short_product_name: yup.string().max(3, "Short Product Name must be at most 3 characters."),
			regions: yup.array().min(1, "Select at least one region."),
			event_hub_namespace_sku: yup
				.string()
				.oneOf(["Basic", "Standard", "Premium"], "Invalid option"),
			event_hub_namespace_capacity: yup.string().when("event_hub_namespace_sku", {
				is: (value) => value === "Basic" || value === "Standard",
				then: (schema) =>
					schema.oneOf(["1", "2"], "Invalid value for Event Hub Namespace Capacity"),
				otherwise: (schema) =>
					schema.test(
						"isMultipleOf2",
						"When Namespace SKU is set to Premium, Namespace Capacity must be 1 or a multiple of 2.",
						(value) => value === "1" || parseInt(value) % 2 === 0
					)
			}),
			event_hubs: yup.array().of(
				yup.object().shape({
					hub_name: yup.string().required("Hub Name is required"),
					partitions: yup.number().required("Partitions number is required"),
					retention: yup.number().required("Retention is required"),
					capture_enabled: yup.boolean().required(" This field is required"),
					capture_encoding: yup.string().when("capture_enabled", {
						is: true,
						then: (schema) => schema.required("Capture Enconding is required")
					}),
					capture_interval: yup.number().when("capture_enabled", {
						is: true,
						then: (schema) =>
							schema
								.required("Capture Interval is required.")
								.min(60, "Minimum interval is 60 seconds")
								.max(900, "Maximum interval is 900 seconds")
					}),
					capture_size: yup.number().when("capture_enabled", {
						is: true,
						then: (schema) =>
							schema
								.required("Capture Interval is required.")
								.min(10485760, "Values can be between 10485760 and 524288000 bytes")
								.max(524288000, "Values can be between 10485760 and 524288000 bytes")
					}),
					capture_archive_format: yup.string().when("capture_enabled", {
						is: true,
						then: (schema) => schema.required("Capture Archive Format is required")
					}),
					datalake_name: yup.string().when("capture_enabled", {
						is: true,
						then: (schema) => schema.required("Data Lake Name is required")
					}),
					datalake_resource_group: yup.string().when("capture_enabled", {
						is: true,
						then: (schema) => schema.required("Data Lake Resource Group is required")
					}),
					container_name: yup.string().when("capture_enabled", {
						is: true,
						then: (schema) => schema.required("Container Name is required")
					})
				})
			)
		})
	)
});
