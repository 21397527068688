import { Error404 as Error404Component } from "@hexa-ui/components";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { routes } from "router/constants";
import { ErrorPageStyled } from "../ErrorComponentStyled";

const Error404 = (): JSX.Element => {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	return (
		<ErrorPageStyled>
			<Error404Component
				headerText={formatMessage({ id: "ERROR_404.HEADER" })}
				subHeaderText={formatMessage({ id: "ERROR_404.DESCRIPTION" })}
				buttonText={formatMessage({ id: "ERROR_404.BUTTON_TEXT" })}
				buttonClickHandler={() => navigate(routes.HOME_PAGE)}
			/>
		</ErrorPageStyled>
	);
};

export default Error404;
