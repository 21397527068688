import { Card } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";
import { Link } from "react-router-dom";

export const DashboardCardStyled = styled(Card, {
	marginBottom: "$8",
	minHeight: 320,
	width: "100%",
	padding: "$6",
	display: "flex",

	".dashboard-card__heading": {
		justifyContent: "space-between",
		alignItems: "center",
		display: "flex"
	},

	".dashboard-card__body": {
		img: {
			width: "100%",
			margin: "1rem 0",
			height: "180px"
		}
	},

	".dashboard-card__badge": {
		justifyContent: "end",
		display: "flex"
	}
});

export const LinkStyled = styled(Link, {
	textDecoration: "none",
	margin: 0
});
