import { AddStackButton } from "pages/InfraServicesPages/components/AddStackButton/AddStackButton";
import { useFieldArray, useFormContext } from "react-hook-form";
import { DatabaseStackFields } from "./DatabaseStackFields";

export const DatabaseStackForm = () => {
	const { control } = useFormContext();
	const { fields, append, remove } = useFieldArray({
		control,
		name: "database_stack"
	});

	const handleAppend = () =>
		append({
			product_name: "",
			regions: [],
			primary_region: "",
			short_product_name: "",
			need_mysql: undefined,
			mysql_server_type: "",
			mysql_sku: "",
			mysql_version: "",
			mysql_extra_config: [],
			need_postgre: undefined,
			postgre_server_type: "single",
			postgre_sku: "",
			postgre_version: "",
			postgre_storage: "",
			postgre_extra_config: []
		});

	return (
		<>
			{fields?.map((field, index) => {
				return (
					<DatabaseStackFields
						key={field.id}
						fieldId={field.id}
						index={index}
						removeItem={remove}
						fields={fields}
					/>
				);
			})}
			<AddStackButton onClick={handleAppend} stackName="Database Stack" />
		</>
	);
};
