import { usePreferredLanguageV2 } from "admin-portal-shared-services";
import { ReactNode } from "react";
import { IntlProvider as NativeProvider } from "react-intl";
import MessageMap from "./i18n.d";
import enUS from "./languages/en-US";
import ptBR from "./languages/pt-BR";

const DEFAULT_LANGUAGE = "en-US";

type IIntlProvider = {
	children: ReactNode;
};

export const messages: { [language: string]: MessageMap } = {
	"en-US": enUS,
	"pt-BR": ptBR
};

export function IntlProvider({ children }: IIntlProvider): JSX.Element {
	const { preferredLanguage, defaultLanguage } = usePreferredLanguageV2();
	const mergedMessages = mergeMessages(messages, preferredLanguage || defaultLanguage);

	return (
		<NativeProvider
			locale={preferredLanguage || defaultLanguage}
			defaultLocale={DEFAULT_LANGUAGE}
			messages={mergedMessages}
		>
			{children}
		</NativeProvider>
	);
}

export const flattenObject = (ob: any): any => {
	const toReturn: { [key: string]: any } = {};

	for (const i in ob) {
		if (typeof ob[i] === "object" && ob[i] !== null) {
			const flatObject = flattenObject(ob[i]);
			for (const x in flatObject) {
				toReturn[`${i}.${x}`] = flatObject[x];
			}
		} else {
			toReturn[i] = ob[i];
		}
	}
	return toReturn;
};

export const mergeMessages = (messagesInput: MessageMap, selectedLocale: string): any => {
	const defaultMessages = flattenObject(messagesInput[DEFAULT_LANGUAGE]);
	const localeMessages = flattenObject(messagesInput[selectedLocale]);
	return { ...defaultMessages, ...localeMessages };
};

export default IntlProvider;
