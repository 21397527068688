export const convertFirstLetterToUppercase = (word: string): string => {
	return word.charAt(0).toUpperCase() + word.slice(1);
};

export const convertFirstLetterToLowercase = (word: string): string => {
	if (!word) return "";
	return word?.charAt(0).toLocaleLowerCase() + word?.slice(1);
};

export const insertBlankSpaces = (word: string) => {
	if (!word) return "";
	word = word.replace(/([a-z])([A-Z])/g, "$1 $2");
	word = word.replace(/([A-Z])([A-Z][a-z])/g, "$1 $2");
	return word;
};

export const removeBlankSpaces = (word) => word?.split(" ").join("");

export const formatDate = (date: number) => {
	const currentDate = new Date(date);
	const formattedDate = currentDate.toLocaleDateString("en-US", {
		month: "2-digit",
		day: "2-digit",
		year: "numeric"
	});
	return formattedDate;
};

export const convertToLowerCaseWithDash = (word: string) => {
	const lowerCaseString = word.toLowerCase();
	const stringWithDashes = lowerCaseString.replace(/\s+/g, "-");
	return stringWithDashes;
};
