import Error404 from "pages/ErrorPages/Error404/Error404";
import React from "react";
import Error403 from "./Error403/Error403";
import { Error500 } from "./Error500/Error500";
import Error503 from "./Error503/Error503";
import { IErrorComponentProps } from "./ErrorComponent.types";

const ErrorComponent = ({
	errorHasOccurred,
	children,
	errorCode,
	onClick
}: IErrorComponentProps): JSX.Element => {
	const ErrorCase = {
		403: <Error403 />,
		404: <Error404 />,
		503: <Error503 />,
		default: <Error500 onClick={onClick} />
	};
	return (
		<React.Fragment>
			{errorHasOccurred ? <>{ErrorCase[errorCode] || ErrorCase.default}</> : <>{children}</>}
		</React.Fragment>
	);
};

export default ErrorComponent;
