import { Paragraph } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const ParagraphStyled = styled(Paragraph, {
	marginLeft: "$2"
});

export const DescriptionTenantForm = () => {
	return (
		<>
			<ParagraphStyled size="xsmall" colortype="disabled">
				Please inform:
			</ParagraphStyled>
			<ul>
				<Paragraph size="xsmall" colortype="disabled">
					<li>Which storage account in the ABI tenant you want to access ex: beeseastus2data;</li>
					<li>Which container in this storage account you want access to ex: silver-abi;</li>
					<li>
						Which Databricks workspace you are going to access from ex:
						dbwdevam-ldz008-hivemind-app;
					</li>
					<li>
						Which KeyVault the token should be inserted into ex: kvdevamldz008aphvmdef Link to the
						encrypt pipeline on ADO for the key vault mentioned;
					</li>
				</Paragraph>
			</ul>
		</>
	);
};
