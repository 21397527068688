import { IInfraForm } from "pages/InfraServicesPages/InfraNewDomainPage/InfraNewDomain.types";
import { ReactNode, createContext, useMemo, useState } from "react";

export interface IInfraFormProviderProps {
	infraFormData: IInfraForm;
	setInfraFormData: (value) => void;
}

export const InfraFormContext = createContext<IInfraFormProviderProps>({
	infraFormData: null,
	setInfraFormData: () => null
});

export const InfraFormProvider = ({ children }: { children: ReactNode }) => {
	const [infraFormData, setInfraFormData] = useState<IInfraForm>();
	const providerValues = useMemo(() => ({ infraFormData, setInfraFormData }), [infraFormData]);

	return <InfraFormContext.Provider value={providerValues}>{children}</InfraFormContext.Provider>;
};
