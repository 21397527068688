import { useEffect } from "react";

export const useResizeObserverErrorHandler = () => {
	useEffect(() => {
		const handleError = (e) => {
			if (e.message === "ResizeObserver loop limit exceeded") {
				const resizeObserverErrDiv = document.getElementById(
					"webpack-dev-server-client-overlay-div"
				);

				const resizeObserverErr = document.getElementById("webpack-dev-server-client-overlay");
				resizeObserverErr.setAttribute("style", "display: none");
				resizeObserverErrDiv.setAttribute("style", "display: none");
			}
		};

		window.addEventListener("error", handleError);

		return () => {
			window.removeEventListener("error", handleError);
		};
	}, []);
};
