import { Tabs } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const { Trigger, List } = Tabs;

export const TriggerStyled = styled(Trigger, {
	padding: "0 13px"
});

export const ListStyled = styled(List, {
	display: "flex",
	width: "100%",
	flexWrap: "wrap"
});
