import { Card } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const ContainerCardStyled = styled(Card, {
	width: "100%",
	padding: "$6",
	margin: "$8 0",
	input: {
		width: "100%"
	},
	textarea: {
		width: "100%"
	}
});
