import { Card, Grid } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const { Item } = Grid;

export const GeneralInformationCardStyled = styled(Card, {
	padding: "24px",
	width: "100%"
});

export const HeaderStyled = styled("div", {
	display: "flex",
	width: "100%",
	justifyContent: "space-between",
	marginBottom: "24px"
});

export const InformationWrapper = styled("div", {
	display: "flex",
	justifyContent: "space-between",
	alignItems: "flex-start"
});

export const InformationItemStyled = styled("div", {
	variants: {
		spacing: {
			basis: {
				marginBottom: "24px"
			},
			large: {
				marginBottom: "32px"
			}
		}
	},
	display: "flex",
	flexDirection: "column",
	rowGap: "4px"
});

export const ExternalLinkWrapper = styled("div", {
	marginBottom: "24px",
	alignItems: "center",
	display: "flex",
	gap: "8px"
});

export const ColumnItem = styled(Item, {
	display: "block"
});
