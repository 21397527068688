import { InfraFormContext } from "contexts";
import { useValidateUniqueValues } from "hooks/useValidateUniqueValues";
import { FormHeader } from "pages/InfraServicesPages/components/FormHeader";
import { infraStacksInfo } from "pages/InfraServicesPages/components/InfraStacksInfo";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { IStackFieldsProps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { BasicStackForm } from "../BasicStack/BasicStackForm";

export const DataShareStackFields = ({ index, removeItem, fields, fieldId }: IStackFieldsProps) => {
	const { infraFormData } = useContext(InfraFormContext);
	const stackInfo = infraStacksInfo.filter((stack) => stack.id === "data-share-stack")?.[0];
	const { watch, getValues, setValue } = useFormContext();
	const formValues = watch("data_share_stack");
	const error = useValidateUniqueValues(formValues, index, "product_name", "Product Name");

	useEffect(() => {
		setValue(`data_share_stack.${index}.id`, fieldId);
	}, [fieldId, infraFormData]);

	return (
		<ContainerCardStyled border="small" elevated="medium" data-testid="data-share-stack-form">
			<FormHeader
				stackInfo={stackInfo}
				fields={fields}
				removeItem={removeItem}
				index={index}
				fieldId={fieldId}
				formSection="data_share_stack"
			/>
			<BasicStackForm
				acceptsShortProductName
				formSection="data_share_stack"
				index={index}
				error={error}
			/>
		</ContainerCardStyled>
	);
};
