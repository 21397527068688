export const handleArrayFields = (value: string) => {
	return typeof value === "string" ? value?.split(",").map((item) => item.trim()) : null;
};

export const handleChange = (getValues, setInfraFormData) => {
	const values = getValues();
	setInfraFormData((previousState) => ({
		...previousState,
		...values
	}));
};

export const handleRemoveFormItem = (getValues, setInfraFormData, fieldId, formSection) => {
	const values = getValues();
	const newFormValues = values[formSection].filter((value) => value.id !== fieldId);
	setInfraFormData((previousState) => ({
		...previousState,
		[formSection]: newFormValues
	}));
};

export const cleanDependentFields = (
	infraFormData,
	setValue,
	formSection,
	index,
	leaderField,
	dependentFields
) => {
	!infraFormData?.[formSection]?.[index]?.[leaderField] &&
		dependentFields.map((field) => {
			setValue(`${formSection}.${index}.${field}`, undefined);
		});
};

export const setupInput = (errors, formSection, fieldName, index, infraFormData) => {
	return {
		hasError: !!errors?.[formSection]?.[index]?.[fieldName],
		errorText: errors?.[formSection]?.[index]?.[fieldName]?.message as string,
		value: infraFormData?.[formSection]?.[index]?.[fieldName]
	};
};
