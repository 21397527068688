import { Card, Grid } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const { Item } = Grid;

export const ItemStyled = styled(Item, {
	padding: 0,
	justifyContent: "center"
});

export const CardWrapperStyled = styled(Card, {
	minHeight: 150,
	padding: "$6 $6 $8",
	margin: "$2",
	display: "flex",
	alignContent: "space-between",
	label: {
		fontFamily: "$heading",
		fontWeight: "$semibold",
		lineHeight: "$6",
		fontSize: "$5"
	},
	".documentation-link": {
		paddingLeft: "$6",
		fontWeight: "$medium"
	}
});

export const CardContentStyled = styled("div", {
	height: "100%",
	display: "flex",
	alignContent: "space-between",
	flexWrap: "wrap"
});

export const CardHeaderStyled = styled("div", {
	button: {
		margin: "$1"
	},
	p: {
		padding: "$6"
	}
});
