export const Chart9 = () => (
	<svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 731.39 365.7">
		<g id="a984a315-a4df-4381-b9f0-5d4b65ce970a" data-name="Layer 6">
			<rect
				y={0.86}
				width={731.39}
				height={364.84}
				style={{
					fill: "none"
				}}
			/>
			<line
				y1={250.17}
				x2={731.37}
				y2={250.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10
				}}
			/>
			<line
				x1={-0.03}
				y1={193.17}
				x2={731.41}
				y2={193.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={307.17}
				x2={731.41}
				y2={307.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={136.17}
				x2={731.41}
				y2={136.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={79.17}
				x2={731.41}
				y2={79.17}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
			<line
				x1={-0.03}
				y1={20}
				x2={731.39}
				y2={20}
				style={{
					fill: "none",
					stroke: "silver",
					strokeMiterlimit: 10,
					strokeWidth: "0.98px"
				}}
			/>
		</g>
		<g id="b0d0266c-edea-430c-a095-222fe920a5b0" data-name="Layer 2">
			<rect
				x={42.87}
				y={254.14}
				width={20.68}
				height={110.39}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={64.74}
				y={302.68}
				width={20.68}
				height={61.85}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={98.92}
				y={246.03}
				width={20.68}
				height={118.5}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={120.79}
				y={278.82}
				width={20.68}
				height={85.71}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={154.97}
				y={209.41}
				width={20.68}
				height={155.12}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={176.84}
				y={260.24}
				width={20.68}
				height={104.29}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={211.02}
				y={164.06}
				width={20.68}
				height={199.38}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={232.89}
				y={224.99}
				width={20.68}
				height={139.54}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={267.07}
				y={193.29}
				width={20.68}
				height={171.24}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={288.94}
				y={232.09}
				width={20.68}
				height={132.44}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={323.12}
				y={185.92}
				width={20.68}
				height={178.62}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={344.99}
				y={219.53}
				width={20.68}
				height={145.01}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={379.17}
				y={205.59}
				width={20.68}
				height={158.94}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={401.04}
				y={237.56}
				width={20.68}
				height={126.97}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={435.22}
				y={150.39}
				width={20.68}
				height={214.14}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={457.09}
				y={170.89}
				width={20.68}
				height={193.64}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={491.27}
				y={227.72}
				width={20.68}
				height={135.17}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={513.13}
				y={234.55}
				width={20.68}
				height={129.98}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={547.32}
				y={205.59}
				width={20.68}
				height={158.94}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={569.18}
				y={221.71}
				width={20.68}
				height={142.82}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={603.37}
				y={209.96}
				width={20.68}
				height={153.2}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={625.23}
				y={225.54}
				width={20.68}
				height={138.99}
				style={{
					fill: "#f8e090"
				}}
			/>
			<rect
				x={659.42}
				y={190.29}
				width={20.68}
				height={174.24}
				style={{
					fill: "#fad360"
				}}
			/>
			<rect
				x={681.28}
				y={209.96}
				width={20.68}
				height={154.57}
				style={{
					fill: "#f8e090"
				}}
			/>
			<polyline
				points="62.54 292.85 122.2 216.34 172.38 233.82 233.85 230.18 343.69 166.06 404.26 177.72 457.59 138.37 515.9 109.95 573.31 136.18 646.99 140.56 692.19 142.74"
				style={{
					fill: "none",
					stroke: "#f8ca2d",
					strokeLinecap: "round",
					strokeLinejoin: "round",
					strokeWidth: 3
				}}
			/>
		</g>
	</svg>
);
