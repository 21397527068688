import { IconButton, Toggle, Tooltip } from "@hexa-ui/components";
import { Edit2, Trash2 } from "@hexa-ui/icons";
import { TypeToast } from "admin-portal-shared-services";
import DeleteReportModal from "components/Modals/DeleteReportModal/DeleteReportModal";
import ReportModal from "components/Modals/ReportModal/ReportModal";
import { returnScopeIds } from "components/Modals/ReportModal/components/reportModalSupportFunctions";
import { useReportsContext } from "contexts";
import { useState } from "react";
import { useIntl } from "react-intl";
import { editReport } from "services/Report/ReportService";
import { INewReport, IReport } from "types/Report.types";
import { showNotify } from "utils/notify";
import { EditReportsButtonStyled } from "./EditReportActionButtonsStyled";

export interface IEditReportsActionButtonsProps {
	report: IReport;
}

const EditReportActionButtons = ({ report }) => {
	const { setUpdateReportsDate } = useReportsContext();
	const { formatMessage } = useIntl();
	const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
	const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
	const [editedReport, setEditedReport] = useState({} as INewReport);

	const handleEditModalOpen = async () => {
		setEditedReport(report);
		setIsEditModalOpen(true);
	};

	const handleDeleteModalOpen = () => {
		setIsDeleteModalOpen(true);
	};

	const validateToggleSuccessMessage = () => {
		report.isEnabled
			? showNotify(formatMessage({ id: "REPORT.TOAST_DISABLED_SUCCESS" }), TypeToast.SUCCESS)
			: showNotify(formatMessage({ id: "REPORT.TOAST_ENABLED_SUCCESS" }), TypeToast.SUCCESS);
	};

	const validateToggleErrorMessage = () => {
		report.isEnabled
			? showNotify(formatMessage({ id: "REPORT.TOAST_DISABLED_ERROR" }), TypeToast.ERROR)
			: showNotify(formatMessage({ id: "REPORT.TOAST_ENABLED_ERROR" }), TypeToast.ERROR);
	};

	const handleReportIsEnabled = async () => {
		const editedReportData = {
			...report,
			scopesId: returnScopeIds(report.scopes),
			domainId: report.domain.domainId,
			isEnabled: !report.isEnabled,
			uploadDate: new Date(report.uploadDate).getTime()
		};
		try {
			await editReport(report.reportId, editedReportData);
			setUpdateReportsDate(new Date());
			validateToggleSuccessMessage();
		} catch {
			validateToggleErrorMessage();
		}
	};

	return (
		<EditReportsButtonStyled>
			<Tooltip placement="left" text={report.isEnabled ? `Disable Report` : `Enable Report`}>
				<Toggle
					aria-label={formatMessage({ id: "ARIA_TOGGLE.ENABLE_REPORT" })}
					checked={report.isEnabled}
					data-testid="toggle-button"
					onCheckedChange={handleReportIsEnabled}
				/>
			</Tooltip>
			<Tooltip placement="top" text={formatMessage({ id: "ARIA_BUTTON.EDIT_REPORT" })}>
				<IconButton
					icon={Edit2}
					size="small"
					aria-label={formatMessage({ id: "ARIA_BUTTON.EDIT_REPORT" })}
					data-testid="icon-button-edit"
					onClick={handleEditModalOpen}
				/>
			</Tooltip>
			<Tooltip placement="top" text={formatMessage({ id: "ARIA_BUTTON.DELETE_REPORT" })}>
				<IconButton
					icon={Trash2}
					size="small"
					aria-label={formatMessage({ id: "ARIA_BUTTON.DELETE_REPORT" })}
					data-testid="icon-button-delete"
					onClick={handleDeleteModalOpen}
				/>
			</Tooltip>

			{isEditModalOpen && (
				<ReportModal
					report={editedReport}
					isOpen={isEditModalOpen}
					onClose={() => {
						setIsEditModalOpen(false);
					}}
				/>
			)}

			{isDeleteModalOpen && (
				<DeleteReportModal
					report={report}
					isOpen={isDeleteModalOpen}
					onClose={() => {
						setIsDeleteModalOpen(false);
					}}
				/>
			)}
		</EditReportsButtonStyled>
	);
};

export default EditReportActionButtons;
