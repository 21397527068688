import { StylesProvider, createGenerateClassName } from "@material-ui/core";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { ReactQueryDevtools } from "@tanstack/react-query-devtools";
import { AuthProvider } from "contexts/AuthProvider/AuthProvider";
import { EnvConfig, EnvProvider } from "contexts/EnvProvider/EnvProvider";
import { LoaderProvider } from "contexts/LoaderContext/LoaderContext";
import { ReportsProvider } from "contexts/ReportsContext/ReportsContext";
import { ViewportProvider } from "contexts/ViewportContext/ViewportContext";

import { IntlProvider } from "i18n";
import { FC, ReactNode } from "react";

interface IApplicationProviders {
	children: ReactNode;
	props: EnvConfig;
}

export const ApplicationProviders: FC<IApplicationProviders> = ({ children, props }) => {
	const queryClient = new QueryClient({
		defaultOptions: {
			queries: {
				staleTime: Infinity,
				cacheTime: 0,
				retry: true,
				refetchOnWindowFocus: false
			}
		}
	});

	const generateClassName = createGenerateClassName({
		productionPrefix: "bees-hub-data-portal-mfe",
		seed: "bees-hub-data-portal-mfe"
	});

	return (
		<ViewportProvider>
			<EnvProvider env={props}>
				<AuthProvider>
					<StylesProvider generateClassName={generateClassName}>
						<QueryClientProvider client={queryClient}>
							<ReactQueryDevtools initialIsOpen={false} />
							<>
								<IntlProvider>
									<LoaderProvider>
										<ReportsProvider>{children}</ReportsProvider>
									</LoaderProvider>
								</IntlProvider>
							</>
						</QueryClientProvider>
					</StylesProvider>
				</AuthProvider>
			</EnvProvider>
		</ViewportProvider>
	);
};
