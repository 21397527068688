import { Grid } from "@hexa-ui/components";
import { CategoryAdd, Edit2 } from "@hexa-ui/icons";
import { setAppHeaderConfig } from "admin-portal-shared-services";
import { useEffect, useState } from "react";
import { routes } from "router/constants";
import { InfraServiceCard } from "../components/InfraServiceCard/InfraServiceCard";
import { HeadingStyled } from "../modules/GeneralQuestions/GeneralQuestionsFormsStyled";
import { InfraNewResourceEditForm } from "./InfraNewResourceEditForm";
import { ServicesWrapperStyled } from "./InfraNewResourcePageStyled";
import { InfraNewResourceStackForm } from "./InfraNewResourceStackForm";

const { Container } = Grid;

const InfraNewResourcePage = () => {
	const [newResource, setNewResource] = useState<string>("");
	useEffect(() => {
		setAppHeaderConfig({
			pageTitle: "New Resource Request",
			breadcrumbConfig: {
				homePath: routes.HOME_PAGE,
				items: [
					{
						label: "Data Infra Services",
						path: routes.INFRA_SERVICES.INFRA_SERVICES,
						isCurrentPage: false
					},
					{
						label: "Request New Resource",
						path: routes.INFRA_SERVICES.NEW_RESOURCE,
						isCurrentPage: true
					}
				]
			}
		});
	}, [setAppHeaderConfig]);

	return (
		<Container data-testid="infra-services-page" type="fluid" sidebar>
			{!newResource && (
				<>
					<HeadingStyled size="H3">What do you need?</HeadingStyled>
					<ServicesWrapperStyled>
						<InfraServiceCard
							icon={<Edit2 />}
							title="Edit Information"
							subtitle="In case you need to edit any information on the infrastructure you already have."
							onClick={() => setNewResource("Edit Information")}
						/>
						<InfraServiceCard
							icon={<CategoryAdd />}
							title="New Stack"
							subtitle="In case you need to request a whole new stack for your domain."
							onClick={() => setNewResource("New Stack")}
						/>
					</ServicesWrapperStyled>
				</>
			)}
			{newResource === "Edit Information" && (
				<InfraNewResourceEditForm setNewResource={setNewResource} />
			)}
			{newResource === "New Stack" && <InfraNewResourceStackForm setNewResource={setNewResource} />}
		</Container>
	);
};

export default InfraNewResourcePage;
