import * as pbi from "powerbi-client";
import { PowerBIEmbed } from "powerbi-client-react";
import { ReportCardStyled } from "./ReportCardStyled";

export interface IReportCardProps {
	id: string;
	embedUrl: string;
	accessToken: string;
}

enum EReportType {
	REPORT = "report",
	DASHBOARD = "dashboard",
	TILE = "tile",
	VISUAL = "visual",
	QNA = "qna"
}

export const ReportCard = ({ id, embedUrl, accessToken }: IReportCardProps): JSX.Element => {
	return (
		<ReportCardStyled data-testid="report-card">
			<PowerBIEmbed
				cssClassName="report-style-class"
				embedConfig={{
					type: EReportType.REPORT,
					id: id,
					embedUrl: embedUrl,
					accessToken: accessToken,
					tokenType: pbi.models.TokenType.Embed,
					settings: {
						panes: {
							filters: {
								visible: false
							}
						}
					}
				}}
			/>
		</ReportCardStyled>
	);
};
