import { Select } from "@hexa-ui/components";

export const getJiraSelectOptions = (fieldId: string, requestTypeInfo) => {
	const fieldObject =
		requestTypeInfo.length > 0 && requestTypeInfo?.filter((field) => field.fieldId === fieldId);
	const fieldOptions = fieldObject?.[0]?.validValues.map((optionValue) => {
		return (
			<Select.Option key={optionValue.value} value={optionValue.label}>
				{optionValue?.label}
			</Select.Option>
		);
	});
	return fieldOptions;
};
