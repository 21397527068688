import { Paragraph } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const ParagraphStyled = styled(Paragraph, {
	paddingBottom: "$4"
});

export const ButtonsWrapper = styled("div", {
	display: "flex",
	width: "100%",
	justifyContent: "flex-end",
	marginTop: "$4",
	gap: "$4"
});
