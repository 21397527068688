import { useAuthenticationService } from "admin-portal-shared-services";
import RouterLoader from "components/shared/RouterLoader/RouterLoader";
import { AuthContext } from "contexts";
import TenantAccessPage from "pages/AccessManagementPages/TenantAccessPage/TenantAccessPage";
import EditReportsPage from "pages/EditReportsPage/EditReportsPage";
import Error403 from "pages/ErrorPages/Error403/Error403";
import Error404 from "pages/ErrorPages/Error404/Error404";
import InfraNewResourcePage from "pages/InfraServicesPages/InfraNewResourcePage/InfraNewResourcePage";
import DatasetDetailsPage from "pages/SelfServiceBi/DatasetDetailsPage/DatasetDetailsPage";
import SelfServiceBiPage from "pages/SelfServiceBi/SelfServiceBiPage";
import { lazy, Suspense, useContext } from "react";
import { Route, Routes } from "react-router-dom";
import { isBeesDataPortalUser, isBeesUser } from "utils/identity";
import { routes } from "./constants";

const HomePage = lazy(() => import("pages/HomePage/HomePage"));
const GlobalReportsPage = lazy(() => import("pages/GlobalReportsPage/GlobalReportsPage"));
const ReportPage = lazy(() => import("pages/ReportPage/ReportPage"));
const UsageMetrics = lazy(() => import("pages/UsageMetricsPage/UsageMetricsPage"));
const InfraServicesPage = lazy(() => import("pages/InfraServicesPages/InfraServicesPage"));
const InfraNewDomainPage = lazy(
	() => import("pages/InfraServicesPages/InfraNewDomainPage/InfraNewDomainPage")
);
const InfraAllRequestsPage = lazy(
	() => import("pages/InfraServicesPages/InfraAllRequestsPage/InfraAllRequestsPage")
);
const RequestAccessManagementPage = lazy(
	() =>
		import("pages/AccessManagementPages/RequestAccessManagementPage/RequestAccessManagementPage")
);
const AccessManagementPage = lazy(() => import("pages/AccessManagementPages/AccessManagementPage"));
const SegmentAccess = lazy(() => import("pages/AccessManagementPages/SegmentAccess/SegmentAccess"));
const SnowflakeDtcAccess = lazy(
	() => import("pages/AccessManagementPages/SnowflakeDtcAccessPage/SnowflakeDtcAccessPage")
);
const UnityCatalogDeltaSharingPage = lazy(
	() => import("pages/AccessManagementPages/UnityCatalogPages/DeltaSharingPage/DeltaSharingPage")
);
const UnityCatalogRequests = lazy(
	() => import("pages/AccessManagementPages/UnityCatalogPages/RequestsPage/RequestOptionsPage")
);
const UnityCatalogNewDomain = lazy(
	() => import("pages/AccessManagementPages/UnityCatalogPages/NewDomain/NewDomainPage")
);
const UnityCatalogInternalSharing = lazy(
	() => import("pages/AccessManagementPages/UnityCatalogPages/InternalSharing/InternalSharingPage")
);

const FallbackPage = <RouterLoader />;

const Router = (): JSX.Element => {
	const { isPublisher } = useContext(AuthContext);
	const authService = useAuthenticationService();
	const scopes = authService.getUserScopes();

	const RestrictedPublisherRoute = (page: React.ReactElement) => {
		if (isPublisher) {
			return <Suspense fallback={FallbackPage}>{page}</Suspense>;
		}
		return <Error403 />;
	};

	const RestrictedDataPortalUserRoute = (page: React.ReactElement) => {
		if (isBeesDataPortalUser(scopes)) {
			return <Suspense fallback={FallbackPage}>{page}</Suspense>;
		}
		return <Error403 />;
	};

	const RestrictedBeesUserRoute = (page: React.ReactElement) => {
		if (isBeesUser(scopes)) {
			return <Suspense fallback={FallbackPage}>{page}</Suspense>;
		}
		return <Error403 />;
	};
	return (
		<Routes>
			<Route
				path={routes.HOME_PAGE}
				element={
					<Suspense fallback={FallbackPage}>
						<HomePage />
					</Suspense>
				}
			/>
			<Route
				path={routes.SELF_SERVICE_BI.SELF_SERVICE_BI}
				element={RestrictedDataPortalUserRoute(<SelfServiceBiPage />)}
			/>
			<Route path={routes.SELF_SERVICE_BI.DATASET_DETAILS} element={<DatasetDetailsPage />} />
			<Route path={routes.USAGE_METRICS} element={RestrictedBeesUserRoute(<UsageMetrics />)} />
			<Route
				path={routes.REQUEST_ACCESS}
				element={
					<Suspense fallback={FallbackPage}>
						<RequestAccessManagementPage />
					</Suspense>
				}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.ACCESS_MANAGEMENT}
				element={RestrictedBeesUserRoute(<AccessManagementPage />)}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.SEGMENT_ACCESS}
				element={RestrictedBeesUserRoute(<SegmentAccess />)}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.TENANT_ACCESS}
				element={RestrictedBeesUserRoute(<TenantAccessPage />)}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.SNOWFLAKE_DTC_ACCESS}
				element={RestrictedBeesUserRoute(<SnowflakeDtcAccess />)}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.UNITY_CATALOG_REQUESTS}
				element={RestrictedBeesUserRoute(<UnityCatalogRequests />)}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.UNITY_CATALOG_NEW_DOMAIN}
				element={RestrictedBeesUserRoute(<UnityCatalogNewDomain />)}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.UNITY_CATALOG_INTERNAL_SHARING}
				element={RestrictedBeesUserRoute(<UnityCatalogInternalSharing />)}
			/>
			<Route
				path={routes.ACCESS_MANAGEMENT.UNITY_CATALOG_DELTA_SHARING}
				element={RestrictedBeesUserRoute(<UnityCatalogDeltaSharingPage />)}
			/>
			<Route
				path={routes.GLOBAL_REPORTS.REPORT_DETAILS}
				element={RestrictedDataPortalUserRoute(<ReportPage />)}
			/>
			<Route
				path={routes.GLOBAL_REPORTS.GLOBAL_REPORTS_DOMAIN}
				element={RestrictedDataPortalUserRoute(<GlobalReportsPage />)}
			/>
			<Route
				path={routes.GLOBAL_REPORTS.BASE_GLOBAL_REPORTS}
				element={RestrictedDataPortalUserRoute(<GlobalReportsPage />)}
			/>
			<Route
				path={routes.GLOBAL_REPORTS.EDIT_REPORTS}
				element={RestrictedPublisherRoute(<EditReportsPage />)}
			/>
			<Route
				path={routes.INFRA_SERVICES.NEW_DOMAIN}
				element={RestrictedBeesUserRoute(<InfraNewDomainPage />)}
			/>
			<Route
				path={routes.INFRA_SERVICES.NEW_RESOURCE}
				element={RestrictedBeesUserRoute(<InfraNewResourcePage />)}
			/>
			<Route
				path={routes.INFRA_SERVICES.ALL_REQUESTS}
				element={RestrictedBeesUserRoute(<InfraAllRequestsPage />)}
			/>
			<Route
				path={routes.INFRA_SERVICES.INFRA_SERVICES}
				element={RestrictedBeesUserRoute(<InfraServicesPage />)}
			/>
			<Route path="*" element={<Error404 />} />
		</Routes>
	);
};

export default Router;
