/**
 * This client was automatically generated by Segment Typewriter. ** Do Not Edit **
 */

import * as Segment from './segment'

export interface BeesGlobalReportDeleted {
	/**
	 * The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
	 */
	country: string | null
	/**
	 * The date the report was viewed
	 */
	date: string | null
	/**
	 * The Id of the report
	 */
	report_id: string | null
	/**
	 * The e-mail of the user that saw the report
	 */
	user_email: string | null
}
export interface BeesGlobalReportViewed {
	/**
	 * The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
	 */
	country: string | null
	/**
	 * The date the report was viewed
	 */
	date: string | null
	/**
	 * The Id of the report
	 */
	report_id: string | null
	/**
	 * The name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Global Reports', 'Care Reports'.
	 */
	screen_name: string | null
	/**
	 * The e-mail of the user that saw the report
	 */
	user_email: string | null
}
export interface BeesGlobalReportsPageViewed {
	/**
	 * The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
	 */
	country: string | null
	/**
	 * The number of reports at the tab
	 */
	dashboards_numbers: number | null
	/**
	 * The date the report was viewed
	 */
	date: string | null
	/**
	 * The number of the page being viewed. Samples: 3
	 */
	page_number: number | null
	/**
	 * The name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Global Reports', 'Care Reports'.
	 */
	screen_name: string | null
}
export interface ReportClicked {
	/**
	 * The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
	 */
	country: string | null
	/**
	 * The dashboard name
	 */
	dashboard_name: string | null
	/**
	 * The date the report was viewed
	 */
	date: string | null
	/**
	 * The domain data
	 */
	domain: string | null
	/**
	 * The Id of the report
	 */
	report_id: string | null
	/**
	 * The name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Global Reports', 'Care Reports'.
	 */
	screen_name: string | null
}
export interface TabGlobalReportSelected {
	/**
	 * The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
	 */
	country: string | null
	/**
	 * The number of reports at the tab
	 */
	dashboards_numbers: number | null
	/**
	 * The date the report was viewed
	 */
	date: string | null
	/**
	 * The name of the tab with reports
	 */
	tab_name: string | null
}

export type ViolationHandler = (
	message: Record<string, any>,
	violations: any[]
) => void

/**
 * The default handler that is fired if none is supplied with setTypewriterOptions.
 * This handler will log a warning message to the console.
 */
export const defaultValidationErrorHandler: ViolationHandler = (
	message,
	violations
) => {
	const msg = JSON.stringify(
		{
			type: 'Typewriter JSON Schema Validation Error',
			description:
				`You made an analytics call (${message.event}) using Typewriter that doesn't match the ` +
				'Tracking Plan spec.',
			errors: violations,
		},
		undefined,
		2
	)

	console.warn(msg)
}

let analytics: () => SegmentAnalytics.AnalyticsJS | undefined = () => {
	return window.analytics
}

/** Options to customize the runtime behavior of a Typewriter client. */
export interface TypewriterOptions {
	/**
	 * Underlying analytics instance where analytics calls are forwarded on to.
	 * Defaults to window.analytics.
	 */
	analytics?: SegmentAnalytics.AnalyticsJS
	/**
	 * Handler fired when if an event does not match its spec. This handler
	 * does not fire in production mode, because it requires inlining the full
	 * JSON Schema spec for each event in your Tracking Plan.
	 *
	 * By default, it will throw errors if NODE_ENV = "test" so that tests will fail
	 * if a message does not match the spec. Otherwise, errors will be logged to stderr.
	 */
	onViolation?: ViolationHandler
}

/**
 * Updates the run-time configuration of this Typewriter client.
 *
 * @param {TypewriterOptions} options - the options to upsert
 *
 * @typedef {Object} TypewriterOptions
 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
 * 		calls are forwarded on to. Defaults to window.analytics.
 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
 * 		will be logged to stderr.
 */
export function setTypewriterOptions(options: TypewriterOptions) {
	analytics = options.analytics
		? () => options.analytics || window.analytics
		: analytics
}

/**
 * Helper to attach metadata on Typewriter to outbound requests.
 * This is used for attribution and debugging by the Segment team.
 */
function withTypewriterContext(message: Segment.Options = {}): Segment.Options {
	return {
		...message,
		context: {
			...(message.context || {}),
			typewriter: {
				language: 'typescript',
				version: '7.0.2-0',
			},
		},
	}
}

/**
 * @typedef BeesGlobalReportDeleted
 * @property {string | null} country - The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
 * @property {string | null} date - The date the report was viewed
 * @property {string | null} report_id - The Id of the report
 * @property {string | null} user_email - The e-mail of the user that saw the report
 */
/**
 * @typedef BeesGlobalReportViewed
 * @property {string | null} country - The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
 * @property {string | null} date - The date the report was viewed
 * @property {string | null} report_id - The Id of the report
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Global Reports', 'Care Reports'.
 * @property {string | null} user_email - The e-mail of the user that saw the report
 */
/**
 * @typedef BeesGlobalReportsPageViewed
 * @property {string | null} country - The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
 * @property {number | null} dashboards_numbers - The number of reports at the tab
 * @property {string | null} date - The date the report was viewed
 * @property {number | null} page_number - The number of the page being viewed. Samples: 3
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Global Reports', 'Care Reports'.
 */
/**
 * @typedef ReportClicked
 * @property {string | null} country - The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
 * @property {string | null} dashboard_name - The dashboard name
 * @property {string | null} date - The date the report was viewed
 * @property {string | null} domain - The domain data
 * @property {string | null} report_id - The Id of the report
 * @property {string | null} screen_name - The name of the screen where the user is taking this action. The CURRENT screen. Samples: 'Global Reports', 'Care Reports'.
 */
/**
 * @typedef TabGlobalReportSelected
 * @property {string | null} country - The country set in contact information in ISO Alpha 2 pattern. Samples: DO, ZA, AR
 * @property {number | null} dashboards_numbers - The number of reports at the tab
 * @property {string | null} date - The date the report was viewed
 * @property {string | null} tab_name - The name of the tab with reports
 */

/**
 * Triggered when the user delete one report



 *
 * @param {BeesGlobalReportDeleted} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function beesGlobalReportDeleted(
	props?: BeesGlobalReportDeleted,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bees Global Report Deleted',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user select to see one report




 *
 * @param {BeesGlobalReportViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function beesGlobalReportViewed(
	props?: BeesGlobalReportViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bees Global Report Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects the "Bees Global Reports" button
 *
 * @param {BeesGlobalReportsPageViewed} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function beesGlobalReportsPageViewed(
	props?: BeesGlobalReportsPageViewed,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Bees Global Reports Page Viewed',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user selects a dashboard inside any tab
 *
 * @param {ReportClicked} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function reportClicked(
	props?: ReportClicked,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Report Clicked',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}
/**
 * Triggered when the user select one of the tabs of GlobalReports screen



 *
 * @param {TabGlobalReportSelected} [props] - The analytics properties that will be sent to Segment.
 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
 * 		call is fired.
 */
export function tabGlobalReportSelected(
	props?: TabGlobalReportSelected,
	options?: Segment.Options,
	callback?: Segment.Callback
): void {
	const a = analytics()
	if (a) {
		a.track(
			'Tab Global Report Selected',
			props || {},
			withTypewriterContext(options),
			callback
		)
	}
}

const clientAPI = {
	/**
	 * Updates the run-time configuration of this Typewriter client.
	 *
	 * @param {TypewriterOptions} options - the options to upsert
	 *
	 * @typedef {Object} TypewriterOptions
	 * @property {Segment.AnalyticsJS} [analytics] - Underlying analytics instance where analytics
	 * 		calls are forwarded on to. Defaults to window.analytics.
	 * @property {Function} [onViolation] - Handler fired when if an event does not match its spec. This handler does not fire in
	 * 		production mode, because it requires inlining the full JSON Schema spec for each event in your Tracking Plan. By default,
	 * 		it will throw errors if NODE_ENV="test" so that tests will fail if a message does not match the spec. Otherwise, errors
	 * 		will be logged to stderr.
	 */
	setTypewriterOptions,
	/**
	 * Triggered when the user delete one report
	
	
	
	 *
	 * @param {BeesGlobalReportDeleted} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	beesGlobalReportDeleted,
	/**
	 * Triggered when the user select to see one report
	
	
	
	
	 *
	 * @param {BeesGlobalReportViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	beesGlobalReportViewed,
	/**
	 * Triggered when the user selects the "Bees Global Reports" button
	 *
	 * @param {BeesGlobalReportsPageViewed} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	beesGlobalReportsPageViewed,
	/**
	 * Triggered when the user selects a dashboard inside any tab
	 *
	 * @param {ReportClicked} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	reportClicked,
	/**
	 * Triggered when the user select one of the tabs of GlobalReports screen
	
	
	
	 *
	 * @param {TabGlobalReportSelected} [props] - The analytics properties that will be sent to Segment.
	 * @param {Object} [options] - A dictionary of options. For example, enable or disable specific destinations for the call.
	 * @param {Function} [callback] - An optional callback called after a short timeout after the analytics
	 * 		call is fired.
	 */
	tabGlobalReportSelected,
}

export default new Proxy<typeof clientAPI>(clientAPI, {
	get(target, method) {
		if (typeof method === 'string' && target.hasOwnProperty(method)) {
			return target[method as keyof typeof clientAPI]
		}

		return () => {
			console.warn(`⚠️  You made an analytics call (${String(
				method
			)}) that can't be found. Either:
    a) Re-generate your typewriter client: \`npx typewriter\`
    b) Add it to your Tracking Plan: https://app.segment.com/abi-delta/protocols/tracking-plans/rs_2LNUHJYheM1TAN5Qx3x9xK6yojA`)
			const a = analytics()
			if (a) {
				a.track(
					'Unknown Analytics Call Fired',
					{
						method,
					},
					withTypewriterContext()
				)
			}
		}
	},
})
