const IconPowerBi = (props) => {
	return (
		<svg
			xmlns="http://www.w3.org/2000/svg"
			x="0px"
			y="0px"
			viewBox="0 0 309.8 326.6"
			xmlSpace="preserve"
			{...props}
			width="22px"
		>
			<path
				d="M305.6 185.1v-77.2c0-2.3-.1-4.7-.6-7-2.6-12.1-9.4-21-21.4-24.7-60.9-18.6-121.8-37-182.7-55.5C85.3 16 69.7 11.2 54 6.6 41.6 3 30.9 6.5 21.9 15.5c-6 6-8.4 13.5-8.3 21.8v107.5c.2 3.8 2.9 6.7 6.8 7.1 3.6.4 6.9-1.9 7.8-5.4.3-1.1.3-2.2.3-3.3V38.1c0-1.7.1-3.4.4-5 .8-4.1 2.9-7.8 6.2-10.3 3.4-2.7 7.8-3.7 12.1-2.7 4.7 1 9.3 2.6 14 4.1 44.5 13.4 89 26.9 133.5 40.3 28.3 8.6 56.6 17.1 84.9 25.7 3.2 1 5.8 2.8 7.8 5.5 2.5 3.4 3.5 7.2 3.5 11.3v156c-.3 4.5-1.5 8.6-5.1 11.5-5.2 4.4-10.8 6-17.6 3.6-7.7-2.7-15.6-4.7-23.4-7.4-5.2-1.8-10.3 2.8-9.7 8.1.3 2.8 1.9 4.9 4.5 5.7 9.6 3.1 19.2 6 28.8 8.9 2.8.8 5.7.9 8.5.5 5.4-.7 10.3-2.7 15.4-4.4.4-.1.7-.4 1-.7 8.2-6.8 12.4-15.4 12.4-26.1-.1-25.8-.1-51.7-.1-77.6zm-131.9 32.3v86.3c.7 7.9 4.6 13.7 11.8 17.1.7.3 1.5.5 2.3.5 3 .1 6.1.1 9.2 0 1.1 0 2.2-.2 3.2-.7 7.1-3.4 11.1-9.1 11.8-17 0-.3 0-.8.2-.9 1.4-.3.8-1.4.8-2.1V133.5v-1.7c-.4-13.8-14.5-23-27.1-17.8-7.5 3.1-12.2 10.3-12.2 18.7v84.7zm-56.2.7v53.1c0 4.1-.1 8.2 0 12.3.4 12.4 11.3 20.6 23.3 17.7 8.3-2 14.3-9.2 14.9-17.7 0-.5.2-.9.4-1.3s.6-.8.6-1.2c0-42.6.1-85.2 0-127.7 0-12.5-11.8-21.5-24-18.6-9 2.1-15.2 10-15.2 19.3v64.1zm-57.1-.6v17.4c0 8.4-.1 16.8 0 25.2.3 13.1 12.2 21.9 24.8 18.4 8.6-2.3 14.4-10.2 14.4-19.5v-71.4c0-4.2.1-8.4 0-12.6-.3-13.1-11.8-21.9-24.5-18.8-8.7 2.1-14.8 10.1-14.8 19.3.1 14.1.1 28.1.1 42zm-56.2.5v28c0 .9.2 1.8.5 2.6 3.5 8.7 12.9 13.6 22.2 11.5 9.3-2 15.6-9.9 15.6-19.6v-45.1c0-1 0-2-.1-2.9-1.2-8.9-8.1-15.7-17.3-17-8.3-1.2-16.9 3.9-20.3 12-.4.9-.6 1.9-.6 2.8V218z"
				fill="#fff"
			/>
			<path d="M305.6 185.1v77.7c0 10.7-4.3 19.3-12.4 26.1-.3.3-.6.5-1 .7-5 1.7-10 3.7-15.4 4.4-2.9.4-5.8.3-8.5-.5-9.6-2.9-19.3-5.8-28.8-8.9-2.6-.8-4.2-3-4.5-5.7-.5-5.3 4.5-9.9 9.7-8.1 7.7 2.7 15.7 4.6 23.4 7.4 6.8 2.4 12.4.7 17.6-3.6 3.5-3 4.8-7.1 5.1-11.5v-1.3-154.7c0-4.1-1-8-3.5-11.3-2-2.7-4.6-4.5-7.8-5.5-28.3-8.6-56.6-17.1-84.9-25.7-44.5-13.4-89-26.9-133.5-40.3-4.6-1.4-9.3-3-14-4.1-4.3-1-8.6.1-12.1 2.7-3.2 2.5-5.3 6.2-6.2 10.3-.3 1.6-.4 3.3-.4 5v105.1c0 1.1 0 2.2-.3 3.3-.9 3.5-4.3 5.7-7.8 5.4-3.9-.4-6.6-3.2-6.8-7.1v-1.2V37.4c0-8.3 2.4-15.8 8.3-21.8 9-9 19.7-12.5 32.1-8.9 15.7 4.6 31.3 9.4 46.9 14.2 60.9 18.5 121.9 36.9 182.7 55.5 12 3.7 18.8 12.6 21.4 24.7.5 2.3.6 4.7.6 7 .2 25.5.1 51.3.1 77z" />
			<path d="M173.7 217.4v-84.6c0-8.5 4.7-15.6 12.2-18.7 12.6-5.2 26.7 4 27.1 17.8V300.7c0 .8.5 1.8-.8 2.1-.1 0-.1.6-.2.9-.7 7.9-4.6 13.6-11.8 17-1 .5-2.1.6-3.2.7-3 .1-6.1.1-9.2 0-.8 0-1.6-.2-2.3-.5-7.2-3.4-11.1-9.2-11.8-17.1-.1-.8 0-1.7 0-2.5v-83.9zM117.5 218.1v-64.2c0-9.3 6.3-17.1 15.2-19.3 12.2-2.9 24 6 24 18.6.1 42.6 0 85.1 0 127.7 0 .4-.4.8-.6 1.2-.2.4-.3.9-.4 1.3-.6 8.5-6.7 15.7-14.9 17.7-12 2.9-22.9-5.3-23.3-17.7-.1-4.1 0-8.2 0-12.3v-53zM60.4 217.5v-41.9c0-9.3 6.1-17.2 14.8-19.3 12.7-3.1 24.2 5.7 24.5 18.8.1 4.2 0 8.4 0 12.6v71.4c0 9.3-5.9 17.2-14.4 19.5-12.6 3.5-24.5-5.3-24.8-18.4-.2-8.4 0-16.8 0-25.2-.1-5.9-.1-11.7-.1-17.5zM4.2 218v-27.7c0-.9.2-1.9.6-2.8 3.4-8.1 12-13.2 20.3-12 9.1 1.3 16.1 8.1 17.3 17 .1 1 .1 1.9.1 2.9v45.1c0 9.7-6.3 17.6-15.6 19.6s-18.7-2.8-22.2-11.5c-.3-.8-.5-1.7-.5-2.6v-28z" />
		</svg>
	);
};

export default IconPowerBi;
