import { Error403 as Error403Component } from "@hexa-ui/components";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { routes } from "router/constants";
import { ErrorPageStyled } from "../ErrorComponentStyled";

const Error403 = (): JSX.Element => {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	return (
		<ErrorPageStyled>
			<Error403Component
				header={formatMessage({ id: "ERROR_403.HEADER" })}
				description={formatMessage({ id: "ERROR_403.DESCRIPTION" })}
				buttonText={formatMessage({ id: "ERROR_403.BUTTON_TEXT" })}
				onButtonClick={() => navigate(routes.HOME_PAGE)}
			/>
		</ErrorPageStyled>
	);
};

export default Error403;
