import { yupResolver } from "@hookform/resolvers/yup";
import { FormButtons } from "components/FormButtons";
import { useSubmitJiraForm } from "hooks/useSubmitJiraForm";
import { IRequestTypeInfo } from "pages/AccessManagementPages/RequestAccessManagementPage/RequestAccessManagementPage.types";
import React, { memo, useCallback } from "react";
import { FormProvider, useForm } from "react-hook-form";
import { formatProFormaAnswers } from "utils/formatProformaAnswers";
import { handleArrayValues } from "utils/jira";
import { ObjectSchema } from "yup";
import { CustomInput } from "./components/CustomInput";
import { CustomSelect } from "./components/CustomSelect";
import { CustomTextArea } from "./components/CustomTextArea";

interface IJiraFormProps {
	requestTypeId: number;
	serviceDeskId: string;
	requestTypeInfo: IRequestTypeInfo[];
	validationSchema: ObjectSchema<any, any, any, any>;
	longTextFields?: string[];
	hasError?: boolean;
	proformaFields?: JSX.Element;
	proformaFieldsLabels?: object;
	proformaFieldID?: string;
}

export const JiraForm = memo(
	({
		requestTypeId,
		serviceDeskId,
		requestTypeInfo,
		validationSchema,
		longTextFields,
		proformaFields,
		proformaFieldsLabels,
		proformaFieldID,
		hasError
	}: IJiraFormProps) => {
		const methods = useForm({
			resolver: yupResolver(validationSchema),
			mode: "onBlur",
			reValidateMode: "onChange",
			shouldFocusError: true
		});
		const {
			formState: { isValid },
			getValues
		} = methods;
		const formValues = getValues();

		const handleRequestAnswersValues = useCallback(
			(item: IRequestTypeInfo) => {
				const answerValues = {
					string: () => formValues[item.fieldId],
					option: () => ({
						value: formValues[item.fieldId]
					}),
					priority: () => ({ name: formValues[item.fieldId] ?? "Low" }),
					array: () => handleArrayValues(formValues[item.fieldId])
				};
				return answerValues[item?.jiraSchema?.type]();
			},
			[formValues]
		);

		const getRequestFieldValues = useCallback(() => {
			const newRequestAnswers = {};

			requestTypeInfo?.forEach(
				(item: IRequestTypeInfo) =>
					(newRequestAnswers[item.fieldId] = handleRequestAnswersValues(item))
			);

			return newRequestAnswers;
		}, [formValues]);

		const getProformaFieldValues = useCallback(() => {
			if (proformaFields) {
				const proformaAnswers =
					formValues.proforma && formatProFormaAnswers(formValues.proforma, proformaFieldsLabels);
				const proformaAnswerObject = { [proformaFieldID]: proformaAnswers };

				return proformaAnswerObject;
			} else return null;
		}, [formValues.proforma]);

		const { isSendButtonLoading, handleSubmit } = useSubmitJiraForm({
			serviceDeskId,
			requestTypeId,
			requestFieldValues: { ...getRequestFieldValues(), ...getProformaFieldValues() }
		});

		const handleFormFields = useCallback(
			(item: IRequestTypeInfo) => {
				if (!item.visible) {
					return <></>;
				}
				const formTypes = {
					string: <CustomInput item={item} key={item.fieldId} />,
					option: <CustomSelect item={item} requestTypeInfo={requestTypeInfo} key={item.fieldId} />,
					priority: (
						<CustomSelect item={item} requestTypeInfo={requestTypeInfo} key={item.fieldId} />
					),
					array: (
						<CustomSelect
							item={item}
							requestTypeInfo={requestTypeInfo}
							multiple
							key={item.fieldId}
						/>
					)
				};
				return formTypes[item?.jiraSchema?.type] ?? <></>;
			},
			[requestTypeInfo]
		);

		return (
			<FormProvider {...methods}>
				{!hasError &&
					requestTypeInfo?.map((item) => {
						if (longTextFields?.includes(item?.fieldId)) {
							return <CustomTextArea item={item} key={item.fieldId} />;
						} else {
							return <React.Fragment key={item.fieldId}>{handleFormFields(item)}</React.Fragment>;
						}
					})}
				{(!hasError && proformaFields) ?? null}

				<FormButtons
					handleSubmit={handleSubmit}
					isValid={isValid}
					isLoading={isSendButtonLoading}
				/>
			</FormProvider>
		);
	}
);
