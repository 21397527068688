import { Select } from "@hexa-ui/components";
import { EDataSourceOptions, EFrequencyOptions } from "types/SelectOptions.types";

export const DataSourceOptions = (): JSX.Element => {
	return (
		<>
			{Object.values(EDataSourceOptions).map((value) => (
				<Select.Option value={value} key={value}>
					{value}
				</Select.Option>
			))}
		</>
	);
};

export const FrequencyOptions = (): JSX.Element => {
	return (
		<>
			{Object.values(EFrequencyOptions).map((value) => (
				<Select.Option value={value} key={value}>
					{value}
				</Select.Option>
			))}
		</>
	);
};
