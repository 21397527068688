import { LoadingBuzz } from "@hexa-ui/components";
import RouterLoaderStyled from "./RouterLoaderStyled";

const RouterLoader = (): JSX.Element => {
	return (
		<RouterLoaderStyled>
			<LoadingBuzz size="xlarge" dataTestId="loading-buzz" />
		</RouterLoaderStyled>
	);
};

export default RouterLoader;
