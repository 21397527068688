import { Select } from "@hexa-ui/components";
import { IRequestTypeInfo } from "pages/AccessManagementPages/RequestAccessManagementPage/RequestAccessManagementPage.types";
import { getJiraSelectOptions } from "pages/InfraServicesPages/JiraFunctions";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";

interface ICustomSelectProps {
	item: IRequestTypeInfo;
	requestTypeInfo: IRequestTypeInfo[];
	multiple?: boolean;
}

export const CustomSelect = ({
	item,
	requestTypeInfo,
	multiple
}: ICustomSelectProps): JSX.Element => {
	const { control, watch } = useFormContext();
	const formValues = watch();

	return (
		<ContainerStyled marginTop marginBottom key={item?.fieldId}>
			<Controller
				control={control}
				name={item?.fieldId}
				render={({ field: { onChange } }) => {
					return (
						<Select.Root
							id={item?.fieldId}
							label={item?.name}
							labelProps={{ htmlFor: item?.fieldId }}
							placeholder="Choose an option"
							value={formValues?.[item?.fieldId]}
							onChange={onChange}
							multiple={multiple}
						>
							{getJiraSelectOptions(item?.fieldId, requestTypeInfo)}
						</Select.Root>
					);
				}}
			/>
		</ContainerStyled>
	);
};
