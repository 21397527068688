import { Grid } from "@hexa-ui/components";
import { setAppHeaderConfig } from "admin-portal-shared-services";
import LoaderDots from "components/shared/LoaderDots/LoaderDots";
import { GenericError } from "pages/ErrorPages/GenericError/GenericError";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { routes } from "router/constants";
import { useGetDatasetById } from "services/Dataset/datasets";
import { IDataset } from "types/Dataset.types";
import { DatasetCard } from "../components/DatasetCard/DatasetCard";
import { GeneralInformationCard } from "../components/GeneralInformationCard/GeneralInformationCard";

const { Container } = Grid;

const DatasetDetailsPage = () => {
	const { datasetId } = useParams();
	const {
		data: datasetData,
		isError,
		isLoading,
		refetch
	} = useGetDatasetById(datasetId, { enabled: true });

	useEffect(() => {
		setAppHeaderConfig({
			pageTitle: "Dataset Details",
			breadcrumbConfig: {
				homePath: routes.HOME_PAGE,
				items: [
					{
						label: "Self Service BI",
						path: routes.SELF_SERVICE_BI.SELF_SERVICE_BI,
						isCurrentPage: false
					},
					{
						label: `${datasetData?.reportTitle}`,
						path: routes.SELF_SERVICE_BI.DATASET_DETAILS,
						isCurrentPage: true
					}
				]
			}
		});
	}, [setAppHeaderConfig, datasetData]);

	return (
		<LoaderDots isLoading={isLoading}>
			<GenericError hasError={isError} onClick={refetch}>
				<Container type="fluid" sidebar>
					<GeneralInformationCard dataset={datasetData as IDataset} />
					<DatasetCard
						id={datasetData?.embeddedReport?.id}
						embedUrl={datasetData?.embeddedReport?.embedUrl}
						accessToken={datasetData?.embeddedReportToken?.token}
					/>
				</Container>
			</GenericError>
		</LoaderDots>
	);
};

export default DatasetDetailsPage;
