import { ROUTE_BASE_URL } from "../constants";
import { IRoutesParams } from "./RouteParams.types";

export const GET_SCOPES = "GET_SCOPES";

export const GetScopesRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_SCOPES: `/scopes`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};
