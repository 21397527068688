import { EReportType, EScopeLevel, IScope } from "types/Report.types";

export const extractReportID = (powerBIURL: string) => {
	const splitURL = powerBIURL?.split("?reportId=")[1];
	const filteredReportId = splitURL?.split("&")[0];
	return filteredReportId;
};

export const identifyReportType = (reportURL: string) => {
	if (reportURL?.includes("powerbi")) return EReportType.POWER_BI;
	else if (reportURL?.includes("app.mode")) return EReportType.MODE;
	else return EReportType.OTHER;
};

export const validateScopeNames = (scopeLevel: string) => {
	if (EScopeLevel.hasOwnProperty(scopeLevel)) {
		return EScopeLevel[scopeLevel];
	}
	return false;
};

export const returnScopeIds = (scopes: IScope[]) => {
	return scopes.map((scope: IScope) => scope.scopeId);
};
