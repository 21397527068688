import { EmptyState } from "@hexa-ui/components";
import { useNavigate } from "react-router-dom";
import { routes } from "router/constants";
import { EmptyStateStyled } from "styles/shared/EmptyStateStyled/EmptyStateStyled";

const { PageLevel } = EmptyState;

export const ReportErrorState = ({ handleRefresh }) => {
	const navigate = useNavigate();

	return (
		<EmptyStateStyled>
			<PageLevel
				title="Oops!"
				description="Something went wrong loading dashboard. Please try again."
				data-testid="page-level-error"
				actions={[
					{
						action: () => navigate(routes.HOME_PAGE),
						name: "Go Back",
						variant: "secondary"
					},
					{
						action: handleRefresh,
						name: "Refresh",
						variant: "primary"
					}
				]}
				ilustrationName="beesPageCouldntLoad"
			/>
		</EmptyStateStyled>
	);
};
