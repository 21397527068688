import {
	Button,
	Checkbox,
	Dialog,
	Divider,
	Grid,
	Heading,
	Input,
	LoadingDots,
	Paragraph,
	Radio,
	Select,
	TextArea,
	TextLink,
	Toast
} from "@hexa-ui/components";
import { yupResolver } from "@hookform/resolvers/yup";
import { TypeToast } from "admin-portal-shared-services";
import { useReportsContext } from "contexts";
import { useResizeObserverErrorHandler } from "hooks/useResizeObserverErrorHandler";
import domainsMock from "mocks/Domain/domainsMock.json";
import { useCallback, useEffect, useLayoutEffect, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { createReport, editReport, getDomains } from "services/Report/ReportService";
import { getScopes } from "services/Scopes/ScopesService";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { ParagraphStyled } from "styles/shared/ParagraphStyled/ParagraphStyled";
import { IBeesDomainsResponse, IDomain } from "types/Domain.types";
import { INewReport, IReportInputs, IScope } from "types/Report.types";
import { EScopes } from "types/Scopes.types";
import { EReportStatus } from "types/SelectOptions.types";
import { showNotify } from "utils/notify";
import { reportModalSchema } from "./ReportModal.schema";
import {
	DialogActionButtonStyled,
	GoodPracticesContainerStyled,
	PageDescriptionStyled,
	RadioRootStyled,
	ScopesCheckboxesStyled
} from "./ReportModalStyled";
import {
	CreateScopesMessage,
	CreateWorkspaceIdMessage,
	checkMessageError
} from "./components/DynamicMessages";
import { ReportGoodPractices } from "./components/ReportGoodPractices";
import { DataSourceOptions, FrequencyOptions } from "./components/ReportModalOptionValues";
import {
	extractReportID,
	identifyReportType,
	returnScopeIds,
	validateScopeNames
} from "./components/reportModalSupportFunctions";
import { scopeList } from "./data/scopeListData";

const { Container, Item } = Grid;
export const RLS_GUIDE =
	"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3496083784/BEES+Data+Portal+Good+Practices+To+Share+Reports#Row-Level-Security-(RLS)";

export interface IReportModalProps {
	report?: INewReport;
	isOpen: boolean;
	onClose: () => void;
}

const DOMAIN_REQUEST = {
	size: 200,
	offset: 0
};

const SCOPE_REQUEST = {
	size: 200,
	offset: 0
};

type TReportInputKeys = keyof IReportInputs;

const ReportModal = ({ report, isOpen, onClose }: IReportModalProps): JSX.Element => {
	useResizeObserverErrorHandler();
	const { setUpdateReportsDate } = useReportsContext();
	const { formatMessage } = useIntl();
	const [isReportModalEdition, setIsReportModalEdition] = useState<boolean>(false);
	const [domains, setDomains] = useState([] as IDomain[]);
	const [scopes, setScopes] = useState<IScope[]>([] as IScope[]);
	const [openAlertCloseModal, setOpenAlertCloseModal] = useState<boolean>(false);
	const [isSendButtonLoading, setIsSendButtonLoading] = useState<boolean>(false);
	const [isMounted, setIsMounted] = useState(true);
	const methods = useForm<INewReport>({
		mode: "onBlur",
		reValidateMode: "onChange",
		shouldFocusError: true,
		resolver: yupResolver(reportModalSchema),
		defaultValues: {
			hasRls: false
		}
	});
	const {
		control,
		watch,
		register,
		setValue,
		setError,
		reset,
		formState: { isDirty, errors, isValid }
	} = methods;
	const formValues = watch();

	useLayoutEffect(() => {
		if (!report) return;
		setIsReportModalEdition(true);
		getReportFormData();
	}, []);

	const getReportFormData = () => reset(report);
	const getAllDomains = useCallback(async () => {
		try {
			const { data }: IBeesDomainsResponse = await getDomains(
				DOMAIN_REQUEST.size,
				DOMAIN_REQUEST.offset
			);
			const enabledDomains = data?.filter((domain) => domain.isEnabled === true);
			setDomains(enabledDomains);
		} catch (error) {
			setDomains(domainsMock);
		}
	}, []);

	const getAllScopes = useCallback(async () => {
		try {
			const { data } = await getScopes(SCOPE_REQUEST.size, SCOPE_REQUEST.offset);
			setScopes(data);
		} catch {
			setScopes(scopeList);
		}
	}, []);

	useEffect(() => {
		if (isMounted) {
			Promise.all([getAllDomains(), getAllScopes()]);
		}

		return () => {
			setIsMounted(false);
		};
	}, [getAllDomains, getAllScopes]);

	const setReportData = () => {
		const reportId = extractReportID(formValues.reportLink);
		const reportType = identifyReportType(formValues.reportLink);
		return {
			reportId: reportId,
			scopesId: returnScopeIds(formValues.scopes),
			hasRls: formValues.hasRls,
			domainId: Number(formValues.domainId),
			ownerName: formValues.ownerName,
			ownerEmail: formValues.ownerEmail,
			reportTitle: formValues.reportTitle,
			description: formValues.description,
			status: EReportStatus.Done,
			reportLink: formValues.reportLink,
			documentationLink: formValues.documentationLink,
			dataSource: formValues.dataSource,
			updateFrequency: formValues.updateFrequency,
			reportType: reportType,
			userAcceptedTerms: formValues.userAcceptedTerms,
			userGuide: formValues.userGuide,
			isEnabled: true,
			uploadDate: isReportModalEdition ? new Date(report.uploadDate).getTime() : Date.now()
		};
	};

	const editOpenedReport = async () => {
		const editedReportData = setReportData();
		try {
			await editReport(report.reportId, editedReportData);
			setUpdateReportsDate(new Date());
			showNotify(formatMessage({ id: "REPORT.TOAST_EDITED_SUCCESS" }), TypeToast.SUCCESS);
			onClose();
		} catch (error) {
			checkMessageError(error.response.data.message, setError, formatMessage);
		} finally {
			setIsSendButtonLoading(false);
		}
	};

	const createNewReport = async () => {
		const newReportData = setReportData();
		try {
			await createReport(newReportData);
			setUpdateReportsDate(new Date());
			showNotify(formatMessage({ id: "REPORT.TOAST_ADDED_SUCCESS" }), TypeToast.SUCCESS);
			onClose();
		} catch (error) {
			checkMessageError(error.response.data.message, setError, formatMessage);
		} finally {
			setIsSendButtonLoading(false);
		}
	};

	const handleCloseModal = (event) => {
		event.preventDefault();
		isDirty ? setOpenAlertCloseModal(true) : onClose();
	};

	const handleSubmit = (event) => {
		event.preventDefault();
		setIsSendButtonLoading(true);
		isReportModalEdition ? editOpenedReport() : createNewReport();
	};

	const setupInput = (name: TReportInputKeys) => {
		return {
			hasError: !!errors?.[name],
			errorText: errors?.[name]?.message as string,
			value: formValues[name],
			...register(name)
		};
	};

	const setupScopesCheckbox = (scope: IScope) => {
		if (formValues.scopes) {
			return {
				checked: formValues.scopes?.some((s) => s.scopeId === scope.scopeId),
				...register("scopes")
			};
		}
	};

	const handleSelectedScopes = (clickedScopeId: string, scope: IScope) => {
		if (formValues.scopes?.length > 0) {
			const containScope = formValues?.scopes?.filter((s) => s.scopeId?.includes(clickedScopeId));
			if (containScope.length === 0) {
				setValue("scopes", [...formValues.scopes, scope]);
			} else {
				const filteredScopes = formValues?.scopes?.filter((s) => s.scopeId !== clickedScopeId);
				setValue("scopes", filteredScopes);
			}
		} else {
			setValue("scopes", [scope]);
		}
	};

	const handleRlsDefaultValue = () => {
		return formValues.hasRls ? "true" : "false";
	};

	return (
		<Dialog.Root
			data-testid="add-report-modal"
			open={isOpen}
			onClose={handleCloseModal}
			title={<Heading size="H2">{isReportModalEdition ? "Edit Report" : "Add Report"}</Heading>}
			variant="bottomTray"
			onEscapeKeyDown={(e) => handleCloseModal(e)}
		>
			<Divider />
			<PageDescriptionStyled>
				Please follow the access requirements below embedding your report.
			</PageDescriptionStyled>
			<ContainerStyled>
				<ContainerStyled marginBottom>
					<Heading size="H2">Report Information</Heading>
				</ContainerStyled>
				<ContainerStyled marginBottom>
					<Heading size="H4">Resource group</Heading>
				</ContainerStyled>
				<FormProvider {...methods}>
					<form onSubmit={handleSubmit}>
						<ContainerStyled marginBottom marginTop>
							<Controller
								control={control}
								name="domainId"
								defaultValue={report?.domain?.domainId}
								render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
									<Select.Root
										id="domain-select"
										data-testid="domain-select"
										label="Domain"
										error={error?.message}
										labelProps={{ htmlFor: "domain-select" }}
										value={String(value)}
										onChange={onChange}
										onBlur={onBlur}
									>
										{domains?.map((domain) => (
											<Select.Option value={String(domain.domainId)} key={domain.domainId}>
												{domain.domainName}
											</Select.Option>
										))}
									</Select.Root>
								)}
							/>
							{CreateWorkspaceIdMessage(domains, formValues)}
						</ContainerStyled>
						<ContainerStyled marginTop>
							<Paragraph size="small" weight="semibold">
								Scopes
							</Paragraph>
							<ScopesCheckboxesStyled>
								{scopes?.map((scope) => (
									<Checkbox
										key={scope.scopeId}
										id={scope.scopeId}
										data-testid="scope-checkbox"
										name="scopes"
										label={validateScopeNames(scope.scopeLevel)}
										onCheckedChange={() => handleSelectedScopes(scope.scopeId, scope)}
										{...setupScopesCheckbox(scope)}
									/>
								))}
							</ScopesCheckboxesStyled>
							{formValues.scopes
								?.map((s: IScope) => s.scopeLevel)
								.includes(EScopes.AbiNonGlobal) && (
								<>
									<ParagraphStyled size="small" weight="semibold">
										Does your dashboard have{" "}
										<TextLink target="_blank" href={RLS_GUIDE}>
											RLS (Row-level security)
										</TextLink>
										?
									</ParagraphStyled>
									<RadioRootStyled
										onValueChange={(value) => setValue("hasRls", JSON.parse(value))}
										defaultValue={handleRlsDefaultValue()}
										error={!!errors?.hasRls}
										errorMessage={errors?.hasRls?.message}
									>
										<Radio.Item label="Yes" value="true" id="rls" />
										<Radio.Item label="No" value="false" id="no-rls" />
									</RadioRootStyled>
								</>
							)}
							{formValues?.hasRls
								? CreateScopesMessage(formValues.scopes, formValues.hasRls)
								: CreateScopesMessage(formValues.scopes)}
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Input
								id="input-with-report-title"
								data-testid="report-title-input"
								label="Report Title"
								placeholder=""
								width="100%"
								required
								{...setupInput("reportTitle")}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<TextArea
								id="text-area-with-description"
								data-testid="description-textarea"
								label="Description"
								placeholder=""
								width="100%"
								required
								{...setupInput("description")}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Input
								id="input-with-report-owner-name"
								data-testid="owner-name-input"
								label="Report Owner Name"
								placeholder="Owner Name"
								width="100%"
								required
								{...setupInput("ownerName")}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Input
								id="input-with-report-owner-email"
								data-testid="owner-email-input"
								label="Report Owner Email"
								type="email"
								placeholder="example@ab-inbev.com"
								width="100%"
								required
								{...setupInput("ownerEmail")}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Input
								id="input-with-report-link"
								data-testid="report-link-input"
								label="Report Link"
								placeholder="https://app.powerbi.com/"
								width="100%"
								required
								disabled={isReportModalEdition ? true : false}
								{...setupInput("reportLink")}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom>
							<Paragraph colortype="secondary" size="small">
								PowerBI Dashboard &gt; File &gt; Embed Report &gt; WebSite or Portal &gt; First Link
							</Paragraph>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Input
								id="input-with-documentation-link"
								data-testid="documentation-link-input"
								label="Documentation Link"
								placeholder="https://"
								width="100%"
								{...setupInput("documentationLink")}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Controller
								control={control}
								name="dataSource"
								data-testid="data-source-select"
								defaultValue={report?.dataSource}
								render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
									<Select.Root
										id="data-source-select"
										data-testid="data-source-select"
										label="Data Source"
										error={!value && error?.message}
										labelProps={{ htmlFor: "data-source-select" }}
										value={value}
										onChange={onChange}
										onBlur={onBlur}
									>
										<DataSourceOptions />
									</Select.Root>
								)}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Controller
								control={control}
								name="updateFrequency"
								defaultValue={String(report?.updateFrequency)}
								render={({ field: { onChange, onBlur, value }, fieldState: { error } }) => (
									<Select.Root
										id="update-frequency-select"
										data-testid="update-frequency-select"
										label="Update Frequency"
										error={error?.message}
										labelProps={{ htmlFor: "update-frequency-select" }}
										value={value}
										onChange={onChange}
										onBlur={onBlur}
									>
										<FrequencyOptions />
									</Select.Root>
								)}
							/>
						</ContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Input
								id="input-with-user-guide"
								data-testid="user-guide-input"
								label="User Guide"
								optionalText="(Optional)"
								required={false}
								placeholder="https://"
								width="100%"
								{...setupInput("userGuide")}
							/>
						</ContainerStyled>
						<GoodPracticesContainerStyled>
							<ReportGoodPractices />
						</GoodPracticesContainerStyled>
						<ContainerStyled marginBottom marginTop>
							<Checkbox
								id="agreement"
								data-testid="agreement-checkbox"
								label="I agree with the stablished sharing conditions for the report and the good practices."
								checked={formValues.userAcceptedTerms}
								onCheckedChange={() => setValue("userAcceptedTerms", !formValues.userAcceptedTerms)}
							/>
						</ContainerStyled>
						<DialogActionButtonStyled>
							<Toast.Provider duration={8000} swipeDirection="right">
								<Button size="medium" variant="secondary" onClick={handleCloseModal}>
									Cancel
								</Button>
								<Toast.Root
									type="warning"
									open={openAlertCloseModal}
									onOpenChange={setOpenAlertCloseModal}
									position="top-right"
									message={formatMessage({ id: "REPORT.TOAST_DISCARD_CHANGES" })}
									showCloseButton
									actions={[
										{
											action: onClose,
											name: "Close anyway"
										}
									]}
								/>
							</Toast.Provider>
							<Button
								data-testid="submit-button"
								type="submit"
								size="medium"
								disabled={!isValid || !formValues.userAcceptedTerms || isSendButtonLoading}
							>
								{isSendButtonLoading ? (
									<LoadingDots size="large" />
								) : isReportModalEdition ? (
									"Save"
								) : (
									"Submit Report"
								)}
							</Button>
						</DialogActionButtonStyled>
					</form>
				</FormProvider>
			</ContainerStyled>
		</Dialog.Root>
	);
};

export default ReportModal;
