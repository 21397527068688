import { useEnvService } from "admin-portal-shared-services";
import { ROUTE_BASE_URL } from "api/constants";
import MockAdapter from "axios-mock-adapter";
import domainsMock from "mocks/Domain/domainsMock.json";
import jiraSelectOptionsMock from "mocks/Jira/jiraSelectOptionsMock.json";
import openTicketsMock from "mocks/Jira/openTicketsMock.json";
import allReportsMock from "mocks/Report/allReportsMock.json";
import reportAddedMock from "mocks/Report/reportAddedMock.json";
import reportMock from "mocks/Report/reportMock.json";
import reportsMock from "mocks/Report/reportsMock.json";
import searchResultMock from "mocks/Search/searchResultMock.json";
import { DATA_TEAM_SERVICE_DESK_ID } from "utils/constants";

const { getEnvConfig } = useEnvService();
const route = (path: string = "") => {
	return typeof path === "string" ? new RegExp(path.replace(/:\w+/g, "[^/]+")) : path;
};

export const useMock = (axios) => {
	const mock = new MockAdapter(axios, { onNoMatch: "throwException" });
	mock
		.onGet(route(`${ROUTE_BASE_URL}/reports/domain/:domainId`)) // getReportsByDomain
		.reply(200, reportsMock);
	mock.onGet(route(`${ROUTE_BASE_URL}/reports/:domainId`)).reply(200, reportMock); //getReportById
	mock.onGet(route(`${ROUTE_BASE_URL}/reports`)).reply(200, allReportsMock); //getAllReports
	mock.onGet(route(`${ROUTE_BASE_URL}/domains`)).reply(200, domainsMock); //getDomains
	mock.onPost(route(`${ROUTE_BASE_URL}/reports`)).reply(201, reportAddedMock); // createReport
	mock.onPut(route(`${ROUTE_BASE_URL}/reports/:reportId`)).reply(204); // editReport
	mock.onDelete(route(`${ROUTE_BASE_URL}/reports/:reportId`)).reply(200); // deleteReport
	mock.onPost(route(`${ROUTE_BASE_URL}/reports/search`)).reply(200, searchResultMock); // searchReports
	mock
		.onGet(route(`${ROUTE_BASE_URL}/jira/fields/${DATA_TEAM_SERVICE_DESK_ID}/:requestType`))
		.reply(200, jiraSelectOptionsMock); // getFields
	mock
		.onGet(route(`${ROUTE_BASE_URL}/jira/open`), {
			params: {
				serviceDeskId: DATA_TEAM_SERVICE_DESK_ID,
				requestOwnership: "ALL_REQUESTS",
				expand: "comment",
				limit: 4
			}
		})
		.reply(200, openTicketsMock); //getOpenTickets
};

export const setUpMockAdapter = (client) => {
	const envConfig = getEnvConfig();
	if (envConfig["ENV"] === "sit") return useMock(client);
};
