import { styled } from "@hexa-ui/theme";

export const ButtonsWrapperStyled = styled("div", {
	display: "flex",
	width: "100%",
	variants: {
		position: {
			start: {
				justifyContent: "flex-start"
			},
			end: {
				justifyContent: "flex-end"
			},
			center: {
				justifyContent: "center"
			}
		},
		margin: {
			top: { marginTop: "20px" },
			top2: { marginTop: "$2" },
			bottom: { marginBottom: "$4" },
			bottomTop: { margin: "20px 0" }
		},
		gap: {
			true: {
				gap: "$4"
			}
		}
	}
});
