import { styled } from "@hexa-ui/theme";

export const ContainerStyled = styled("div", {
	display: "flex",
	flexDirection: "column",
	justifyContent: "center",
	alignItems: "center"
});

export const GenericErrorStyled = styled("div", {
	display: "flex",
	flexDirection: "column",
	alignItems: "center",
	rowGap: "$2",
	alignContent: "center",
	button: {
		marginTop: "$4"
	}
});
