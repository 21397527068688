import { ERequestTenantAccessFieldsId } from "types/Jira.types";
import * as yup from "yup";

const { summary, description, priority, valueStream } = ERequestTenantAccessFieldsId;

export const tenantAccessFormSchema = yup.object().shape({
	[summary]: yup
		.string()
		.required("Summary is required.")
		.min(8, "Summary must be at least 8 characters.")
		.max(42, "Summary must be at most 42 characters."),
	[description]: yup
		.string()
		.required("Description is required.")
		.min(10, "Description must be at least 10 characters.")
		.max(280, "Description must be at most 280 characters."),
	[priority]: yup.string(),
	[valueStream]: yup.string().required("Value stream is required.")
});
