import { useAuthenticationService } from "admin-portal-shared-services";
import segmentAnalytics from "analytics";

interface IReportClickedProps {
	domainName: string;
	reportTitle: string;
	reportId: string;
}

const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

export const globalReportsPageViewedTracking = () => {
	segmentAnalytics.beesGlobalReportsPageViewed({
		country: userCountry,
		date: new Date().toISOString(),
		screen_name: "GlobalReports",
		page_number: null,
		dashboards_numbers: null
	});
};

export const tabGlobalReportSelectedTracking = (tabName: string, dashboardNumbers: number) => {
	segmentAnalytics.tabGlobalReportSelected({
		tab_name: tabName,
		country: userCountry,
		date: new Date().toISOString(),
		dashboards_numbers: dashboardNumbers
	});
};

export const reportClickedTracking = ({
	domainName,
	reportTitle,
	reportId
}: IReportClickedProps) => {
	segmentAnalytics.reportClicked({
		domain: domainName,
		country: userCountry,
		dashboard_name: reportTitle,
		report_id: reportId,
		date: new Date().toISOString(),
		screen_name: "Global Reports"
	});
};
