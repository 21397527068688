export interface IJiraGetTicketsResponse {
	data: {
		requestTitle: string;
		resourceType: string;
		jiraType: string;
		reference: string;
		summary: string;
		status: string;
		serviceRequest: string;
		requester: string;
		createdDate: string;
		updatedDate: string;
	};
}

export enum ERequestTypeId {
	InfraNewDomain = 6882,
	NewAzureResource = 1512,
	NewAzureEditResource = 9090,
	AccessSegment = 239,
	AccessSnowflake = 3597,
	AccessSnowflakeDTC = 8704,
	AccessRequest = 316,
	TenantAccess = 6987,
	UnityCatalog = 9067
}

export enum ERequestAccessFieldsId {
	microFront = "customfield_13724",
	summary = "summary",
	description = "description",
	email = "customfield_13817",
	area = "customfield_13834",
	country = "customfield_13835",
	affectedCountry = "customfield_13365",
	businessModel = "customfield_13767",
	typeOfAccess = "customfield_13836"
}

export enum ESegmentAccessFieldsId {
	summary = "summary",
	userName = "customfield_13617",
	email = "customfield_13000",
	role = "customfield_13633",
	otherRole = "customfield_13618",
	product = "customfield_13578",
	environment = "customfield_13619",
	teamName = "customfield_13230",
	requestedTool = "customfield_13614",
	description = "description",
	country = "customfield_13635",
	accessType = "customfield_13574"
}

export enum ERequestTenantAccessFieldsId {
	summary = "summary",
	description = "description",
	priority = "priority",
	valueStream = "customfield_13634",
	teamName = "customfield_13230"
}

export enum ERequestSnowflakeFieldsId {
	summary = "summary",
	description = "description",
	priority = "priority",
	teamName = "customfield_13230",
	role = "customfield_13633",
	requestApprovedBy = "customfield_13000"
}

export enum EUnityCatalogFieldsId {
	summary = "summary",
	description = "description",
	priority = "priority",
	teamName = "customfield_13230",
	valueStream = "customfield_13634",
	dataAccessRequest = "customfield_13581"
}

export interface IRequestFieldsInfo {
	fieldId: string;
	name: string;
	description: string;
	required: boolean;
	defaultValues: string[];
	validValues: IValidValues[];
	jiraSchema: IJiraSchema;
	visible: boolean;
}

interface IJiraSchema {
	type: string;
	system: string;
}

interface IValidValues {
	value: string;
	label: string;
	children: string[];
}
