import { Tabs } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

const { Content } = Tabs;

export const TabContentStyled = styled(Content, {
	padding: "$2 0",
	minHeight: "calc(100vh - 400px)",
	h2: {
		margin: "$4 0",
		width: "100%",
		lineHeight: "$6"
	}
});
