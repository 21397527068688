import { Error503 as Error503Component } from "@hexa-ui/components";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { routes } from "router/constants";
import { ErrorPageStyled } from "../ErrorComponentStyled";

const Error503 = (): JSX.Element => {
	const { formatMessage } = useIntl();
	const navigate = useNavigate();

	return (
		<ErrorPageStyled>
			<Error503Component
				headerText={formatMessage({ id: "ERROR_503.HEADER" })}
				subheaderText={formatMessage({ id: "ERROR_503.DESCRIPTION" })}
				buttonText={formatMessage({ id: "ERROR_503.BUTTON_TEXT" })}
				infoText={formatMessage(
					{ id: "ERROR_503.INFO_TEXT" },
					{
						a: (str) => (
							<a
								href="https://ab-inbev.atlassian.net/servicedesk/customer/portal/5"
								target="_blank"
								rel="noreferrer"
							>
								{str}
							</a>
						)
					}
				)}
				buttonClickHandler={() => navigate(routes.HOME_PAGE)}
			/>
		</ErrorPageStyled>
	);
};

export default Error503;
