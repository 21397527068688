import { Input } from "@hexa-ui/components";
import { IRequestTypeInfo } from "pages/AccessManagementPages/RequestAccessManagementPage/RequestAccessManagementPage.types";
import { useFormContext } from "react-hook-form";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";

interface ICustomInputProps {
	item: IRequestTypeInfo;
}

export const CustomInput = ({ item }: ICustomInputProps): JSX.Element => {
	const {
		register,
		formState: { errors },
		watch
	} = useFormContext();
	const formValues = watch();

	return (
		<ContainerStyled marginTop marginBottom key={item?.fieldId}>
			<Input
				id={item?.fieldId}
				label={item?.name}
				placeholder={item?.name}
				width="100%"
				hint={item?.description}
				hasError={!!errors?.[item?.fieldId]}
				errorText={errors?.[item?.fieldId]?.message as string}
				value={formValues?.[item?.fieldId]}
				{...register(item?.fieldId)}
			/>
		</ContainerStyled>
	);
};
