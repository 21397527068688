export interface IInfraStacksInfo {
	id: string;
	title: string;
	description: string;
	documentation: string;
}

export const infraStacksInfo: IInfraStacksInfo[] = [
	{
		id: "processing-stack",
		title: "Processing Stack",
		description:
			"The processing stack is a set of resources normally used to extract and transform the data preparing it to be used by other domains or creating new data products.",
		documentation:
			"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3365044357/Landing+Zone+Questions#Processing-Stack"
	},
	{
		id: "data-lake-stack",
		title: "Data Lake Stack",
		description:
			"Data Lake stack is used to store the data extracted, used and manipulated by the data domain. It contains three data lakes: Raw, Refined, and Workspace. ",
		documentation:
			"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3365044357/Landing+Zone+Questions#Data-lake-Stack"
	},
	{
		id: "database-stack",
		title: "Database Stack",
		description:
			"The database stack can create two types of databases MySQL and Postgres databases and can be used to store any data or data product that the domain need.",
		documentation:
			"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3365044357/Landing+Zone+Questions#Database-Stack"
	},
	{
		id: "workspace-stack",
		title: "Workspace Stack",
		description:
			"The workspace stack is used by data analysts to work with the data and reach insights from this data or create dashboards and data products.",
		documentation:
			"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3365044357/Landing+Zone+Questions#workspace-Stack"
	},
	{
		id: "machine-learning-stack",
		title: "Machine Learning Stack",
		description:
			"Machine Learning is an stack with all resources a domain needs to work with machine learning.",
		documentation:
			"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3365044357/Landing+Zone+Questions#Machine-learning-Stack"
	},
	{
		id: "data-share-stack",
		title: "Data Share Stack",
		description:
			"Data Share is a stack with all necessary resources for the data domains to share their data sets and data products with other applications.",
		documentation:
			"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3365044357/Landing+Zone+Questions#Share-Stack"
	},
	{
		id: "streaming-stack",
		title: "Streaming Stack",
		description:
			"Streaming is an stack that data domains will use if they want to enable data streaming in their domain (used for domains that needs to work with event hubs).",
		documentation:
			"https://ab-inbev.atlassian.net/wiki/spaces/PKB/pages/3365044357/Landing+Zone+Questions#Data-Streaming-Stack"
	}
];
