import { Checkbox, Grid, Input, Paragraph, Quantifier, Select, Tooltip } from "@hexa-ui/components";
import { HelpCircle } from "@hexa-ui/icons";
import { ScopesCheckboxesStyled } from "components/Modals/ReportModal/ReportModalStyled";
import { InfraFormContext } from "contexts";
import { useContext, useEffect } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { handleChange } from "../../InfraSupportFunctions";
import { HeadingStyled } from "../GeneralQuestions/GeneralQuestionsFormsStyled";
import { CardStyled, ItemStyled } from "./EventHubFieldsStyled";
import { DividerStyled } from "./StreamingStackStyled";

const { Item } = Grid;

interface IEventHubFieldsProps {
	formIndex: number;
	hubIndex: number;
}

export const EventHubFields = ({ formIndex, hubIndex }: IEventHubFieldsProps) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const {
		register,
		getValues,
		control,
		setValue,
		formState: { errors }
	} = useFormContext();
	const CAPTURE_ENABLED_FIELD =
		infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.capture_enabled;

	useEffect(() => {
		const dependentFields = [
			"capture_encoding",
			"capture_interval",
			"capture_size",
			"skip_empty_archives",
			"capture_archive_format",
			"datalake_name",
			"datalake_resource_group",
			"container_name"
		];
		!infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.capture_enabled &&
			dependentFields.map((field) => {
				setValue(`streaming_stack.${formIndex}.event_hubs.${hubIndex}.${field}`, undefined);
			});
	}, [CAPTURE_ENABLED_FIELD]);

	const setupEventHubInput = (fieldName: string) => {
		return {
			hasError: !!errors?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.[fieldName],
			errorText: errors?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.[fieldName]
				?.message as string,
			value: infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.[fieldName]
		};
	};

	const setupEventHubError = (fieldName) => {
		return {
			error: errors?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.[fieldName]?.message
		};
	};

	return (
		<CardStyled border="small" elevated="small" data-testid="event-hub-form">
			<HeadingStyled size="H4">Event Hub</HeadingStyled>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register(`streaming_stack.${formIndex}.event_hubs.${hubIndex}.hub_name`, {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="hub_name"
					label="Hub Name"
					value={infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.hub_name}
					width="100%"
					placeholder=""
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<ItemStyled>
					<Paragraph size="small">Partitions</Paragraph>
					<Tooltip text="Number of partitions for this hub">
						<HelpCircle size="medium" />
					</Tooltip>
				</ItemStyled>
				<Controller
					control={control}
					name={`streaming_stack.${formIndex}.event_hubs.${hubIndex}.partitions`}
					render={({ field: { onChange } }) => {
						return (
							<Quantifier
								id="partitions"
								data-testid="partitions"
								value={
									infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.partitions
								}
								onValueChange={(value: number) => {
									onChange(value);
									handleChange(getValues, setInfraFormData);
								}}
							/>
						);
					}}
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<ItemStyled>
					<Paragraph size="small">Retention</Paragraph>
					<Tooltip text="Number of days messages will be retained on the hub">
						<HelpCircle size="medium" />
					</Tooltip>
				</ItemStyled>
				<Controller
					control={control}
					name={`streaming_stack.${formIndex}.event_hubs.${hubIndex}.retention`}
					render={({ field: { onChange } }) => {
						return (
							<Quantifier
								id="retention"
								data-testid="retention"
								value={
									infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.retention
								}
								onValueChange={(value: number) => {
									onChange(value);
									handleChange(getValues, setInfraFormData);
								}}
							/>
						);
					}}
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<ScopesCheckboxesStyled>
					<Controller
						control={control}
						name={`streaming_stack.${formIndex}.event_hubs.${hubIndex}.capture_enabled`}
						render={({ field: { onChange } }) => {
							return (
								<Checkbox
									id="capture_enabled"
									label="Enable Capture"
									checked={
										infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]
											?.capture_enabled
									}
									onCheckedChange={(value) => {
										onChange(value);
										handleChange(getValues, setInfraFormData);
									}}
									{...setupEventHubError("capture_enabled")}
								/>
							);
						}}
					/>
				</ScopesCheckboxesStyled>
			</ContainerStyled>
			{CAPTURE_ENABLED_FIELD && (
				<>
					<ContainerStyled marginBottom marginTop>
						<Controller
							control={control}
							name={`streaming_stack.${[formIndex]}.event_hubs.${[hubIndex]}.capture_encoding`}
							render={({ field: { onChange } }) => {
								return (
									<Select.Root
										id="capture_encoding"
										data-testid="capture_encoding"
										label="Capture Encoding"
										labelProps={{ htmlFor: "capture_encoding" }}
										value={
											infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]
												?.capture_encoding
										}
										onChange={onChange}
										{...setupEventHubError("capture_encoding")}
									>
										<Select.Option value="Avro">Avro</Select.Option>
										<Select.Option value="AvroDeflate">AvroDeflate</Select.Option>
									</Select.Root>
								);
							}}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<ItemStyled>
							<Paragraph size="small">Capture Interval</Paragraph>
							<Tooltip text="Number of seconds between captures">
								<HelpCircle size="medium" />
							</Tooltip>
						</ItemStyled>
						<Controller
							control={control}
							name={`streaming_stack.${formIndex}.event_hubs.${hubIndex}.capture_interval`}
							render={({ field: { onChange } }) => {
								return (
									<Quantifier
										id="capture_interval"
										data-testid="capture_interval"
										value={
											infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]
												?.capture_interval
										}
										onValueChange={(value: number) => {
											onChange(value);
											handleChange(getValues, setInfraFormData);
										}}
									/>
								);
							}}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<ItemStyled>
							<Paragraph size="small">Capture Size</Paragraph>
							<Tooltip text="Size of informations beteeen captures">
								<HelpCircle size="medium" />
							</Tooltip>
						</ItemStyled>
						<Controller
							control={control}
							name={`streaming_stack.${formIndex}.event_hubs.${hubIndex}.capture_size`}
							render={({ field: { onChange } }) => {
								return (
									<Quantifier
										id="capture_size"
										data-testid="capture_size"
										value={
											infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]
												?.capture_size
										}
										onValueChange={(value: number) => {
											onChange(value);
											handleChange(getValues, setInfraFormData);
										}}
									/>
								);
							}}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<ScopesCheckboxesStyled>
							<Controller
								control={control}
								name={`streaming_stack.${formIndex}.event_hubs.${hubIndex}.skip_empty_archives`}
								render={({ field: { onChange } }) => {
									return (
										<Checkbox
											id="skip_empty_archives"
											label="Skip Empty Archives"
											checked={
												infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]
													?.skip_empty_archives
											}
											onCheckedChange={(value) => {
												onChange(value);
												handleChange(getValues, setInfraFormData);
											}}
											{...setupEventHubError("skip_empty_archives")}
										/>
									);
								}}
							/>
						</ScopesCheckboxesStyled>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(
								`streaming_stack.${formIndex}.event_hubs.${hubIndex}.capture_archive_format`,
								{
									onChange: () => handleChange(getValues, setInfraFormData)
								}
							)}
							id="capture_archive_format"
							label="Capture Archive Format"
							width="100%"
							placeholder="{Namespace}/{EventHub}/{PartitionId}/{Year}/{Month}/{Day}/{Hour}/{Minute}/{Second}"
							{...setupEventHubInput("capture_archive_format")}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(`streaming_stack.${formIndex}.event_hubs.${hubIndex}.datalake_name`, {
								onChange: () => handleChange(getValues, setInfraFormData)
							})}
							id="datalake_name"
							label="Data Lake Name"
							width="100%"
							placeholder=""
							{...setupEventHubInput("datalake_name")}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(
								`streaming_stack.${formIndex}.event_hubs.${hubIndex}.datalake_resource_group`,
								{
									onChange: () => handleChange(getValues, setInfraFormData)
								}
							)}
							id="datalake_resource_group"
							label="Data Lake Resource Group"
							width="100%"
							placeholder=""
							{...setupEventHubInput("datalake_resource_group")}
						/>
					</ContainerStyled>
					<ContainerStyled marginBottom marginTop>
						<Input
							{...register(`streaming_stack.${formIndex}.event_hubs.${hubIndex}.container_name`, {
								onChange: () => handleChange(getValues, setInfraFormData)
							})}
							id="container_name"
							label="Container Name"
							value={
								infraFormData?.streaming_stack?.[formIndex]?.event_hubs?.[hubIndex]?.container_name
							}
							width="100%"
							placeholder=""
							{...setupEventHubInput("container_name")}
						/>
					</ContainerStyled>
				</>
			)}
			<DividerStyled />
			<HeadingStyled size="H4">Authorization Rule</HeadingStyled>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register(`streaming_stack.${formIndex}.authorization_rule.${hubIndex}.rule_name`, {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="rule_name"
					label="Rule Name"
					value={
						infraFormData?.streaming_stack?.[formIndex]?.authorization_rule?.[hubIndex]?.rule_name
					}
					width="100%"
					placeholder=""
				/>
			</ContainerStyled>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register(
						`streaming_stack.${formIndex}.authorization_rule.${hubIndex}.event_hub_name`,
						{
							onChange: () => handleChange(getValues, setInfraFormData)
						}
					)}
					id="event_hub_name"
					label="Event Hub Name"
					value={
						infraFormData?.streaming_stack?.[formIndex]?.authorization_rule?.[hubIndex]
							?.event_hub_name
					}
					width="100%"
					placeholder=""
				/>
			</ContainerStyled>
			<ContainerStyled>
				<ScopesCheckboxesStyled>
					<Paragraph size="small" weight="semibold">
						Authorizations
					</Paragraph>
					<ContainerStyled marginTop>
						<Item>
							<Controller
								control={control}
								name={`streaming_stack.${formIndex}.authorization_rule.${hubIndex}.listen`}
								render={({ field: { onChange } }) => {
									return (
										<Checkbox
											id="listen"
											label="Listen"
											checked={
												infraFormData?.streaming_stack?.[formIndex]?.authorization_rule?.[hubIndex]
													?.listen
											}
											onCheckedChange={(value) => {
												onChange(value);
												handleChange(getValues, setInfraFormData);
											}}
										/>
									);
								}}
							/>
							<Controller
								control={control}
								name={`streaming_stack.${formIndex}.authorization_rule.${hubIndex}.send`}
								render={({ field: { onChange } }) => {
									return (
										<Checkbox
											id="send"
											label="Send"
											checked={
												infraFormData?.streaming_stack?.[formIndex]?.authorization_rule?.[hubIndex]
													?.send
											}
											onCheckedChange={(value) => {
												onChange(value);
												handleChange(getValues, setInfraFormData);
											}}
										/>
									);
								}}
							/>
							<Controller
								control={control}
								name={`streaming_stack.${formIndex}.authorization_rule.${hubIndex}.manage`}
								render={({ field: { onChange } }) => {
									return (
										<Checkbox
											id="manage"
											label="Manage"
											checked={
												infraFormData?.streaming_stack?.[formIndex]?.authorization_rule?.[hubIndex]
													.manage
											}
											onCheckedChange={(value) => {
												onChange(value);
												handleChange(getValues, setInfraFormData);
											}}
										/>
									);
								}}
							/>
						</Item>
					</ContainerStyled>
				</ScopesCheckboxesStyled>
			</ContainerStyled>
		</CardStyled>
	);
};
