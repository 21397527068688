import { createContext, ReactNode, useContext, useState } from "react";
import { ISearchedResult } from "types/Dashboard.types";

export interface IReportSearchProps {
	searchResult: ISearchedResult;
	setSearchResult: (value: ISearchedResult) => void;
	wasSearched: boolean;
	setWasSearched: (value: boolean) => void;
	isSearchReportLoading: boolean;
	setIsSearchReportLoading: (value: boolean) => void;
}

export const ReportSearch = createContext<IReportSearchProps>({
	searchResult: {} as ISearchedResult,
	setSearchResult: () => null,
	wasSearched: false,
	setWasSearched: () => null,
	isSearchReportLoading: false,
	setIsSearchReportLoading: () => null
});

export const useReportSearchContext = () => useContext(ReportSearch);

export const ReportSearchProvider = ({ children }: { children: ReactNode }) => {
	const [searchResult, setSearchResult] = useState({ data: [], count: 0 });
	const [wasSearched, setWasSearched] = useState(false);
	const [isSearchReportLoading, setIsSearchReportLoading] = useState(false);

	return (
		<ReportSearch.Provider
			value={{
				searchResult,
				setSearchResult,
				wasSearched,
				setWasSearched,
				isSearchReportLoading,
				setIsSearchReportLoading
			}}
		>
			{children}
		</ReportSearch.Provider>
	);
};
