import { ROUTE_BASE_URL } from "../constants";
import { IRoutesParams } from "./RouteParams.types";

export const POST_JIRA_TICKET = "POST_JIRA_TICKET";
export const GET_JIRA_FIELDS = "GET_JIRA_FIELDS";
export const GET_OPEN_REQUESTS = "GET_OPEN_REQUESTS";
export const GET_TICKET = "GET_TICKET";
export const ADD_COMMENT = "ADD_COMMENT";
export const ADD_PARTICIPANT = "ADD_PARTICIPANT";
export const REMOVE_PARTICIPANT = "REMOVE_PARTICIPANT";

export const GetOpenRequestsRoutes = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_OPEN_REQUESTS: `/jira/open`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const GetTicketByIdRoutes = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_TICKET: `/jira/ticket/`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const GetRequestFieldsRoutes = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_JIRA_FIELDS: `/jira/fields/`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const PostJiraTicketRoutes = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		POST_JIRA_TICKET: `/jira/ticket`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const AddJiraCommentRoutes = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		ADD_COMMENT: `/jira/comment`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const AddParticipantRoutes = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		ADD_PARTICIPANT: `/jira/participant`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const RemoveParticipantRoutes = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		REMOVE_PARTICIPANT: `/jira/participant`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};
