import { CardTypes, Grid, Heading, Paragraph } from "@hexa-ui/components";
import { useState } from "react";
import { DescriptionStyled, HeaderCardStyled, InfraCardStyled } from "./InfraServiceCardStyled";

const { Item } = Grid;

type ElevationType = CardTypes["elevated"];

export interface IInfraCardProps {
	id?: string;
	icon: React.ReactNode;
	title: string;
	subtitle: string;
	onClick: () => void;
}

export const InfraServiceCard = ({
	icon,
	title,
	subtitle,
	onClick
}: IInfraCardProps): React.JSX.Element => {
	const [elevation, setElevation] = useState<ElevationType>("medium");

	return (
		<Item xl={2} lg={3} md={6} sm={6} xs={12}>
			<InfraCardStyled
				border="small"
				elevated={elevation}
				data-testid={`infra-service-card`}
				onMouseOver={() => setElevation("large")}
				onMouseLeave={() => setElevation("medium")}
				onClick={onClick}
			>
				<HeaderCardStyled>
					{icon}
					<Heading size="H4">{title}</Heading>
				</HeaderCardStyled>
				<DescriptionStyled>
					<Paragraph colortype="disabled">{subtitle}</Paragraph>
				</DescriptionStyled>
			</InfraCardStyled>
		</Item>
	);
};
