import { styled } from "@hexa-ui/theme";

export const ContainerStyled = styled("div", {
	variants: {
		marginTop: {
			true: { marginTop: "12px" },
			false: { marginTop: "0" }
		},
		marginBottom: {
			true: { marginBottom: "12px" },
			false: { marginBottom: "0" }
		}
	},

	width: "100%",
	display: "flex",
	flexWrap: "wrap",
	alignItems: "center",

	".label": {
		width: "100%"
	}
});
