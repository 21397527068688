import { Paragraph } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const StackCardsWrapperStyled = styled("div", {
	display: "flex",
	justifyContent: "flex-start",
	width: "100%",
	flexWrap: "wrap"
});

export const ParagraphStyled = styled(Paragraph, {
	margin: "$2 0 $6",
	width: "100%"
});

export const ServicesWrapperStyled = styled("div", {
	display: "flex",
	justifyContent: "flex-start",
	width: "100%",
	flexWrap: "wrap"
});
