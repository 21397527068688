import { useAuthenticationService } from "admin-portal-shared-services";
import { UserInfoToken } from "admin-portal-shared-services/dist/services/authentication/AuthenticationService.types";
import { createContext, useContext } from "react";
import { EScopesSection } from "types/Report.types";

export interface AuthConfig {
	hasSession: boolean;
	userName: string;
	token: string;
	decodedToken?: UserInfoToken;
	scopes: Array<string>;
	objectsAllowed: Array<string>;
	dataServices: Array<string>;
	isPublisher: boolean;
}

interface IAuthContextProps {
	children: React.ReactNode;
	session?: AuthConfig;
}

const defaultInitialValue: AuthConfig = {
	hasSession: false,
	userName: "",
	token: "",
	scopes: [],
	objectsAllowed: [],
	dataServices: [],
	isPublisher: false
};

const checkScopes = (decodedScopes: Array<string>, index: EScopesSection) => {
	return decodedScopes.map((item) => item.split(".")[index]);
};

const InitAuthenticationConfig = (): AuthConfig => {
	const authService = useAuthenticationService();
	const auth = authService.getAuthHeader();
	let usersDataServices = [];

	const dataServicesAccesses = {
		Bees: [
			"BEES Global Reports",
			"Self Service Analytics",
			"BEES Data Catalog",
			"Digital Comms Services",
			"Data Infra Services",
			"Access Management",
			"Usage Metrics"
		],
		Global: [
			"BEES Global Reports",
			"Self Service Analytics",
			"BEES Data Catalog",
			"Access Management"
		]
	};

	if (!auth) return defaultInitialValue;

	const decodedToken = authService.parseJwt();
	const decodedScopes = decodedToken?.scopes || [];
	const midScopes = checkScopes(decodedScopes, EScopesSection.Object);
	const finalScopes = checkScopes(decodedScopes, EScopesSection.Action);
	const isPublisher = finalScopes.includes("Publish");
	const userScopes = authService.getUserScopes();

	if (midScopes.includes("Bees")) {
		usersDataServices = dataServicesAccesses.Bees;
	} else if (midScopes.includes("Global")) {
		usersDataServices = dataServicesAccesses.Global;
	}

	return {
		hasSession: true,
		userName: decodedToken.name || "",
		token: auth,
		scopes: userScopes,
		objectsAllowed: midScopes,
		decodedToken,
		dataServices: usersDataServices,
		isPublisher: isPublisher
	};
};

export const AuthContext = createContext(defaultInitialValue);
export const useAuthContext = () => useContext(AuthContext);
export const AuthProvider = ({
	children,
	session = InitAuthenticationConfig()
}: IAuthContextProps) => {
	return <AuthContext.Provider value={session}>{children}</AuthContext.Provider>;
};
