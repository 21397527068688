import { Input, Select } from "@hexa-ui/components";
import { InfraFormContext } from "contexts";
import { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { handleChange, setupInput } from "../../InfraSupportFunctions";

interface IBasicStackFormProps {
	acceptsPrimaryRegion?: boolean;
	acceptsShortProductName?: boolean;
	formSection?: string;
	index?: number;
	error?: {
		hasUniquenessError: boolean;
		errorMessage: string;
	};
}

export const BasicStackForm = ({
	acceptsPrimaryRegion,
	acceptsShortProductName,
	formSection,
	index,
	error
}: IBasicStackFormProps) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const {
		control,
		register,
		getValues,
		formState: { errors }
	} = useFormContext();
	const { hasUniquenessError, errorMessage } = error;

	const primaryRegionOptions = () => {
		if (
			infraFormData?.[formSection]?.[index]?.regions === undefined ||
			infraFormData?.[formSection]?.[index]?.regions.length === 0
		) {
			return (
				<Select.Option value="empty-state" key="empty-state" disabled>
					Select at least one region above
				</Select.Option>
			);
		} else {
			return infraFormData?.[formSection]?.[index]?.regions.map((region) => {
				return (
					<Select.Option value={region} key={`primary ${region}`}>
						{region}
					</Select.Option>
				);
			});
		}
	};

	return (
		<>
			<ContainerStyled marginBottom marginTop>
				<Input
					{...register(`${formSection}.${index}.product_name`, {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="product_name"
					label="Product Name"
					data-testid="product-name"
					width="100%"
					placeholder="Product Name"
					hint="Product Name must be at most 23 characters."
					value={infraFormData?.[formSection]?.[index]?.product_name}
					hasError={!!errors?.[formSection]?.[index]?.["product_name"] || hasUniquenessError}
					errorText={
						(hasUniquenessError && errorMessage) ||
						errors?.[formSection]?.[index]?.["product_name"]?.message
					}
				/>
			</ContainerStyled>
			{acceptsShortProductName && (
				<ContainerStyled marginBottom marginTop>
					<Input
						{...register(`${formSection}.${index}.short_product_name`, {
							onChange: () => handleChange(getValues, setInfraFormData)
						})}
						id="short_product_name"
						label="Short Product Name"
						hint="Short Product Name must be at most 3 characters."
						width="100%"
						placeholder="spn"
						{...setupInput(errors, formSection, "short_product_name", index, infraFormData)}
					/>
				</ContainerStyled>
			)}
			<ContainerStyled marginBottom marginTop>
				<Controller
					control={control}
					name={`${formSection}.${index}.regions`}
					render={({ field: { onChange } }) => {
						return (
							<Select.Root
								id="regions"
								label="Regions"
								labelProps={{ htmlFor: "regions", className: "label" }}
								data-testid="regions"
								placeholder="Choose an option"
								onChange={(value) => {
									onChange(value);
									handleChange(getValues, setInfraFormData);
								}}
								value={infraFormData?.[formSection]?.[index]?.regions}
								error={errors?.[formSection]?.[index]?.["regions"]?.message}
								multiple
							>
								<Select.Option value="am">am</Select.Option>
								<Select.Option value="ar">ar</Select.Option>
								<Select.Option value="eu">eu</Select.Option>
								<Select.Option value="er">er</Select.Option>
							</Select.Root>
						);
					}}
				/>
			</ContainerStyled>
			{acceptsPrimaryRegion && (
				<ContainerStyled marginBottom marginTop>
					<Controller
						name={`${formSection}.${index}.primary_region`}
						render={({ field: { onChange } }) => {
							return (
								<Select.Root
									id="primary_region"
									label="Primary Region"
									hint="It must be one of the Regions selected"
									data-testid="primary-region-select"
									labelProps={{ htmlFor: "primary_region", className: "label" }}
									placeholder="Choose an option"
									onChange={(value) => {
										onChange(value);
										handleChange(getValues, setInfraFormData);
									}}
									defaultValue={infraFormData?.[formSection]?.[index]?.primary_region}
								>
									{primaryRegionOptions()}
								</Select.Root>
							);
						}}
					/>
				</ContainerStyled>
			)}
		</>
	);
};
