import { TextLink } from "@hexa-ui/components";
import { BarChart3 } from "@hexa-ui/icons";
import IconPowerBi from "assets/AppIcons/IconPowerBi";
import { generatePath, routes } from "router/constants";
import { EReportType, IReport } from "types/Report.types";
import { convertFirstLetterToLowercase } from "utils/transformers";

export const renderDashboardTitle = (dashboardTitle: string, object: IReport) => {
	const lowerCaseDomainName = convertFirstLetterToLowercase(object.domain.domainName);
	return (
		<TextLink
			hasUnderline={false}
			colorOption="black"
			href={generatePath(routes.GLOBAL_REPORTS.REPORT_DETAILS, {
				domainName: lowerCaseDomainName,
				reportId: object.reportId
			})}
		>
			{dashboardTitle}
		</TextLink>
	);
};

export const renderTypeIconColumn = (dashboardType: EReportType) => {
	if (dashboardType === EReportType.POWER_BI) {
		return <IconPowerBi aria-label={EReportType.POWER_BI} data-testid="power-bi-icon" />;
	}
	return <BarChart3 size="large" />;
};
