import { Button, Error500 as Error500Component } from "@hexa-ui/components";
import { useIntl } from "react-intl";
import { Error500Styled } from "./Error500Styled";

export const Error500 = ({ onClick }): JSX.Element => {
	const intl = useIntl();

	return (
		<Error500Styled>
			<Error500Component headerText={intl.formatMessage({ id: "ERROR_500.HEADER" })} />
			<Button onClick={onClick}>Reload</Button>
		</Error500Styled>
	);
};

export default Error500;
