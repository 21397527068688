import { useAuthenticationService } from "admin-portal-shared-services";
import Axios from "axios";
import { setUpMockAdapter } from "services/mocks/MockAdapter";

export interface IRequest {
	url: string;
	params?: React.ReactNode;
	body?: React.ReactNode;
	baseURL?: string;
	data?: string | string[];
}

const authentication = useAuthenticationService();
const { user_country: userCountry } = authentication.getUserCountryAndLanguage();

export const initialAxios = Axios.create();

authentication.enhancedAxios(
	initialAxios as unknown as Parameters<typeof authentication.enhancedAxios>[0],
	{
		headers: [
			{
				country: userCountry || "BR"
			}
		]
	}
);

authentication.enhancedAxios(initialAxios);
setUpMockAdapter(initialAxios);

const Api = {
	post: ({ url, body, baseURL, params }: IRequest): Promise<any> =>
		initialAxios.post(url, body, { baseURL, params }),

	delete: ({ url, baseURL, data }: IRequest): Promise<any> =>
		initialAxios.delete(url, { baseURL, data }),

	get: ({ url, params, baseURL }: IRequest): Promise<any> =>
		initialAxios.get(url, { params, baseURL }),

	put: ({ url, body, baseURL }: IRequest): Promise<any> => initialAxios.put(url, body, { baseURL })
};

export default Api;
