export const infraFormDefaultValues = (infraFormData) => {
	return {
		summary: "",
		General_questions: infraFormData?.General_questions || {
			team_name: "",
			environments: [],
			admin_users: "",
			non_admin_users: ""
		},
		processing_stack: infraFormData?.processing_stack || [
			{
				product_name: "",
				regions: [],
				primary_region: "",
				short_product_name: "",
				need_databricks: undefined,
				databricks_repository: "",
				need_datafactory: undefined,
				datafactory_repository: "",
				additional_vaults: []
			}
		],
		datalake_stack: infraFormData?.datalake_stack || [
			{
				product_name: "",
				regions: [],
				primary_region: "",
				short_product_name: "",
				raw_containers: [],
				refined_containers: [],
				workspace_containers: []
			}
		],
		database_stack: infraFormData?.database_stack || [
			{
				product_name: "",
				regions: [],
				primary_region: "",
				short_product_name: "",
				need_mysql: undefined,
				mysql_server_type: "",
				mysql_sku: "",
				mysql_version: "",
				mysql_extra_config: [],
				need_postgre: undefined,
				postgre_server_type: "single",
				postgre_sku: "",
				postgre_version: "",
				postgre_storage: "",
				postgre_extra_config: []
			}
		],
		workspace_stack: infraFormData?.workspace_stack || [
			{
				product_name: "",
				regions: [],
				primary_region: "",
				short_product_name: "",
				need_databricks: undefined,
				need_synapse: undefined,
				need_synapse_sql_pool: undefined,
				sql_pool_collation: "",
				sql_pool_sku: ""
			}
		],
		machine_learning_stack: infraFormData?.machine_learning_stack || [
			{
				product_name: "",
				regions: [],
				short_product_name: ""
			}
		],
		data_share_stack: infraFormData?.data_share_stack || [
			{
				product_name: "",
				regions: []
			}
		],
		streaming_stack: infraFormData?.streaming_stack || [
			{
				product_name: "",
				regions: [],
				short_product_name: "",
				event_hub_namespace_sku: "",
				event_hub_namespace_capacity: "",
				event_hub_namespace_auto_inflate_enable: false,
				event_hub_namespace_zones: false,
				event_hub_namespace_public_access: false,
				event_hub_dedicated_cluster: false,
				event_hubs: infraFormData?.streaming_stack?.[0]?.event_hubs || [
					{
						hub_name: "",
						partitions: 1,
						retention: 7,
						capture_enabled: false,
						capture_encoding: "",
						capture_interval: 300,
						capture_size: 314572800,
						skip_empty_archives: true,
						capture_archive_format:
							"{Namespace}/{EventHub}/{PartitionId}/{Year}/{Month}/{Day}/{Hour}/{Minute}/{Second}",
						datalake_name: "",
						datalake_resource_group: "",
						container_name: ""
					}
				],
				authorization_rule: []
			}
		]
	};
};
