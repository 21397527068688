import { styled } from "@hexa-ui/theme";

export const LabelBackground = styled("div", {
	display: "flex",
	borderRadius: "4px",
	height: "28px",
	padding: "0px 8px",
	alignItems: "center",
	gap: "8px",
	width: "fit-content",
	variants: {
		variant: {
			Active: {
				background: "$semanticSuccessBackground"
			},
			Inactive: {
				background: "$semanticErrorBackground"
			}
		}
	}
});
