import { styled } from "@hexa-ui/theme";

export const YesorNoRadioStyled = styled("div", {
	width: "100%",
	marginLeft: "8px",
	display: "flex",
	label: {
		padding: "0 16px 0 8px"
	}
});
