import { Input, Paragraph } from "@hexa-ui/components";
import { InfraFormContext } from "contexts";
import { useValidateUniqueValues } from "hooks/useValidateUniqueValues";
import { FormHeader } from "pages/InfraServicesPages/components/FormHeader";
import { infraStacksInfo } from "pages/InfraServicesPages/components/InfraStacksInfo";
import { useContext, useEffect } from "react";
import { useFormContext } from "react-hook-form";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { ParagraphStyled } from "styles/shared/ParagraphStyled/ParagraphStyled";
import { IStackFieldsProps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { cleanDependentFields, handleChange, setupInput } from "../../InfraSupportFunctions";
import { YesOrNoRadio } from "../../components/YesOrNoRadio";
import { BasicStackForm } from "../BasicStack/BasicStackForm";

export const ProcessingStackFields = ({
	fieldId,
	index,
	removeItem,
	fields
}: IStackFieldsProps) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const stackInfo = infraStacksInfo.filter((stack) => stack.id === "processing-stack")?.[0];
	const {
		watch,
		register,
		getValues,
		setValue,
		formState: { errors }
	} = useFormContext();
	const NEED_DATABRICKS_FIELD = infraFormData?.processing_stack?.[index]?.need_databricks;
	const NEED_DATAFACTORY_FIELD = infraFormData?.processing_stack?.[index]?.need_datafactory;
	const formValues = watch("processing_stack");
	const error = useValidateUniqueValues(formValues, index, "product_name", "Product Name");

	useEffect(() => {
		setValue(`processing_stack.${index}.id`, fieldId);
	}, [fieldId, infraFormData]);

	useEffect(() => {
		cleanDependentFields(infraFormData, setValue, "processing_stack", index, "need_databricks", [
			"databricks_repository"
		]);
	}, [NEED_DATABRICKS_FIELD]);

	useEffect(() => {
		cleanDependentFields(infraFormData, setValue, "processing_stack", index, "need_datafactory", [
			"datafactory_repository"
		]);
	}, [NEED_DATAFACTORY_FIELD]);

	return (
		<ContainerCardStyled
			border="small"
			elevated="medium"
			key={fieldId}
			data-testid="processing-stack-form"
		>
			<FormHeader
				stackInfo={stackInfo}
				fields={fields}
				removeItem={removeItem}
				index={index}
				fieldId={fieldId}
				formSection="processing_stack"
			/>
			<BasicStackForm
				acceptsPrimaryRegion
				acceptsShortProductName
				formSection="processing_stack"
				index={index}
				error={error}
			/>
			<ContainerStyled>
				<Paragraph size="small" weight="semibold" width="100%">
					Need Databricks?
				</Paragraph>
				<YesOrNoRadio
					id={`need_databricks`}
					fieldData="need_databricks"
					formSection="processing_stack"
					index={index}
				/>
			</ContainerStyled>
			{NEED_DATABRICKS_FIELD && (
				<ContainerStyled marginBottom marginTop>
					<Input
						{...register(`processing_stack.${index}.databricks_repository`, {
							onChange: () => handleChange(getValues, setInfraFormData)
						})}
						id="databricks_repository"
						label="Databricks Repository"
						labelProps={{ htmlFor: "databricks_repository" }}
						placeholder=""
						width="100%"
						{...setupInput(
							errors,
							"processing_stack",
							"databricks_repository",
							index,
							infraFormData
						)}
					/>
				</ContainerStyled>
			)}
			<ContainerStyled>
				<ParagraphStyled size="small" weight="semibold">
					Need Datafactory?
				</ParagraphStyled>
				<YesOrNoRadio
					id={`need_datafactory`}
					fieldData="need_datafactory"
					formSection="processing_stack"
					index={index}
				/>
			</ContainerStyled>
			{NEED_DATAFACTORY_FIELD && (
				<ContainerStyled marginBottom marginTop>
					<Input
						{...register(`processing_stack.${index}.datafactory_repository`, {
							onChange: () => handleChange(getValues, setInfraFormData)
						})}
						id="datafactory_repository"
						label="Datafactory Repository"
						labelProps={{ htmlFor: "datafactory_repository" }}
						placeholder=""
						width="100%"
						{...setupInput(
							errors,
							"processing_stack",
							"datafactory_repository",
							index,
							infraFormData
						)}
					/>
				</ContainerStyled>
			)}
			<ContainerStyled marginBottom>
				<Input
					{...register(`processing_stack.${index}.additional_vaults`, {
						onChange: () => handleChange(getValues, setInfraFormData)
					})}
					id="additional_vaults"
					label="Additional Vaults"
					hint="Example: additional vault 1, additional vault 2..."
					labelProps={{ htmlFor: "additional_vaults" }}
					placeholder="Additional Vaults"
					width="100%"
					{...setupInput(errors, "processing_stack", "additional_vaults", index, infraFormData)}
				/>
			</ContainerStyled>
		</ContainerCardStyled>
	);
};
