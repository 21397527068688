import { Paragraph, Radio } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const PageDescriptionStyled = styled(Paragraph, {
	margin: "$2 0 $8 0"
});

export const DialogActionButtonStyled = styled("div", {
	display: "flex",
	justifyContent: "flex-end",

	Button: {
		marginRight: "1rem"
	}
});

export const GoodPracticesContainerStyled = styled("div", {
	padding: "1rem 0",
	display: "grid",
	gap: "8px",

	h4: {
		marginBottom: "$2"
	},

	hr: {
		width: "100%",
		height: "1px",
		margin: "0 0 8px 0",
		border: 0,
		backgroundColor: "rgba(0,0,0,0.15)"
	},

	ul: {
		margin: "0 0 8px 0"
	}
});

export const RadioRootStyled = styled(Radio.Root, {
	width: "100%",
	display: "flex",
	gap: "$2",
	label: {
		paddingLeft: "$2",
		marginRight: "20px"
	}
});

export const ScopesCheckboxesStyled = styled("div", {
	width: "100%",
	lineHeight: "24px",
	display: "flex",
	margin: "12px 0 20px 12px",
	label: {
		paddingLeft: "8px",
		marginRight: "20px"
	}
});
