import MessageMap from "../i18n.d";

const enUS: MessageMap = {
	test: {
		translate: "Translate",
		missing: "Only English option available"
	},
	DASHBOARD: {
		TOAST_LOAD_SUCCESS: "Dashboards loaded successfully.",
		TOAST_LOAD_ERROR: "Something went wrong loading dashboard. Please try again.",
		LOAD_ERROR: "No dashboard found."
	},
	REPORT: {
		TOAST_ADDED_SUCCESS: "Report successfully added!",
		TOAST_ADDED_ERROR: "Something went wrong adding report.",
		TOAST_LOADING_INFO_ERROR: "Something went wrong while loading report info.",
		TOAST_EDITED_SUCCESS: "Report edited successfully!",
		TOAST_ENABLED_SUCCESS: "Report enabled successfully!",
		TOAST_ENABLED_ERROR: "Something went wrong enabling report.",
		TOAST_DISABLED_SUCCESS: "Report disabled successfully!",
		TOAST_DISABLED_ERROR: "Something went wrong disabling report.",
		TOAST_EDITED_ERROR: "Something went wrong editing report.",
		TOAST_LOAD_SUCCESS: "Report loaded successfully!",
		TOAST_LOAD_ERROR: "Something went wrong loading the report. Please try again.",
		TOAST_DELETED_SUCCESS: "Report deleted successfully!",
		TOAST_DUPLICATED_ERROR: "This report already exists.",
		TOAST_WORKSPACE_ERROR: "Workspace error. You trying to publish a dashboard with wrong domain.",
		TOAST_DELETED_ERROR: "Something went wrong while deleting the report. Please try again.",
		TOAST_DISCARD_CHANGES: "Closing it will discard your changes.",
		TOAST_RLS_ERROR: "You trying to publish a dashboard that does not match the RLS rules."
	},
	DATASET: {
		TOAST_DELETED_SUCCESS: "Dataset successfully deleted.",
		TOAST_DELETED_ERROR: "Unable to delete dataset. Try again."
	},
	ERROR_404: {
		HEADER: "Mmm… Looks like this page is gone",
		DESCRIPTION: "It looks like this page doesn't exist or you don't have permission to access it.",
		BUTTON_TEXT: "Home"
	},
	ERROR_403: {
		HEADER: "Hmm... Access denied!",
		DESCRIPTION: "It looks like you don't have permission to access this page.",
		BUTTON_TEXT: "Home"
	},
	ERROR_500: {
		HEADER: "Ooops! Something went wrong"
	},
	ERROR_503: {
		HEADER: "Hmm… Service unavailable!",
		DESCRIPTION:
			"Our servers are temporarily too busy. You can try again in a few minutes and it should be normalized.",
		BUTTON_TEXT: "Home",
		INFO_TEXT: "If the issue persists, you can <a>report the problem.</a>"
	},
	ARIA_BUTTON: {
		DELETE_REPORT: "Delete Report Button",
		ADD_REPORT: "Add Report Button,",
		EDIT_REPORT: "Edit Report Button"
	},
	ARIA_TOGGLE: {
		ENABLE_REPORT: "Enable report toggle"
	},
	JIRA: {
		TOAST_POST_TICKET_SUCCESS: "Ticket created successfully",
		TOAST_POST_TICKET_ERROR: "Something went wrong while creating the ticket. Please try again.",
		TOAST_TICKETS_LIST_ERROR: "Something went wrong while loading your tickets.",
		TOAST_LOAD_ERROR: "Something went wrong while loading the informations. Please try again.",
		TOAST_GENERIC_ERROR: "Something went wrong. Please try again.",
		COMMENT_POST_ERROR: "Something went wrong while posting your comment."
	},
	BANNERS: {
		HOME: "Bees Data Portal is a one-stop shop for users to discover, access and use all of our data services. We want to help you to build and enable successful data products.",
		GLOBAL_REPORTS_1:
			"This is a place where you can find our reports. They are separated by domains.",
		GLOBAL_REPORTS_2: "Feel free to discover all of them and all the ways you can go through data."
	}
};

export default enUS;
