import { LoadingBuzz } from "@hexa-ui/components";
import { useLoaderContext } from "contexts";
import React from "react";
import { LoaderStyled } from "./LoaderStyled";

interface ILoaderProps {
	children: React.ReactNode;
	isLoadingProp?: boolean;
}

const Loader = ({ children, isLoadingProp }: ILoaderProps): JSX.Element => {
	const { isLoading } = useLoaderContext();
	const loadingParameter = isLoadingProp ?? isLoading;

	return (
		<React.Fragment>
			{loadingParameter ? (
				<LoaderStyled>
					<LoadingBuzz data-testid="bees-loader" color="mono" size="xxlarge" />
				</LoaderStyled>
			) : (
				<>{children}</>
			)}
		</React.Fragment>
	);
};

export default Loader;
