import { Button, ProgressTracker, useProgressTracker } from "@hexa-ui/components";
import { TypeToast, useAuthenticationService } from "admin-portal-shared-services";
import { InfraFormContext } from "contexts";
import { handleArrayFields } from "pages/InfraServicesPages/InfraSupportFunctions";
import { useContext, useState } from "react";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { routes } from "router/constants";
import { createJiraTicket } from "services/Jira/JiraService";
import { ButtonsWrapperStyled } from "styles/shared/ButtonsWrapperStyled/ButtonsWrapperStyled";
import { ACCOUNT_ID_BEES_DATA_PORTAL, DATA_TEAM_SERVICE_DESK_ID } from "utils/constants";
import { showNotify } from "utils/notify";
import { ISteps } from "../../InfraNewDomainPage/InfraNewDomain.types";
import { InfraFormStyled, StyledLabel } from "./InfraFormStepperStyled";

interface InfraFormStepperProps {
	steps: ISteps[];
	handleCancel: () => void;
	requestTypeId: number;
}

const InfraFormStepper = ({
	steps,
	handleCancel,
	requestTypeId
}: InfraFormStepperProps): React.JSX.Element => {
	const { infraFormData } = useContext(InfraFormContext);
	const navigate = useNavigate();
	const { formatMessage } = useIntl();
	const [completedSteps, setCompletedSteps] = useState<number[]>([]);
	const { currentStep, goTo, goToPrevious, goToNext } = useProgressTracker({
		initialStep: 0,
		stepQuantity: steps.length
	});
	const currentLocalStep = steps[currentStep];
	const authService = useAuthenticationService();
	const userEmail = authService.getUserEmailB2C()?.toLowerCase();

	const newRequestBody = (requestTypeId: number) => {
		const { summary, ...formData } = infraFormData;
		const description = {
			...formData,
			General_questions: {
				admin_users: handleArrayFields(formData?.General_questions?.admin_users as string),
				non_admin_users: handleArrayFields(formData?.General_questions?.non_admin_users as string)
			},
			processing_stack: formData?.processing_stack?.map((stack) => {
				return {
					...stack,
					additional_vaults: handleArrayFields(stack?.additional_vaults as string)
				};
			}),
			datalake_stack: formData?.datalake_stack?.map((stack) => {
				return {
					...stack,
					workspace_containers: handleArrayFields(stack.workspace_containers as string),
					raw_containers: handleArrayFields(stack.raw_containers as string),
					refined_containers: handleArrayFields(stack.refined_containers as string)
				};
			})
		};

		return {
			serviceDeskId: DATA_TEAM_SERVICE_DESK_ID,
			requestTypeId: requestTypeId.toString(),
			raiseOnBehalfOf: userEmail,
			requestParticipants: [ACCOUNT_ID_BEES_DATA_PORTAL],
			requestFieldValues: {
				summary: infraFormData?.summary,
				description: `${JSON.stringify(description)}`
			}
		};
	};

	const handleSubmit = (event) => {
		(async () => {
			event.preventDefault();
			try {
				await createJiraTicket(newRequestBody(requestTypeId));
				showNotify(formatMessage({ id: "JIRA.TOAST_POST_TICKET_SUCCESS" }), TypeToast.SUCCESS);
				navigate(routes.INFRA_SERVICES.INFRA_SERVICES);
			} catch {
				showNotify(formatMessage({ id: "JIRA.TOAST_POST_TICKET_ERROR" }), TypeToast.ERROR);
			}
		})();
	};

	const isLastStep = () => {
		return currentStep !== steps.length - 1;
	};

	return (
		<InfraFormStyled>
			<ProgressTracker.Root size="small" orientation="horizontal" currentStep={currentStep}>
				<ProgressTracker.Header>
					{steps.map((item, index) => (
						<ProgressTracker.Step
							index={index}
							key={item.id}
							completed={completedSteps.includes(index)}
							onSelect={(indexSelected) => {
								setCompletedSteps([...completedSteps, currentStep]);
								goTo(indexSelected);
							}}
						>
							<StyledLabel alertMessage={`alert error for ${item.label}`} label={item.label} />
						</ProgressTracker.Step>
					))}
				</ProgressTracker.Header>
			</ProgressTracker.Root>
			<currentLocalStep.component />
			<ButtonsWrapperStyled position="center" margin="bottom" gap>
				{currentStep === 0 ? (
					<Button variant="secondary" onClick={handleCancel}>
						Cancel
					</Button>
				) : (
					<Button
						onClick={() => {
							setCompletedSteps([...completedSteps, currentStep]);
							goToPrevious();
						}}
					>
						Previous
					</Button>
				)}
				{isLastStep() ? (
					<Button
						onClick={() => {
							setCompletedSteps([...completedSteps, currentStep]);
							goToNext();
						}}
					>
						Next
					</Button>
				) : (
					<Button type="submit" onClick={handleSubmit}>
						Submit
					</Button>
				)}
			</ButtonsWrapperStyled>
		</InfraFormStyled>
	);
};

export default InfraFormStepper;
