import { Button, LoadingDots } from "@hexa-ui/components";
import { FormEvent } from "react";
import { useNavigate } from "react-router-dom";
import { ButtonsWrapperStyled } from "styles/shared/ButtonsWrapperStyled/ButtonsWrapperStyled";

interface IFormButtonsProps {
	handleSubmit: (event: FormEvent<HTMLButtonElement>) => Promise<void> | (() => void);
	isValid?: boolean;
	isLoading?: boolean;
}

export const FormButtons = ({ handleSubmit, isValid = true, isLoading }: IFormButtonsProps) => {
	const navigate = useNavigate();

	return (
		<ButtonsWrapperStyled position="end" margin="top" gap>
			<Button variant="secondary" onClick={() => navigate(-1)}>
				Cancel
			</Button>
			<Button onClick={handleSubmit} disabled={!isValid || isLoading}>
				{isLoading ? <LoadingDots size="large" /> : <>Send</>}
			</Button>
		</ButtonsWrapperStyled>
	);
};
