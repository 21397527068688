import { Grid, Tabs } from "@hexa-ui/components";
import { setAppHeaderConfig } from "admin-portal-shared-services";
import { useReportsContext } from "contexts";
import { TriggerStyled } from "pages/GlobalReportsPage/GlobalReportsPageStyled";
import ReportsTabContent from "pages/Tabs/ReportsTabContent";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { generatePath, routes } from "router/constants";
import { TabContentStyled } from "styles/shared/TabContentStyled/TabContentStyled";
import { EBeesDomains, IBeesDomain } from "types/Domain.types";
import { convertToLowerCaseWithDash } from "utils/transformers";
import { domainsList } from "./domainList";

const { Root, List } = Tabs;
const { Container, Item } = Grid;

const SelfServiceBiPage = (): JSX.Element => {
	const [initialDomainTab, setInitialDomainTab] = useState(EBeesDomains.Customer);
	const { setTabDomain } = useReportsContext();
	const [domains, setDomains] = useState([] as IBeesDomain[]);
	const navigate = useNavigate();

	useEffect(() => {
		setAppHeaderConfig({
			pageTitle: "Self Service BI",
			breadcrumbConfig: {
				homePath: routes.HOME_PAGE,
				items: [
					{
						label: "Self Service BI",
						path: routes.SELF_SERVICE_BI.SELF_SERVICE_BI,
						isCurrentPage: true
					}
				]
			}
		});
	}, [setAppHeaderConfig]);

	const setTabDomainId = (domainName: EBeesDomains): number => {
		return domains.find((d: IBeesDomain) => d.domainName.toLowerCase() === domainName.toLowerCase())
			?.domainId;
	};

	const handleTab = (domain: string) => {
		setTabDomain(domain);
		navigate(
			generatePath(routes.SELF_SERVICE_BI.SELF_SERVICE_BI_DOMAIN, {
				domainName: convertToLowerCaseWithDash(domain)
			})
		);
	};

	return (
		<Container data-testid="self-service-bi-page" type="fluid" sidebar>
			<Item lg={12} md={12} sm={12} xl={12} xs={12}>
				{initialDomainTab && (
					<Root defaultValue={initialDomainTab}>
						<List>
							{domainsList.map((domain) => {
								return (
									<TriggerStyled
										value={convertToLowerCaseWithDash(domain.value)}
										onClick={() => handleTab(domain.value)}
										disabled={false}
										key={domain.value}
									>
										{domain.name}
									</TriggerStyled>
								);
							})}
						</List>

						{domainsList.map((domain) => {
							return (
								<TabContentStyled
									value={convertToLowerCaseWithDash(domain.value)}
									key={domain.value}
								>
									<ReportsTabContent
										domainName={domain.value}
										domainId={setTabDomainId(domain.value)}
									/>
								</TabContentStyled>
							);
						})}
					</Root>
				)}
			</Item>
		</Container>
	);
};

export default SelfServiceBiPage;
