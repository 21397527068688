import { Button, Heading, Input, TextArea } from "@hexa-ui/components";
import { TypeToast, useAuthenticationService } from "admin-portal-shared-services";
import { FormProvider, useForm } from "react-hook-form";
import { useIntl } from "react-intl";
import { useNavigate } from "react-router-dom";
import { routes } from "router/constants";
import { createJiraTicket } from "services/Jira/JiraService";
import { ButtonsWrapperStyled } from "styles/shared/ButtonsWrapperStyled/ButtonsWrapperStyled";
import { ContainerCardStyled } from "styles/shared/ContainerCardStyled/ContainerCardStyled";
import { ContainerStyled } from "styles/shared/ContainerStyled/ContainerStyled";
import { ERequestTypeId } from "types/Jira.types";
import { ACCOUNT_ID_BEES_DATA_PORTAL, DATA_TEAM_SERVICE_DESK_ID } from "utils/constants";
import { showNotify } from "utils/notify";

type FormValues = {
	summary: string;
	description: string;
};

interface INewResourceEditProps {
	setNewResource: (string) => void;
}

export const InfraNewResourceEditForm = ({ setNewResource }: INewResourceEditProps) => {
	const navigate = useNavigate();
	const { formatMessage } = useIntl();
	const authService = useAuthenticationService();
	const userEmail = authService.getUserEmailB2C()?.toLowerCase();
	const methods = useForm<FormValues>();
	const formValues = methods.watch();
	const { NewAzureEditResource } = ERequestTypeId;

	const requestBody = (requestTypeId: number) => {
		return {
			serviceDeskId: DATA_TEAM_SERVICE_DESK_ID,
			requestTypeId: requestTypeId.toString(),
			raiseOnBehalfOf: userEmail,
			requestParticipants: [ACCOUNT_ID_BEES_DATA_PORTAL],
			requestFieldValues: {
				summary: formValues?.summary,
				description: formValues?.description
			}
		};
	};

	const handleSubmit = (event) => {
		(async () => {
			event.preventDefault();
			try {
				await createJiraTicket(requestBody(NewAzureEditResource));
				showNotify(formatMessage({ id: "JIRA.TOAST_POST_TICKET_SUCCESS" }), TypeToast.SUCCESS);
				navigate(routes.INFRA_SERVICES.INFRA_SERVICES);
			} catch {
				showNotify(formatMessage({ id: "JIRA.TOAST_POST_TICKET_ERROR" }), TypeToast.ERROR);
			}
		})();
	};

	return (
		<FormProvider {...methods}>
			<ContainerCardStyled border="small" elevated="medium">
				<Heading size="H3">Edit Informations</Heading>
				<ContainerStyled marginBottom marginTop>
					<Input
						{...methods.register("summary")}
						id="text-area-summary"
						label="Summary"
						width="100%"
						value={formValues.summary}
						placeholder="Short description"
					/>
				</ContainerStyled>
				<ContainerStyled marginBottom marginTop>
					<TextArea
						{...methods.register("description")}
						id="description"
						hint="Please acknowledge Stack’s Product Names and Short Product Names can’t be changed."
						labelProps={{ htmlFor: "description" }}
						label="Describe what needs to be changed on your Infrastructure:"
						placeholder="Tell us what you need"
						value={formValues.description}
						width="100%"
						optionalText=""
					/>
				</ContainerStyled>
				<ButtonsWrapperStyled position="end" margin="top" gap>
					<Button onClick={() => setNewResource("")}>Cancel</Button>
					<Button onClick={(event) => handleSubmit(event)}>Submit</Button>
				</ButtonsWrapperStyled>
			</ContainerCardStyled>
		</FormProvider>
	);
};
