export enum EFrequencyOptions {
	Monthly = "Monthly",
	Weekly = "Weekly",
	Daily = "Daily"
}

export enum EDataSourceOptions {
	Entities = "Entities",
	Segment = "Segment",
	Braze = "Braze",
	Other = "Other"
}

export enum EReportStatus {
	Done = "Done",
	UnderConstruction = "UnderConstruction"
}

export enum EAnalyticsService {
	MODE = "Mode",
	POWER_BI = "Power BI"
}
