import { styled } from "@hexa-ui/theme";

export const Error500Styled = styled("div", {
	display: "flex",
	width: "100%",
	alignItems: "center",
	justifyContent: "center",
	marginTop: "20vh",
	flexWrap: "wrap"
});
