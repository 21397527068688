import { Button, Dialog, Heading } from "@hexa-ui/components";
import { TypeToast } from "admin-portal-shared-services";
import { useIntl } from "react-intl";
import { useDeleteDataset } from "services/Dataset/datasets";
import { showNotify } from "utils/notify";
import { ButtonsWrapper, ParagraphStyled } from "./DeleteDatasetModalStyled";

interface IDeleteDatasetModalProps {
	datasetId: string;
	isOpen: boolean;
	onClose: () => void;
}

export const DeleteDatasetModal = ({ datasetId, isOpen, onClose }: IDeleteDatasetModalProps) => {
	const { formatMessage } = useIntl();
	const { mutate } = useDeleteDataset(datasetId, {
		onSuccess: () => {
			onClose();
			showNotify(formatMessage({ id: "DATASET.TOAST_DELETED_SUCCESS" }), TypeToast.SUCCESS);
		},
		onError: () => {
			onClose();
			showNotify(formatMessage({ id: "DATASET.TOAST_DELETED_ERROR" }), TypeToast.ERROR);
		}
	});

	return (
		<Dialog.Root
			title={<Heading size="H3">Delete dataset</Heading>}
			open={isOpen}
			onClose={onClose}
			onEscapeKeyDown={onClose}
		>
			<ParagraphStyled>This dataset will be removed from the BEES Data Portal.</ParagraphStyled>
			<ButtonsWrapper>
				<Button
					type="submit"
					size="medium"
					variant="destructive"
					data-testid="delete-button"
					onClick={() => mutate()}
				>
					Yes, delete dataset
				</Button>
				<Button size="medium" variant="secondary" onClick={onClose} data-testid="cancel-button">
					No, go back
				</Button>
			</ButtonsWrapper>
		</Dialog.Root>
	);
};
