// JIRA API
export const DATA_TEAM_SERVICE_DESK_ID = "7";
export const OPERATIONS_SERVICE_DESK_ID = "5";
export const ACCOUNT_ID_BEES_DATA_PORTAL = "712020:d9d2a065-4308-4a6d-baee-a471410ae9b6";
// ANALYTICS
export const ANALYTICS_DATA_PORTAL_UUID = "7a72772d-5d34-4341-a185-3abf59704d90";
// STATUS CODE
export const INITIAL_STATUS_CODE = 200;
// ERROR CODE
export const DEFAULT_ERROR_CODE = 500;
