import { Radio } from "@hexa-ui/components";
import { RadioRootStyled } from "components/Modals/ReportModal/ReportModalStyled";
import { InfraFormContext } from "contexts";
import { useContext } from "react";
import { Controller, useFormContext } from "react-hook-form";
import { handleChange } from "../InfraSupportFunctions";
import { YesorNoRadioStyled } from "./YesOrNoRadioStyled";

export const YesOrNoRadio = ({ id, fieldData, formSection, index }) => {
	const { infraFormData, setInfraFormData } = useContext(InfraFormContext);
	const { control, getValues } = useFormContext();

	const handleRadioDefaultValue = () => {
		if (infraFormData?.[formSection]?.[index]?.[fieldData] === undefined) return undefined;
		return infraFormData?.[formSection]?.[index]?.[fieldData] ? "true" : "false";
	};

	return (
		<YesorNoRadioStyled>
			<Controller
				control={control}
				name={`${formSection}.${index}.${id}`}
				render={({ field: { onChange } }) => {
					return (
						<RadioRootStyled
							id={`${id}_${index}`}
							onValueChange={(value) => {
								onChange(JSON.parse(value));
								handleChange(getValues, setInfraFormData);
							}}
							defaultValue={handleRadioDefaultValue()}
						>
							<Radio.Item label="Yes" value="true" id={`${id}_yes_${index}`} />
							<Radio.Item label="No" value="false" id={`${id}_no_${index}`} />
						</RadioRootStyled>
					);
				}}
			/>
		</YesorNoRadioStyled>
	);
};
