import { EScopes } from "./Scopes.types";
import { EDataSourceOptions, EFrequencyOptions, EReportStatus } from "./SelectOptions.types";

export enum EReportType {
	POWER_BI = "Power BI",
	MODE = "Mode",
	OTHER = "Other"
}

export enum EScopesSection {
	AppName = 0,
	Object = 1,
	Action = 2
}

export interface IReportLink {
	fromPbix: boolean;
	ownedByMe: boolean;
	id: string;
	reportType: string;
	name: string;
	webUrl: string;
	embedUrl: string;
	isFromPbix: boolean;
	isOwnedByMe: boolean;
	datasetId: string;
	datasetWorkspaceId: string;
}

export enum EDomainWorkspaceName {
	Force = "BEES Force - Commercial Management",
	Platformsuccess = "BEES Analytics",
	Care = "BEES Care Prod",
	Grow = "BEES Grow Prod",
	Rewards = "Bees Pricing Prod",
	DigitalComms = "Bees Engage Prod",
	Customer = "Bees Customer Prod",
	Link = "Bees Link Prod",
	Deliver = "BEES Deliver Prod",
	Fintech = "Bees Fintech Global KPIs",
	NPS = "Bees NPS"
}

export interface IReportDomain {
	enabled: boolean;
	domainId: number;
	domainName: string;
	workspaceId: string;
	isEnabled: boolean;
}

export enum EScopeLevel {
	"BeesDataPortal.Bees.Read" = "Bees",
	"BeesDataPortal.Global.Read" = "ABI Global",
	"BeesDataPortal.CountryBU.Read" = "ABI Non-Global"
}

export interface IScope {
	scopeId: string;
	scopeLevel: EScopeLevel | EScopes | string;
}

export interface IReport {
	enabled: boolean;
	reportId: string;
	scopes: IScope[];
	hasRls?: boolean;
	domain: IReportDomain;
	domainId?: string;
	ownerName: string;
	ownerEmail: string;
	reportTitle: string;
	description: string;
	status: EReportStatus | string;
	lastUpdate: string | number | Date;
	uploadDate: string | number | Date;
	documentationLink: string;
	dataSource: EDataSourceOptions | string;
	updateFrequency: EFrequencyOptions | string;
	reportType: EReportType | string;
	userAcceptedTerms: boolean;
	userGuide?: string;
	country: string;
	isEnabled: boolean;
	reportLink?: string;
	embeddedReport: IReportLink;
	embeddedReportToken: {
		token: string;
		tokenId: string;
		expiration: Date | number;
	};
}

export interface IReportResponse {
	data: IReport;
}

export interface IReports {
	enabled: boolean;
	reportId: string;
	domain: IReportDomain;
	ownerName: string;
	ownerEmail: string;
	reportTitle: string;
	description: string;
	status: EReportStatus | string;
	lastUpdate: Date | number;
	uploadDate: string | number | Date;
	documentationLink: string;
	dataSource: EDataSourceOptions | string;
	updateFrequency: EFrequencyOptions | string;
	reportType: string;
	userAcceptedTerms: boolean;
	userGuide: string;
	country: string;
	isEnabled: boolean;
}

export interface INewReport {
	reportId: string;
	scopes?: IScope[];
	scopesId?: string[];
	hasRls: boolean;
	domainId: number;
	domain?: IReportDomain;
	ownerName: string;
	ownerEmail: string;
	reportTitle: string;
	description: string;
	status: string;
	reportLink: string;
	documentationLink: string;
	dataSource: string;
	updateFrequency: string;
	reportType?: string;
	userAcceptedTerms: boolean;
	userGuide?: string;
	isEnabled: boolean;
	uploadDate: string | number | Date;
}

export interface IReportInputs {
	reportTitle: string;
	description: string;
	ownerName: string;
	ownerEmail: string;
	reportLink: string;
	documentationLink: string;
	userGuide: string;
}
