import { useQuery, UseQueryOptions } from "@tanstack/react-query";
import Api from "api/Api";
import { GET_REPORTS, GetReportsByDomainRoute } from "api/routes/ReportRoutes";
import { IReport } from "types/Report.types";

export interface useGetReportsByDomainReturn {
	onSuccess: Function;
}

export interface IPagination {
	offset: number;
	pageSize: number;
	count: number;
}

export interface IReportResponse {
	data: {
		data: IReport[];
	};
}

export const useGetReportsByDomain = (
	domainId,
	size,
	offset,
	pagination: IPagination,
	updateReportsDate: Date,
	queryOptions?: UseQueryOptions<IReportResponse>
) => {
	return useQuery<IReportResponse>(
		["domainId", domainId, pagination, updateReportsDate],
		() =>
			Api.get({
				baseURL: GetReportsByDomainRoute({ route: GET_REPORTS, domainId: domainId }),
				url: `${domainId}`,
				params: {
					size: size,
					offset: offset
				}
			}),
		{
			keepPreviousData: true,
			enabled: queryOptions.enabled,
			...queryOptions
		}
	);
};
