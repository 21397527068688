import { Select } from "@hexa-ui/components";

export const ServerTypeOptions = () => {
	return (
		<>
			<Select.Option value="single">Single</Select.Option>
			<Select.Option value="flexible">Flexible</Select.Option>
		</>
	);
};

export const MySqlVersionOptions = () => {
	return (
		<>
			<Select.Option value="5.7">Version 5.7</Select.Option>
			<Select.Option value="8.0">Version 8.0</Select.Option>
		</>
	);
};

export const PostgreVersionOptions = (): JSX.Element => {
	return (
		<>
			<Select.Option value="9.5">Version 9.5</Select.Option>
			<Select.Option value="9.6">Version 9.6</Select.Option>
			<Select.Option value="10">Version 10</Select.Option>
			<Select.Option value="10.0">Version 10.0</Select.Option>
			<Select.Option value="10.2">Version 10.2</Select.Option>
			<Select.Option value="11">Version 11</Select.Option>
		</>
	);
};

export const SqlPoolSkuOptions = () => {
	const validValues = [
		"DW100c",
		"DW200c",
		"DW300c",
		"DW400c",
		"DW500c",
		"DW1000c",
		"DW1500c",
		"DW2000c",
		"DW2500c",
		"DW3000c",
		"DW5000c",
		"DW6000c",
		"DW7500c",
		"DW10000c",
		"DW15000c",
		"DW30000c"
	];
	return (
		<>
			{validValues.map((option) => {
				return (
					<Select.Option value={option} key={option}>
						{option}
					</Select.Option>
				);
			})}
		</>
	);
};

export const EventHubSkuOptions = () => {
	return (
		<>
			<Select.Option value="Basic">Basic</Select.Option>
			<Select.Option value="Standard">Standard</Select.Option>
			<Select.Option value="Premium">Premium</Select.Option>
		</>
	);
};
