import { ROUTE_BASE_URL } from "../constants";
import { IRoutesParams } from "./RouteParams.types";

export const GET_REPORT = "GET_REPORT";
export const POST_REPORT = "POST_REPORT";
export const GET_REPORTS = "GET_REPORTS";
export const EDIT_REPORT = "EDIT_REPORT";
export const DELETE_REPORT = "DELETE_REPORT";
export const GET_DOMAINS = "GET_DOMAINS";
export const SEARCH_REPORTS = "SEARCH_REPORTS";

export const GetReportByIdRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_REPORT: `/reports/`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const GetReportsByDomainRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_REPORTS: `/reports/domain`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const GetAllReportsRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_REPORTS: `/reports`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const GetDomainsRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		GET_DOMAINS: `/domains`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const PostReportRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		POST_REPORT: `/reports`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const EditReportRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		EDIT_REPORT: `/reports/`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const DeleteReportRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		DELETE_REPORT: `/reports/`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};

export const SearchReportsRoute = ({ route }: IRoutesParams): string => {
	const routes_stoplight: { [key: string]: string } = {
		SEARCH_REPORTS: `/reports/search`
	};

	return `${ROUTE_BASE_URL}${routes_stoplight[route]}`;
};
