import { UseQueryOptions, useMutation, useQuery } from "@tanstack/react-query";
import datasetMock from "mocks/Report/datasetMock.json";
import { IReport } from "types/Report.types";
import { deleteDataset } from "./DatasetService";

export const useGetDatasetById = (datasetId, options?: UseQueryOptions<IReport>) => {
	return useQuery<IReport>(
		["datasetId", datasetId],
		() => {
			// const { data } = await getDatasetById(datasetId);
			// return data;

			//returning mock while endpoint is not ready
			return datasetMock;
		},
		{
			...options
		}
	);
};

export const useDeleteDataset = (datasetId, options?) => {
	return useMutation(async () => await deleteDataset(datasetId), {
		...options
	});
};
