import Api from "api/Api";
import {
	DELETE_REPORT,
	DeleteReportRoute,
	EDIT_REPORT,
	EditReportRoute,
	GET_DOMAINS,
	GET_REPORT,
	GET_REPORTS,
	GetAllReportsRoute,
	GetDomainsRoute,
	GetReportByIdRoute,
	GetReportsByDomainRoute,
	POST_REPORT,
	PostReportRoute,
	SEARCH_REPORTS,
	SearchReportsRoute
} from "api/routes/ReportRoutes";
import { INewReport, IReportResponse } from "types/Report.types";

export const getReportById = (reportId: string): Promise<IReportResponse | undefined> => {
	return Api.get({ baseURL: GetReportByIdRoute({ route: GET_REPORT }), url: `${reportId}` });
};

export const getReportsByDomain = (
	domainId: number,
	size: number,
	offset: number
): Promise<any> => {
	return Api.get({
		baseURL: GetReportsByDomainRoute({ route: GET_REPORTS, domainId: domainId }),
		url: `${domainId}`,
		params: {
			size: size,
			offset: offset
		}
	});
};

export const getDomains = (size: number, offset: number): Promise<any> => {
	return Api.get({
		baseURL: GetDomainsRoute({ route: GET_DOMAINS }),
		url: "",
		params: {
			size: size,
			offset: offset
		}
	});
};

export const getReports = (size: number, offset: number): Promise<any> => {
	return Api.get({
		baseURL: GetAllReportsRoute({ route: GET_REPORTS }),
		url: "",
		params: {
			size: size,
			offset: offset
		}
	});
};

export const createReport = (newReport: INewReport): Promise<void> => {
	return Api.post({
		baseURL: PostReportRoute({ route: POST_REPORT }),
		url: "",
		body: newReport
	});
};

export const editReport = (reportId: string, report: INewReport): Promise<any> => {
	return Api.put({
		baseURL: EditReportRoute({ route: EDIT_REPORT }),
		url: `${reportId}`,
		body: report
	});
};

export const deleteReport = (reportId: string): Promise<any> => {
	return Api.delete({
		baseURL: DeleteReportRoute({ route: DELETE_REPORT }),
		url: `${reportId}`
	});
};

export const searchReports = (reportTitle: string, size: number, offset: number): Promise<any> => {
	return Api.post({
		baseURL: SearchReportsRoute({ route: SEARCH_REPORTS }),
		url: "",
		body: {
			reportTitle: reportTitle
		},
		params: {
			size: size,
			offset: offset
		}
	});
};
