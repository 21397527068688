import { Button, Heading, Paragraph } from "@hexa-ui/components";
import { EmptyState } from "assets/Images/EmptyState/EmptyState";
import { ContainerStyled, GenericErrorStyled } from "./GenericErrorStyled";

interface IGenericErrorProps {
	hasError: boolean;
	children: React.ReactNode;
	onClick: () => void;
}

export const GenericError = ({ hasError, children, onClick }: IGenericErrorProps) => {
	return (
		<>
			{hasError ? (
				<ContainerStyled>
					<GenericErrorStyled>
						<EmptyState />
						<Heading size="H2">Unable to load page</Heading>
						<Paragraph size="basis" colortype="disabled">
							Try reloading the page.
						</Paragraph>
						<Button onClick={onClick} size="large">
							Reload page
						</Button>
					</GenericErrorStyled>
				</ContainerStyled>
			) : (
				<>{children}</>
			)}
		</>
	);
};
