import { Grid } from "@hexa-ui/components";
import { styled } from "@hexa-ui/theme";

export const HeaderStyled = styled(Grid.Item, {
	display: "flex",
	justifyContent: "space-between",
	padding: 0
});

export const InfoModalStyled = styled("div", {
	display: "flex",
	marginBottom: "$4",
	h3: { width: "100%", marginRight: "$1" },
	svg: {
		color: "$semanticInfoText"
	},
	".description": {
		paddingBottom: "$4"
	}
});
