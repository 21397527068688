export enum ESidebarVisibility {
	BeesDataPortalUsers = "BeesDataPortalUsers",
	BeesUsers = "BeesUsers",
	AllUsers = "AllUsers"
}

export interface ISidebarItem {
	id: string;
	title: string;
	icon: () => JSX.Element;
	path: string;
	visible: string | boolean;
	target?: "_self" | "_blank";
}
