export const formatProFormaAnswers = (formValuesObject: object, labelsObject: object) => {
	if (!formValuesObject || !labelsObject || Object.keys(labelsObject).length === 0) return "";

	const answersArray = Object.entries(formValuesObject).map(([question, answer]) => {
		let formattedAnswer = answer;
		if (typeof answer === "object") {
			formattedAnswer = JSON.stringify(answer);
		}
		return {
			question: labelsObject?.[question],
			answer: formattedAnswer
		};
	});
	const formattedAnswers = answersArray
		.map(({ question, answer }) => `*${question}*: ${answer}\n`)
		.join("");

	return formattedAnswers;
};
