import { LoadingDots } from "@hexa-ui/components";
import React from "react";
import { LoaderDotsWrapper } from "./LoaderDotsStyled";

interface ILoaderProps {
	children: React.ReactNode;
	isLoading?: boolean;
}

const LoaderDots = ({ children, isLoading }: ILoaderProps): JSX.Element => {
	return (
		<React.Fragment>
			{isLoading ? (
				<LoaderDotsWrapper>
					<LoadingDots data-testid="loading-dots" color="mono" size="xxlarge" />
				</LoaderDotsWrapper>
			) : (
				<>{children}</>
			)}
		</React.Fragment>
	);
};

export default LoaderDots;
