import { Heading, Paragraph, TextLink } from "@hexa-ui/components";
import { ExternalLink } from "assets/AppIcons/ExternalLink";
import { StatusLabel } from "components/StatusLabel/StatusLabel";
import { AuthContext } from "contexts";
import { useContext } from "react";
import { IDataset } from "types/Dataset.types";
import { EditionButtons } from "../EditionButtons/EditionButtons";
import {
	ColumnItem,
	ExternalLinkWrapper,
	GeneralInformationCardStyled,
	HeaderStyled,
	InformationItemStyled,
	InformationWrapper
} from "./GeneralInformationCardStyled";

interface IGeneralInformationProps {
	dataset: IDataset | undefined;
}

export const GeneralInformationCard = ({ dataset }: IGeneralInformationProps) => {
	const { isPublisher } = useContext(AuthContext);

	if (!dataset || Object.keys(dataset).length === 0) return <></>;

	const accessLevelTypes = {
		"BeesDataPortal.Bees.Read": "BEES",
		"BeesDataPortal.Global.Read": "ABI global",
		"BeesDataPortal.CountryBU.Read": "ABI non-global employees"
	};

	const datasetAccessLevels = dataset?.scopes
		?.map((scope) => accessLevelTypes[scope?.scopeLevel])
		.join(", ");

	return (
		<GeneralInformationCardStyled
			border="medium"
			elevated="medium"
			data-testid="general-information-card"
		>
			<HeaderStyled>
				<Heading size="H3">General Information</Heading>
				{isPublisher && (
					<EditionButtons datasetId={dataset?.reportId} domainName={dataset?.domain?.domainName} />
				)}
			</HeaderStyled>

			<InformationWrapper>
				<ColumnItem xl={6} lg={6} md={6} sm={12} xs={12}>
					<InformationItemStyled spacing="large">
						<Paragraph weight="semibold" size="small">
							Status
						</Paragraph>
						<StatusLabel status={dataset?.status} />
					</InformationItemStyled>

					<InformationItemStyled spacing="large">
						<Paragraph weight="semibold" size="small">
							Title
						</Paragraph>
						<Paragraph size="basis">{dataset?.reportTitle}</Paragraph>
					</InformationItemStyled>

					<InformationItemStyled spacing="basis">
						<Paragraph weight="semibold" size="small">
							Description
						</Paragraph>
						<Paragraph size="basis">{dataset?.description}</Paragraph>
					</InformationItemStyled>

					<ExternalLinkWrapper>
						<ExternalLink />
						<TextLink
							hasUnderline={false}
							size="small"
							target="blank"
							href={dataset?.documentationLink}
						>
							Show dataset documentation
						</TextLink>
					</ExternalLinkWrapper>

					{dataset?.userGuide && (
						<ExternalLinkWrapper>
							<ExternalLink />
							<TextLink hasUnderline={false} size="small" target="blank" href={dataset?.userGuide}>
								Show user guide
							</TextLink>
						</ExternalLinkWrapper>
					)}
				</ColumnItem>

				<ColumnItem xl={5} lg={5} md={5} sm={12} xs={12}>
					<InformationItemStyled spacing="basis">
						<Paragraph weight="semibold" size="small">
							Domain
						</Paragraph>
						<Paragraph size="basis">{dataset?.domain?.domainName}</Paragraph>
					</InformationItemStyled>

					<InformationItemStyled spacing="basis">
						<Paragraph weight="semibold" size="small">
							Data source
						</Paragraph>
						<Paragraph size="basis">{dataset?.dataSource}</Paragraph>
					</InformationItemStyled>

					<InformationItemStyled spacing="basis">
						<Paragraph weight="semibold" size="small">
							Analytics service
						</Paragraph>
						<Paragraph size="basis">{dataset?.reportType}</Paragraph>
					</InformationItemStyled>

					{isPublisher && (
						<InformationItemStyled spacing="basis">
							<Paragraph weight="semibold" size="small">
								Access permission
							</Paragraph>
							<Paragraph size="basis">{datasetAccessLevels}</Paragraph>
							{dataset?.hasRls && (
								<Paragraph size="small" colortype="disabled">
									Row-level-security (RLS) applied.
								</Paragraph>
							)}
						</InformationItemStyled>
					)}

					<InformationItemStyled>
						<Paragraph weight="semibold" size="small">
							Dataset owner
						</Paragraph>
						<Paragraph size="basis">{dataset?.ownerName}</Paragraph>
						<Paragraph size="small" colortype="disabled">
							{dataset?.ownerEmail}
						</Paragraph>
					</InformationItemStyled>
				</ColumnItem>
			</InformationWrapper>
		</GeneralInformationCardStyled>
	);
};
